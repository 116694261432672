import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "front";
export const useEventConflictLogic = (props) => {
    const dispatch = useDispatch();
    const closeDialog = useCallback(() => {
        dispatch(openModal(undefined));
    }, []);
    return {
        closeDialog
    };
};
