import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from "react";
import { Dialog, Flex, Text, GridIcon } from "@fluentui/react-northstar";
import { CompareModule, openModal, useModalSelector, useMsTeamsSelector, usePermissionsSelector, useTranslate, userHasRightsToEdit } from "front";
import { translations } from "../../../translations";
import { useDispatch } from "react-redux";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { ResourcesView } from "../../../views/Resources/Resources.rendering";
import { Modal } from "../../../interfaces/modalInterface";
export const ResourcesDialog = () => {
    const { calendar, resources, mutateResource, resourcesUpdateLoading } = useCalendarCache();
    const { isOpen } = useModalSelector("isOpen");
    const dispatch = useDispatch();
    const [basicResources, setBasicResources] = useState(JSON.parse(JSON.stringify(resources)));
    const { userPerm } = usePermissionsSelector('userPerm');
    const { isOnMobile, userMail } = useMsTeamsSelector("isOnMobile", "userMail");
    const t = useTranslate(translations);
    const noChange = useMemo(() => CompareModule.areObjectsEqual(resources, basicResources), [resources, basicResources]);
    const readOnly = useMemo(() => !userHasRightsToEdit(userPerm), [userPerm]);
    const emptyRooms = useMemo(() => basicResources.length == 0 || basicResources.some((b) => b.title.trim() == ""), [basicResources]);
    const onHide = () => {
        dispatch(openModal(undefined));
    };
    const onChange = (resource) => {
        setBasicResources(resource);
    };
    const saveSettings = () => {
        const resource = {
            resourceMode: calendar.resourceMode,
            id: calendar.resourceId,
            updated: new Date(),
            updatedBy: userMail,
            calendarId: calendar.id,
            resourceData: basicResources
        };
        mutateResource({ id: calendar.resourceId, calendarId: calendar.id, resource: resource }, {
            onSuccess: () => {
                setTimeout(() => { onHide(); }, 1000);
            }
        });
    };
    const renderDialogHeader = () => {
        return (_jsx(Flex, { fill: true, space: "between", styles: {
                width: "100%",
            }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(GridIcon, { outline: true }), _jsx(Text, { content: t("RoomsManagement") })] }) }));
    };
    const renderSettings = () => {
        return (_jsx(ResourcesView, { show: true, calendar: calendar, onChange: onChange, resources: basicResources, onHide: onHide }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, closeOnOutsideClick: false, open: isOpen == Modal.Rooms, header: {
            content: renderDialogHeader(),
            styles: { width: "100%" },
        }, content: renderSettings(), cancelButton: {
            content: t("Cancel"),
            disabled: resourcesUpdateLoading,
        }, onCancel: onHide, confirmButton: {
            content: t("Save"),
            disabled: resourcesUpdateLoading || noChange || readOnly || emptyRooms,
            loading: resourcesUpdateLoading,
        }, onConfirm: saveSettings }));
};
