import { useDispatch, useSelector } from "react-redux";
import { setModalWithData } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { translations, useMsTeamsSelector, useTranslate } from "../../../..";
import { useLocation } from "react-router-dom";
import { useNewsCache } from "../../../hook/useNewsCache";
export const useNewsLogic = (props) => {
    // General
    const location = useLocation();
    const dispatchCtx = useDispatch();
    const t = useTranslate(translations);
    // Context
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    // Caches
    const { queryNewsBanner } = useNewsCache({ appType: props.AppType });
    const infosApp = useSelector((state) => state.infosApp);
    const isNotInsideRestrictedRoutes = !location.pathname.includes("/remove") && !location.pathname.includes("/configuration") && !location.pathname.includes("/widget");
    const openCTALink = () => {
        const url = queryNewsBanner.data?.ctaUrl;
        window.open(url, "_blank");
        infosApp?.appInsightInstance?.trackEvent({
            name: "News_CTA_Clicked_" + queryNewsBanner.data?.ctaLabel,
        });
        dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
    };
    return {
        queryNewsBanner,
        isOnMobile,
        isNotInsideRestrictedRoutes,
        t,
        openCTALink,
    };
};
