export const useLogic = (props) => {
    const onDeleteResource = (index) => {
        let resources = new Array(...props.resources);
        resources.splice(index, 1);
        props.onChange(resources);
    };
    /**
     * On update resource
     * @param index resource index
     * @param title resource title
     */
    const onUpdateResource = (index, title) => {
        let resources = new Array(...props.resources);
        resources = resources.map((r, i) => {
            if (i == index) {
                r.title = title.substring(0, 100);
            }
            return r;
        });
        props.onChange(resources);
    };
    /**
     * On add resource
     */
    const onAddResource = () => {
        const resources = new Array(...props.resources);
        let maxValue = Math.max.apply(null, resources.map(function (resource) { return Number(resource.id); }));
        if (maxValue == Number.POSITIVE_INFINITY || maxValue == Number.NEGATIVE_INFINITY)
            maxValue = 0;
        resources.push({ id: (maxValue + 1).toString(), title: "" });
        props.onChange(resources);
    };
    return {
        onAddResource,
        onDeleteResource,
        onUpdateResource
    };
};
