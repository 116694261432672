import { AppView, AppViewData, MeetingScheduler, RemoveCalendar, Tags, ViewManager, Widget, WidgetConfiguration, WidgetDetails } from "calendarShared"
import { Permissions, Sync } from "front"
import { Configuration } from "views/Configuration/Configuration.rendering"

const ConfigurationView: AppViewData = {
    id: AppView.Configure,
    path: "/configure",
    titleId: "Configuration",
    element: Configuration
}
const CalendarView: AppViewData = {
    id: AppView.Calendars,
    path: "/calendars/:calendarId",
    titleId: "Calendars",
    element: ViewManager
}
const TagsView: AppViewData = {
    id: AppView.Tags,
    path: "/tags/:calendarId",
    titleId: "Tags",
    element: Tags
}
const PermissionsView: AppViewData = {
    id: AppView.Permissions,
    path: "/permissions/:calendarId",
    titleId: "Permissions",
    element: Permissions
}

const SyncView: AppViewData = {
    id: AppView.Sync,
    path: "/sync/:calendarId",
    titleId: "Sync",
    element: Sync
}

const RemoveView: AppViewData = {
    id: AppView.Remove,
    path: "/remove/:calendarId",
    titleId: "RemoveCalendar",
    element: RemoveCalendar
}

const WidgetView: AppViewData = {
    id: AppView.Widget,
    path: "/widget",
    titleId: "Widget",
    element: Widget
}

const WidgetDetailView: AppViewData = {
    id: AppView.WidgetDetail,
    path: "/widget/details",
    titleId: "WidgetDetail",
    element: WidgetDetails
}

const WidgetConfigureView: AppViewData = {
    id: AppView.WidgetConfigure,
    path: "/widget/configure",
    titleId: "WidgetConfigure",
    element: WidgetConfiguration
}

const MeetingSchedulerView: AppViewData = {
    id: AppView.MeetingScheduler,
    path: "/meetingscheduler/:calendarId",
    titleId: "MeetingScheduler",
    element: MeetingScheduler
}

export const AppViews: Record<AppView, AppViewData> = {
    [AppView.Configure]: ConfigurationView,
    [AppView.Calendars]: CalendarView,
    [AppView.Tags]: TagsView,
    [AppView.Permissions]: PermissionsView,
    [AppView.Remove]: RemoveView,
    [AppView.Widget]: WidgetView,
    [AppView.WidgetDetail]: WidgetDetailView,
    [AppView.WidgetConfigure]: WidgetConfigureView,
    [AppView.Sync]: SyncView,
    [AppView.MeetingScheduler]: MeetingSchedulerView,
}