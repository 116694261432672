import { findKeyPath, useAppConfigurationSelector, useMsTeamsSelector, usePermissionsSelector } from "front";
import moment from "moment";
import { getHours } from "../event/eventUtils";
import { useState } from "react";
export const useBusinessDaysLogic = (props) => {
    const { appId } = useAppConfigurationSelector("appId");
    const { userPerm } = usePermissionsSelector("userPerm");
    const { locale } = useMsTeamsSelector("locale");
    const LFormat = moment.localeData(locale).longDateFormat("LT");
    const use12HoursFormat = LFormat.split(" ").length > 1 && LFormat.split(" ").pop() === "A";
    const hoursItems = getHours(use12HoursFormat).map((hour, index) => ({
        header: hour,
        value: index,
    }));
    const startHoursItems = hoursItems.slice(0, -1);
    const endHoursItems = hoursItems.slice(1);
    const [searchQuery, setSearchQuery] = useState({ businessDaysStartHourSearchQuery: "", businessDaysEndHourSearchQuery: "" });
    const onChangeBusinessHours = (property, data) => {
        const hour = data?.value?.value;
        if (hour !== 0 && !hour)
            return;
        let startHour = property === "start"
            ? hour
            : props.watchData[findKeyPath(props.keysRegistered, 'startHour')];
        let endHour = property === "end" ? hour : props.watchData[findKeyPath(props.keysRegistered, 'endHour')];
        if (property === "start" && startHour >= endHour) {
            endHour = startHour + 1;
        }
        else if (property === "end" && endHour <= startHour) {
            startHour = endHour - 1;
        }
        props.setValue(findKeyPath(props.keysRegistered, 'startHour'), startHour);
        props.setValue(findKeyPath(props.keysRegistered, 'endHour'), endHour);
    };
    const onSearchBusinessDaysHour = (property) => (e, data) => {
        const value = (data.searchQuery ?? "").slice(0, 2);
        if (property === "start") {
            setSearchQuery((prev) => ({ ...prev, businessDaysStartHourSearchQuery: value }));
            return;
        }
        setSearchQuery((prev) => ({ ...prev, businessDaysEndHourSearchQuery: value }));
    };
    const onToggleBusinessDay = (day, field) => () => {
        const days = Array.from(new Set(props.watchData[findKeyPath(props.keysRegistered, 'days')]));
        if (days.includes(day))
            days.splice(days.indexOf(day), 1);
        else
            days.push(day);
        const value = days.filter((d) => d !== undefined && d !== null);
        field.onChange(value);
    };
    return {
        onChangeBusinessHours,
        onSearchBusinessDaysHour,
        onToggleBusinessDay,
        appId,
        userPerm,
        startHoursItems,
        endHoursItems,
        searchQuery,
        setSearchQuery
    };
};
