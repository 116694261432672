export const translationsSpecific = {
  en: {
    FilesTitle: "Files",
    Booking: "Booking Room Pro",
    noTag: "Start by adding a tag and then filter the view using that tag",
    Today: "Today",
    TimeLine: "Timeline View",
    Month: "Month",
    Week: "Week",
    Day: "Day",
    List: "List",
    AddEvent: "New",
    CreatedBy: "Created by",
    UpdatedBy: "Updated by",
    AddTitle: "Add a title",
    AddDescription: "Add a description",
    SelectValue: "Select a value",
    DeleteEvent: "Delete",
    Cancel: "Cancel",
    Save: "Save",
    AllDay: "All day",
    InfoUser: "Everybody in this channel can add/edit bookings",
    OnlyMe: "Only the creator of this Booking Room Pro can add/edit bookings",
    OnlyOwners: "Only owners can add/edit bookings",
    Name: "Name",
    DefaultDisplay: "Default display mode",
    FirstDay: "First day of the week",
    SecurityMode: "Permissions",
    AddName: "Add a name",
    ErrorDate: "Invalid dates",
    ErrorTitle: "Title is mandatory.",
    Close: "Close",
    Delete: "Delete",
    HeaderDeleteModal: "Delete booking",
    HeaderDeleteModalSerie: "Delete series",
    HeaderDeleteModalOccurence: "Delete occurence",
    ContentDeleteModal: "Do you want to delete this booking?",
    Color: "Color",
    Reset: "Reset color",
    Resource: "Calendar type",
    Standard: "Standard",
    "Suggest Feature": "Suggest a feature",
    Resources: "Rooms",
    ButtonContentResource: "Manage rooms",
    ButtonAddRessource: "Add a room",
    InputResource: "Room",
    ButtonDeleteRessource: "Remove",
    placeholderResources: "ex: Room",
    versionPremiumText: "Booking Room Pro Premium: Active",
    mySubscription: "My subscription",
    purchaseCalendarPro1: "To activate your ",
    purchaseCalendarPro2: " rooms. Unlock now Booking Room Pro Premium.",
    messageFormTask: " ",
    closePremium: "Close",
    HeaderMessageWelcome: "Booking Room Pro Premium",
    The: "The ",
    TabToRemove: " tab will be permanently removed.",
    MessagePremiumCanceled: "You need a Booking Room Pro Premium subscription to add more than three rooms.",
    ButtonInvitePremium: "Unlock Premium",
    ReactivateCalendarPremium: "Your subscription is cancelled. Reactivate Booking Room Pro Premium now.",
    NotRepeat: "Does not repeat",
    Workweek: "From Monday to Friday",
    Weekly: "Weekly",
    Daily: "Daily",
    Monthly: "Monthly",
    Yearly: "Yearly",
    Custom: "Custom...",
    UnLockRecurrence: "Upgrade to Booking Room Pro Premium to enable recurrence",
    RecurrenceInfo: "The duration of your booking cannot exceed that of your recurrence.",
    ReactivateCalendarPremiumRecurrence: "Reactivate Booking Room Pro Premium to enable recurrence",
    TitleCalendarPro: "Booking Room Pro",
    Print: "Print",
    UnlockMoreOptions: "Upgrade this calendar {calendarName} to Booking Room Pro Premium to enable more options",
    ReactivateCalendarPremiumMoreOptions: "Reactivate Booking Room Pro Premium to enable more options",
    TitleCustomRecurrence: "Custom recurrence",
    DateStartRecurrence: "Start date",
    IntervalRecurrence: "Repeat every",
    DaysOfRecurrence: "Repeat on",
    EndOfRecurrence: "Ends",
    EndsNeverRecurrence: "Never",
    DateEndsRecurrence: "On",
    CountOfRecurrence: "After",
    OccurenceOfRecurrence: "Occurences",
    SerieOfRecurrence: "Series",
    Year: "Year",
    RRuleEvery: "Every",
    RRuleMonth: "month",
    RRuleMonths: "months",
    RRuleYear: "year",
    RRuleYears: "years",
    RRuleOnThe: "on the",
    RRuleAnd: "and",
    RRuleNd: "nd",
    RRuleTh: "th",
    RRuleSt: "st",
    RRuleWeek: "week",
    RRuleWeeks: "weeks",
    RRuleDay: "day",
    RRuleDays: "days",
    RRuleOn: "on",
    RRuleFor: "for",
    RRuleTimes: "times",
    RRuleSunday: "Sunday",
    RRuleMonday: "Monday",
    RRuleTuesday: "Tuesday",
    RRuleWednesday: "Wednesday",
    RRuleThursday: "Thursday",
    RRuleFriday: "Friday",
    RRuleSaturday: "Saturday",
    RRuleJanuary: "January",
    RRuleFebruary: "February",
    RRuleMarch: "March",
    RRuleApril: "April",
    RRuleMay: "May",
    RRuleJune: "June",
    RRuleJuly: "July",
    RRuleAugust: "August",
    RRuleSeptember: "September",
    RRuleOctober: "October",
    RRuleNovember: "November",
    RRuleDecember: "December",
    RRuleUntil: "until",
    First: "First",
    Second: "Second",
    Third: "Third",
    Fourth: "Fourth",
    Last: "Last",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    OnThe: "On the",
    LabelResource: "Room",
    LabelTitle: "Title",
    LabelStartDate: "Start Date",
    LabelEndDate: "End Date",
    LabelRecurrence: "Recurrence",
    LabelDescription: "Description",
    LabelWarning: "Warning",
    LabelError: "Error",
    RemoveCalendarTitle: "Are you sure you want to delete this tab? ",
    RemoveCalendarItem: "All the information associated to this tab will be permanently removed. You will not be able to recover the content.",
    RemoveCalendarFAQ: "Booking Room Pro FAQ page",
    RemoveCalendarCancellationText: "Witivio’s cancellation policy here",
    RemoveCalendarContact: "Contact us",
    MessageAlertTokenOutlookExpired: "The Outlook synchronization has expired. {LinkToRenew}",
    HeaderSyncModal: "Synchronize {CalendarName} with your personal Outlook calendar.",
    CheckSyncModal: "Enable synchronization",
    WarningBodySyncModal: "Only the bookings of the current month and future bookings will be synchronized.",
    BodySyncModal:
      "All Booking Room Pro bookings will be copied in your personal Outlook calendar. The synchronization is automatic. To enable the synchronization, Booking Room Pro needs your consent to access your personal calendar.",
    ButtonAuthorizeSyncModal: "I authorize to access to my personal calendar",
    Welcome: "Welcome to Booking Room Pro!",
    WelcomeDetail:
      "Booking Room Pro is a collaborative calendar for Microsoft Teams. Search for Booking Room Pro on Appsource or Teams Store, add it into a channel and start using your shared calendar.",
    OptionUnableInOutlook: "Please, select only 1 day.",
    RenewMessage: "Renew",
    RenewToken: "Click here to renew",
    OnlyOwnersInfo: "This option is only available for Booking Room Pro Premium users",
    DiscardMessageTitle: "Unsaved changes",
    DiscardChanges: "Discard",
    ContinueEditing: "Continue editing",
    DiscardMessage: "Do you want to discard edits or continue editing?",
    MessageOnlyMePrevent: "{createdBy} has prevented you from adding/editing bookings in this calendar.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit bookings in this calendar",
    DeleteOccurrence: "Delete occurrence",
    DeleteSeries: "Delete series",
    MessageDeleteRecurrence: "Do you want to delete this ",
    ButtonDeleteRecurrence: "Delete ",
    EditEvent: "Edit",
    EditOccurrence: "Edit occurrence",
    EditSeries: "Edit series",
    Occurrence: "occurrence",
    Series: "series",
    ViewOccurrenceOfSeries: "You're viewing an occurrence of a series.",
    LoadData: "Loading data...",
    LoadCalendar: "Loading your calendar...",
    InfoEventId: "Booking ID",
    InfoEventSerieId: "Serie ID",
    InfoEventCreatedBy: "Created by",
    InfoEventUpdatedBy: "Updated by",
    InfoEventTimezone: "Timezone",
    infoEventHeader: "Event's information",
    CopyToClipboard: "Copy to clipboard",
    Copied: "Copied!",
    InfoEvent: "Information",
    EstimatedTimeSync: "We will be synchronizing {numberOfEvent} bookings, it may take {EstimatedSyncTime} hours.",
    Upgrade: "Update Booking Room Pro",
    UpgradeDetail: "To use Booking Room Pro, you have to update the app manually. Click the 'Upgrade' button on the top-right of the screen (See the example below) to update your Booking Room Pro.",
    AnyEventInResources: "When switching from standard to rooms mode, all the bookings not linked to a room will not be displayed in rooms mode.",
    NoResource: "No room",
    EmptyFillError: "This field cannot be empty",
    GoDesktopBuyPremium: "You need desktop version of app to enable premium",
    Ok: "Ok",
    NoRightToUpdateConfig: "You are not allowed to change the configuration",
    UpdatingApp: "Updating application",
    LinkTitle: "Link",
    AddLink: "Add a link",
    ErrorMessageUrl: "Please enter a valid website url, ex: https://www.domain.com or domain.com",
    AddressTitle: "Address",
    AddAddress: "Type here to search an address",
    TagsTitle: "Tags",
    LabelSelectionTimeZone: "TimeZone",
    EditTextConfirm: "Save",
    EditTextEdit: "Edit",
    EditTextRemove: "Remove",
    EditTextLink: "Link",
    Add: "Add",
    Forward: "Advanced",
    AlertTagsAndMore: "To use advanced features go to Premium ",
    About: "About",
    Copy: "Copy",
    UniqueId: "Unique identifier",
    WarningUpdateRecurrenceMessage: "Are you sure to save the serie ? All custom occurences will be deleted",
    Version: "Version",
    CalendarDeleted: "This calendar has been deleted",
    EmojiLabel: "Emoji",
    AddEmoji: "Select",
    ShowWeekend: "Show weekend",
    BusinessDays: "Business days",
    StartHour: "Start hour",
    EndHour: "End hour",
    Settings: "Settings",
    Permissions: "Permissions",
    ShowWeekNumbers: "Number the weeks",
    PublicHolidays: "Public holidays",
    Australian: "Australian",
    Austrian: "Austrian",
    Brazilian: "Brazilian",
    Canadian: "Canadian",
    China: "China",
    Christian: "Christian",
    Danish: "Danish",
    Dutch: "Dutch",
    Finnish: "Finnish",
    French: "French",
    German: "German",
    Greek: "Greek",
    HongKongC: "Hong Kong (C)",
    HongKong: "Hong Kong",
    Indian: "Indian",
    Indonesian: "Indonesian",
    Iranian: "Iranian",
    Irish: "Irish",
    Islamic: "Islamic",
    Italian: "Italian",
    Japanese: "Japanese",
    Jewish: "Jewish",
    Malaysian: "Malaysian",
    Mexican: "Mexican",
    NewZealand: "New Zealand",
    Norwegian: "Norwegian",
    Philippines: "Philippines",
    Polish: "Polish",
    Portuguese: "Portuguese",
    Russian: "Russian",
    Singapore: "Singapore",
    SouthAfrica: "South Africa",
    SouthKorean: "South Korean",
    Spain: "Spain",
    Swedish: "Swedish",
    Taiwan: "Taiwan",
    Thai: "Thai",
    UK: "UK",
    US: "US",
    Vietnamese: "Vietnamese",
    NotPremiumMobileMessage: "You need a premium subscription to use mobile version.",
    "Tag:Title": "Tags management",
    "Tag:Create": "Create new tag",
    "Tag:Search": "Type here to search for a tag",
    "Tag:TableName": "Name",
    "Tag:DeleteTitle": "Delete tag",
    "Tag:DeleteMessageFirstPart": "The tag «",
"Tag:DeleteMessageSecondPart": "» will be permanently deleted and will be remove from all bookings.",
    "Tag:DeleteButton": "Delete",
    "Tag:CreateTitle": "Create tag",
    "Tag:CreateMessage": "Please enter a name for the tag",
    "Tag:CreateButton": "Create",
    "Tag:EditTitle": "Edit tag",
    "Tag:EditMessage": "Please enter a new name for the tag",
    "Tag:EditButton": "Save",
    "Tag:ErrorTagAlreadyExist": "This tag already exists",
    "Tag:ErrorFieldTooLong": "You've reached the character limit. Please rewrite the tag name to fit within the allowed character count to proceed",
    "Tag:ErrorFieldRequired": "This field is required",
    "Tag:NoResult": "No search results were found.",
    "Tag:NoEntries": "No entries",
    "Tag:ErrorOccured": "An error occurred while processing your request, please try again later",
    "Tag:UpdatingTags": "We are currently in the process of updating the tag management system.\n\rPlease allow Booking Room Pro some time to complete this update.",
    "Tag:UpdatingTagsError": "An issue occurred during the update, kindly try again later. If the problem persists, feel free to contact us.",
    ConfigurationHelp: "We've shifted all the settings to the main Booking Room Pro window for easier access.",
    WelcomeMessage: "Welcome to Booking Room Pro! Begin by naming the tab and configuring the rooms to get started",
    ShareToOutlook: "Add to Outlook",
    SynchronizeAgenda: "Synchronize agenda",
    CalendarsTitle: "Calendars",
    WelcomePremium: "Welcome to Booking Room Pro Premium 🚀",
    RemoveBookingTitle: "Are you sure you want to delete this tab?",
    RemoveBookingItem: "All the information associated to this tab will be permanently removed. You will not be able to recover the content.",
    Consent: "Let's go 🚀",
    BookingRoomDeleted: "This room booking manager has been deleted",
    RoomsManagement: "Rooms management",
    addEventTitle: "{userName} made a booking in {ressourceName}",
    addEventDesc: "A booking has been created",
    updateEventTitle: "{userName} made an update in {ressourceName}",
    updateEventDesc: "A booking has been updated",
    deleteEventTitle: "{userName} has deleted a booking in {ressourceName}",
    deleteEventDesc: "A booking has been delete",
    AddUpdateNotif: "Added/updated bookings",
    DeleteNotif: "Deleted bookings",
    AddUpdateNotifDesc: "Send a Teams notification to all participants of a new or updated booking",
    DeleteNotifDesc: "Send a Teams notification to all participants of a deleted booking",
    participants: "Participants",
    Notifications: "Notifications",
    NewReservation: "New reservation",
    EditReservation: "Edit reservation",
    AlreadyBooked: "The selected time slot is already booked. Please choose another time slot or {event}",
    AnotherParkingPlace: "another parking place",
    AnotherRoom: "another room",
    UpgradeToPaidPlan: "Upgrade",
    ReccuringEventIsPremium: "Editing recurrent event is a Premium feature",
    TagsPremium: "Filter by topic with custom tags using {App} Premium. See plans for access.",
    "PremiumFeature:AddToOutlook" : "Copy important bookings from Booking Room Pro to Outlook with one click.",
    "UpgradeToPremium" : "Upgrade to Premium",
    "NoTagFound" : "No tag found",

    WarningRecurrenceMaxDate: "Recurring bookings can only be booked for up to 1 year",
    "AdjustBooking": "Adjust my booking",
    "ConflictBookingTitle" : "Conflicting Booking Alert",
    "ConflictBookingTagLine" : "Adjust your booking to avoid conflict or save your event excluding conflicting dates.",
    "SeeConflicts" : "See conflicts",
    "January" : "January",
    "February" : "February",
    "March" : "March",
    "April" : "April",
    "May" : "May",
    "June" : "June",
    "July" : "July",
    "August" : "August",
    "September" : "September",
    "October" : "October",
    "November" : "November",
    "December" : "December",
    "AlreadyBookedAt" : "already booked at",
    "Suggested" : "Suggested: ",

  },
  fr: {
    FilesTitle: "Fichiers",
    Booking: "Réservation de Salle Pro",
    noTag: "Commencer par ajouter un tag pour filtrer la vue avec ce tag",
    Today: "Aujourd'hui",
    TimeLine: "Vue TimeLine",
    Month: "Mois",
    Week: "Semaine",
    Day: "Jour",
    List: "Listes",
    AddEvent: "Nouveau",
    CreatedBy: "Créé par",
    UpdatedBy: "Modifié par",
    AddTitle: "Ajouter un titre",
    AddDescription: "Ajouter une description",
    SelectValue: "Selectionner une valeur",
    DeleteEvent: "Supprimer",
    Cancel: "Annuler",
    Delete: "Supprimer",
    HeaderDeleteModal: "Supprimer la réservations",
    HeaderDeleteModalSerie: "Supprimer la série",
    HeaderDeleteModalOccurence: "Supprimer l'occurence",
    ContentDeleteModal: "Voulez-vous supprimer cette réservations ?",
    Save: "Sauvegarder",
    AllDay: "Toute la journée",
    InfoUser: "Tout les utilisateurs peuvent créer et éditer",
    OnlyMe: "Moi seul peut créer",
    OnlyOwners: "Seuls les propriétaires peuvent créer et modifier",
    Name: "Nom",
    DefaultDisplay: "Mode d'affichage par défaut",
    FirstDay: "Premier jour de la semaine",
    SecurityMode: "Mode de sécurité",
    AddName: "Ajoutez un nom",
    ErrorDate: "Dates invalides",
    ErrorTitle: "Un titre est obligatoire.",
    Close: "Fermer",
    Color: "Couleur",
    Reset: "Réinitialiser couleur",
    Resource: "Type de calendrier",
    Standard: "Standard",
    Resources: "Salles",
    ButtonContentResource: "Gérer les salles",
    ButtonAddRessource: "Ajouter une salle",
    InputResource: "Salle",
    ButtonDeleteRessource: "Supprimmer",
    placeholderResources: "ex: Salle ",
    versionPremiumText: "Réservation de Salle Pro Premium: Actif",
    mySubscription: "Mon abonnement",
    purchaseCalendarPro1: "Pour utiliser vos ",
    purchaseCalendarPro2: " salles. Activez maintenant Réservation de Salle Pro Premium.",
    messageFormTask: "Activer Réservation de Salle Pro Premium",
    closePremium: "Fermer",
    HeaderMessageWelcome: "Réservation de Salle Pro Premium",
    The: "L'onglet ",
    TabToRemove: " sera définitivement supprimé.",
    MessagePremiumCanceled: "Vous n'avez pas d'abonnement Réservation de Salle Pro Premium pour utiliser plus de trois salles.",
    ButtonInvitePremium: "Activer Premium",
    ReactivateCalendarPremium: "Votre abonnement est désactivé. Reactiver Réservation de Salle Pro Premium maintenant.",
    NotRepeat: "Ne pas répéter",
    Workweek: "Du Lundi au Vendredi",
    Weekly: "Hebdomadaire",
    Daily: "Journalier",
    Monthly: "Mensuel",
    Yearly: "Annuel",
    Custom: "Personnaliser...",
    UnLockRecurrence: "Activer Réservation de Salle Pro Premium pour permettre la récurrence",
    RecurrenceInfo: "La durée de votre réservation ne peut pas dépasser celle de votre recurrence.",
    ReactivateCalendarPremiumRecurrence: "Reactiver Réservation de Salle Pro Premium pour permettre la récurrence",
    TitleCalendarPro: "Réservation de Salle Pro",
    Print: "Imprimer",
    UnlockMoreOptions: "Veuillez activer Réservation de Salle Pro Premium pour accéder à cette fonctionnalité",
    ReactivateCalendarPremiumMoreOptions: "Reactivate Réservation de Salle Pro Premium to enable more options",
    TitleCustomRecurrence: "Récurrence personnalisée",
    DateStartRecurrence: "Date de début",
    IntervalRecurrence: "Répéter tou(te)s les",
    DaysOfRecurrence: "Répéter le",
    EndOfRecurrence: "Se termine",
    EndsNeverRecurrence: "Jamais",
    DateEndsRecurrence: "Le",
    CountOfRecurrence: "Après",
    OccurenceOfRecurrence: "Occurences",
    SerieOfRecurrence: "Séries",
    Year: "Année",
    RRuleEvery: "Tout(es) le(s)",
    RRuleMonth: "mois",
    RRuleMonths: "mois",
    RRuleYear: "an",
    RRuleYears: "ans",
    RRuleOnThe: "le",
    RRuleAnd: "et",
    RRuleNd: " ",
    RRuleTh: " ",
    RRuleSt: " ",
    RRuleWeek: "semaine",
    RRuleWeeks: "semaines",
    RRuleDay: "jour",
    RRuleDays: "jours",
    RRuleOn: "le",
    RRuleFor: "après",
    RRuleTimes: "ocurrences",
    RRuleSunday: "dimanche",
    RRuleMonday: "lundi",
    RRuleTuesday: "mardi",
    RRuleWednesday: "mercredi",
    RRuleThursday: "jeudi",
    RRuleFriday: "vendredi",
    RRuleSaturday: "samedi",
    RRuleJanuary: "Janvier",
    RRuleFebruary: "Février",
    RRuleMarch: "Mars",
    RRuleApril: "Avril",
    RRuleMay: "Mai",
    RRuleJune: "Juin",
    RRuleJuly: "Juillet",
    RRuleAugust: "Août",
    RRuleSeptember: "Septembre",
    RRuleOctober: "Octobre",
    RRuleNovember: "Novembre",
    RRuleDecember: "Décembre",
    RRuleUntil: "jusqu'à",
    First: "Premier",
    Second: "Deuxième",
    Third: "Troisième",
    Fourth: "Quatrième",
    Last: "Dernier",
    Sunday: "Dimanche",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    OnThe: "Le",
    LabelResource: "Salle",
    LabelTitle: "Titre",
    LabelStartDate: "Date de début",
    LabelEndDate: "Date de fin",
    LabelRecurrence: "Récurrence",
    LabelDescription: "Description",
    LabelWarning: "Attention",
    LabelError: "Erreur",
    RemoveCalendarTitle: "Êtes-vous sûr de vouloir supprimer cet onglet?",
    RemoveCalendarItem: "Toutes les informations associées à cet onglet seront définitivement supprimées. Vous ne pourrez pas récupérer le contenu.",
    RemoveCalendarFAQ: "FAQ:",
    RemoveCalendarCancellationText: "Politique d'annulation",
    RemoveCalendarContact: "Nous contacter:",
    MessageAlertTokenOutlookExpired: "La synchronisation Outlook a expiré. {LinkToRenew}",
    HeaderSyncModal: "Synchroniser {CalendarName} dans votre calendrier Outlook personnel.",
    CheckSyncModal: "Activer la synchronisation",
    WarningBodySyncModal: "Seuls les réservations du mois en cours et ces réservations futurs seront synchronisés.",
    BodySyncModal:
      "Réservation de Salle Pro copie tous les réservations de votre calendrier Outlook personnel. La synchronisation est automatique. Pour activer la synchronisation, Réservation de Salle Pro a besoin de votre consentement pour accéder à votre calendrier personnel.",
    ButtonAuthorizeSyncModal: "J'autorise l'accès à mon calendrier personnel",
    Welcome: "Bienvenue dans Réservation de Salle Pro, nous sommes heureux que vous soyez ici!",
    WelcomeDetail:
      "Réservation de Salle Pro pour Microsoft Teams. À l'intérieur d'un canal, vous pouvez très facilement organiser des équipes, gérer les disponibilités, programmer le travail et partager des réservations.",
    OptionUnableInOutlook: "L'option plus d'un jour est impossible sur Outlook",
    RenewMessage: "Renouveler",
    RenewToken: "Cliquez ici pour renouveler",
    OnlyOwnersInfo: "Cette option n'est disponible que pour les utilisateurs de Réservation de Salle Pro Premium",
    DiscardMessageTitle: "Modifications non enregistrées",
    DiscardChanges: "Ignorer",
    ContinueEditing: "Continuer l'édition",
    DiscardMessage: "Voulez-vous ignorer les modifications apportées ou continuer la modification ?",
    MessageOnlyMePrevent: "{createdBy} vous a empêché d'ajouter/modifier des réservations dans ce calendrier.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit events in this calendar",
    DeleteOccurrence: "Supprimer l'occurrence",
    DeleteSeries: "Supprimer la série",
    MessageDeleteRecurrence: "Voulez-vous supprimer cette ",
    ButtonDeleteRecurrence: "Supprimer ",
    EditEvent: "Éditer",
    EditOccurrence: "Éditer l'occurrence",
    EditSeries: "Éditer la série",
    Occurrence: "occurrence",
    Series: "série",
    ViewOccurrenceOfSeries: "Une occurrence de la série est affichée.",
    LoadData: "Chargement des données...",
    LoadCalendar: "Chargement de votre calendrier...",
    InfoEventId: "Identifiant de la réservations",
    InfoEventSerieId: "Identifiant de la série",
    InfoEventCreatedBy: "Créé par",
    InfoEventUpdatedBy: "Mis à jour par",
    InfoEventTimezone: "Fuseau horaire",
    infoEventHeader: "Informations sur la réservations",
    CopyToClipboard: "Copier dans le presse-papier",
    Copied: "Copié !",
    InfoEvent: "Informations",
    EstimatedTimeSync: "Nous allons synchroniser {numberOfEvent} réservations, cela peux prendre {EstimatedSyncTime} heures.",
    Upgrade: "Mise à jour de Réservation de Salle Pro",
    UpgradeDetail:
      "Pour continuer à fonctionner nous avons besoin que vous mettiez à jour l'application en cliquant sur le bouton 'mettre à jour', en haut de l'écran comme présenté sur l'image ci-dessous.",
    AnyEventInResources: "Lorsque vous passez du mode standard au mode salles dans Réservation de Salle Pro, tous les réservations non reliés à une salle ne seront pas affichés en mode salles.",
    NoResource: "Aucune salle",
    EmptyFillError: "Ce champ ne peut pas être vide",
    GoDesktopBuyPremium: "Vous devez utiliser la version ordinateur de l'application pour activer le mode premium",
    Ok: "Ok",
    NoRightToUpdateConfig: "Vous n'avez pas le droit de modifier la configuration",
    UpdatingApp: "Mise à jour de l'application",
    LinkTitle: "Lien",
    AddLink: "Ajouter un lien",
    ErrorMessageUrl: "Veuillez saisir une url valide, ex: https://www.domain.com ou domain.com",
    AddressTitle: "Adresse",
    AddAddress: "Saisir une adresse",
    TagsTitle: "Tags",
    LabelSelectionTimeZone: "Fuseau horaire",
    EditTextConfirm: "Sauvegarder",
    EditTextEdit: "Éditer",
    EditTextRemove: "Supprimer",
    EditTextLink: "Lien",
    Add: "Ajouter",
    Forward: "Avancé",
    AlertTagsAndMore: "Pour utiliser les fonctions avancées, passez à la version Premium",
    Copy: "Copier",
    About: "À propos",
    UniqueId: "Identifiant unique",
    WarningUpdateRecurrenceMessage: "Etes-vous sûr de sauvegarder la série ? Toutes les occurrences personnalisées seront supprimées",
    Version: "Version",
    CalendarDeleted: "Ce calendrier a été supprimé",
    EmojiLabel: "Emoji",
    AddEmoji: "Sélectionnez",
    ShowWeekend: "Afficher le week-end",
    BusinessDays: "Jours ouvrables",
    StartHour: "Heure de départ",
    EndHour: "Heure de fin",
    Settings: "Paramètres",
    Permissions: "Permissions",
    ShowWeekNumbers: "Numéroter les semaines",
    PublicHolidays: "Jours fériés",
    Australian: "Australie",
    Austrian: "Autrichien",
    Brazilian: "Brésilien",
    Canadian: "Canadien",
    China: "Chine",
    Christian: "Christian",
    Danish: "Danois",
    Dutch: "Néerlandais",
    Finnish: "finlandais",
    French: "Français",
    German: "Allemand",
    Greek: "Grec",
    HongKongC: "Hong Kong (C)",
    HongKong: "Hong Kong",
    Indian: "Indien",
    Indonesian: "Indonésien",
    Iranian: "Iranien",
    Irish: "Irlandais",
    Islamic: "Islamique",
    Italian: "Italien",
    Japanese: "Japonais",
    Jewish: "Juif",
    Malaysian: "Malaisien",
    Mexican: "Mexicain",
    NewZealand: "néo-zélandais",
    Norwegian: "Norvégien",
    Philippines: "Philippines",
    Polish: "Polonais",
    Portuguese: "Portugais",
    Russian: "Russe",
    Singapore: "Singapour",
    SouthAfrica: "Afrique du Sud",
    SouthKorean: "Coréen du Sud",
    Spain: "Espagne",
    Swedish: "Suédois",
    Taiwan: "Taiwan",
    Thai: "Thaïlandais",
    UK: "ROYAUME-UNI",
    US: "US",
    Vietnamese: "Vietnamien",
    NotPremiumMobileMessage: "Un abonnement Premium est nécessaire pour utiliser la version mobile.",
    "Tag:Title": "Gestion des tags",
    "Tag:Create": "Créer un nouveau tag",
    "Tag:Search": "Tapez ici pour rechercher un tag",
    "Tag:TableName": "Nom",
    "Tag:DeleteTitle": "Supprimer le tag",
    "Tag:DeleteMessageFirstPart": "Le tag «",
"Tag:DeleteMessageSecondPart": "» sera définitivement supprimé et sera retiré de tous les réservations.",
    "Tag:DeleteButton": "Supprimer",
    "Tag:CreateTitle": "Créer un tag",
    "Tag:CreateMessage": "Veuillez saisir un nom pour le tag",
    "Tag:CreateButton": "Créer",
    "Tag:EditTitle": "Modifier le tag",
    "Tag:EditMessage": "Veuillez saisir un nouveau nom pour le tag",
    "Tag:EditButton": "Sauvegarder",
    "Tag:ErrorFieldTooLong": "Vous avez atteint la limite de caractères. Veuillez réécrire le nom du tag pour qu'il rentre dans le nombre de caractères autorisés afin de continuer",
    "Tag:ErrorTagAlreadyExist": "Ce tag existe déjà",
    "Tag:ErrorFieldRequired": "Ce champ est obligatoire",
    "Tag:NoResult": "Aucun résultat de recherche n'a été trouvé.",
    "Tag:NoEntries": "Aucune entrée",
    "Tag:ErrorOccured": "Une erreur est survenue lors du traitement de votre demande, veuillez réessayer plus tard",
    "Tag:UpdatingTags": "Nous sommes actuellement en train de mettre à jour le système de gestion des tags. Veuillez laisser le temps à Réservation de Salle Pro de terminer cette mise à jour.",
    "Tag:UpdatingTagsError": "Un problème est survenu lors de la mise à jour, veuillez réessayer plus tard. Si le problème persiste, n'hésitez pas à nous contacter.",
    "Suggest Feature": "Proposer une fonctionnalité",
    ConfigurationHelp: "Nous avons déplacé tous les paramètres vers la fenêtre principale de Réservation de Salle Pro pour un accès plus facile.",
    WelcomeMessage: "Bienvenue sur Réservation de Salle Pro ! Commencez par donner un nom à l'onglet et configurer les salles pour débuter",
    ShareToOutlook: "Ajouter à Outlook",
    SynchronizeAgenda: "Synchroniser l'agenda",
    CalendarsTitle: "Calendriers",
    WelcomePremium: "Bienvenue dans Réservation de Salle Pro Premium 🚀",
    RemoveBookingTitle: "Êtes-vous sûr de vouloir supprimer cet onglet?",
    RemoveBookingItem: "Toutes les informations associées à cet onglet seront définitivement supprimées. Vous ne pourrez pas récupérer le contenu.",
    Consent: "C'est parti 🚀",
    BookingRoomDeleted: "Ce gestionnaire de réservation de salle a été supprimé",
    addEventTitle: "{userName} a resérvé {ressourceName}",
    addEventDesc: "Une réservations vient d'être crée",
    updateEventTitle: "{userName} a mis à jour dans {ressourceName}",
    updateEventDesc: "Une réservations vient d'être mis à jour",
    deleteEventTitle: "{userName} a supprimé dans {ressourceName}",
    deleteEventDesc: "Une réservations a été supprimé",
    AddUpdateNotif: "Ajout/Mis à jour des réservations",
    DeleteNotif: "Suppression de la réservations",
    AddUpdateNotifDesc: "Envoyer une notification Teams à tous les participants lors d'un ajout ou mis à jour d'un réservations",
    DeleteNotifDesc: "Envoyer une notification Teams à tous les participants lors d'une suppression d'un réservations",
    RoomsManagement: "Gestion des places",
    participants: "Participants",
    Notifications: "Notifications",
    NewReservation: "Nouvelle réservation",
    EditReservation: "Modifier la réservation",
    AlreadyBooked: "Le créneau horaire sélectionné est déjà réservé. Veuillez choisir un autre horaire ou {event}",
    AnotherParkingPlace: "une autre place de parking",
    AnotherRoom: "une autre salle",
    UpgradeToPaidPlan: "Mettre à niveau",
    ReccuringEventIsPremium: "L'édition des événements récurrents est une fonctionnalité Premium",
    TagsPremium: "Filtrez par sujet avec des tags personnalisés grâce à {App} Premium. Voir les plans pour accéder",
    "PremiumFeature:AddToOutlook" : "Copiez les réservations importantes de Réservation de Salle Pro vers Outlook en un click.",
    "UpgradeToPremium" : "Passez à la version Premium",
    "NoTagFound" : "Aucun tag trouvé",
    WarningRecurrenceMaxDate: "Les réservations récurrentes peuvent être effectuées jusqu'à 1 an",
    "AdjustBooking": "Ajuster ma réservation",
    "ConflictBookingTitle": "Alerte de réservation en conflit",
    "ConflictBookingTagLine": "Ajustez votre réservation pour éviter les conflits ou enregistrez votre événement en excluant les dates conflictuelles.",
    "SeeConflicts": "Voir les conflits",
    "January" : "Janvier",
    "February" : "Février",
    "March" : "Mars",
    "April" : "Avril",
    "May" : "Mai",
    "June" : "Juin",
    "July" : "Juillet",
    "August" : "Août",
    "September" : "Septembre",
    "October" : "Octobre",
    "November" : "Novembre",
    "December" : "Décembre",
    "AlreadyBookedAt" : "déjà réservé à",
    "Suggested": "Suggéré : ",
  },
  de: {
    FilesTitle: "Datei",
    Booking: "Raumbuchung Pro",
    noTag: "Beginnen Sie mit dem Hinzufügen eines Tags, um zu filtern",
    Today: "Heute",
    TimeLine: "TimeLine-Ansicht",
    Month: "Monat",
    Week: "Woche",
    Day: "Tag",
    List: "Liste",
    AddEvent: "Neu",
    CreatedBy: "Erstellt von",
    UpdatedBy: "Aktualisiert von",
    AddTitle: "Einen Titel hinzufügen",
    AddDescription: "Eine Beschreibung hinzufügen",
    SelectValue: "Einen Wert auswählen",
    DeleteEvent: "Löschen",
    Cancel: "Abbrechen",
    Delete: "Löschen",
    HeaderDeleteModal: "Ereignis löschen",
    HeaderDeleteModalSerie: "Serie löschen",
    HeaderDeleteModalOccurence: "Vorkommen löschen",
    ContentDeleteModal: "Möchten Sie diese Veranstaltung löschen ?",
    Save: "Speichern",
    AllDay: "Den ganzen Tag",
    InfoUser: "Alle Benutzer können erstellen und bearbeiten",
    OnlyMe: "Ich allein kann schaffen",
    OnlyOwners: "Nur Eigentümer können erstellen und bearbeiten",
    Name: "Name",
    DefaultDisplay: "Standard-Anzeigemodus",
    FirstDay: "Erster Tag der Woche",
    SecurityMode: "Sicherheitsmodus",
    AfterActivating: "After activating this calendar, your subscription will remain",
    NbRemain: "calendars to use",
    ManageSubscription: "Manage your subscription",
    AddName: "Einen Namen hinzufügen",
    ErrorDate: "Ungültige Daten",
    ErrorTitle: "Ein Titel ist obligatorisch.",
    Close: "Schließen",
    Color: "Zurücksetzen",
    Reset: "Farbe zurücksetzen",
    Resource: "Kalender-Typ",
    Standard: "Standard",
    Resources: "Räume",
    ButtonContentResource: "Räume verwalten",
    ButtonAddRessource: "Eine Raum hinzufügen",
    InputResource: "Raum",
    ButtonDeleteRessource: "Entfernen",
    placeholderResources: "ex: Raum",
    versionPremiumText: "Raumbuchung Pro Premium: Active",
    mySubscription: "Mein Abonnement",
    purchaseCalendarPro1: "Zur Aktivierung Ihrer ",
    purchaseCalendarPro2: " räume. Schalte jetzt Raumbuchung Pro Premium frei.",
    messageFormTask: "Raumbuchung Pro Premium kaufen",
    closePremium: "Close",
    WelcomePremium: "Willkommen bei Raumbuchung Pro Premium 🚀",
    HeaderMessageWelcome: " Schalte Raumbuchung Pro Premium",
    The: "Die Registerkarte ",
    TabToRemove: " wird dauerhaft entfernt.",
    MessagePremiumCanceled: "Sie haben kein Raumbuchung Pro Premium-Abonnement, um mehr als drei räume zu verwenden.",
    ButtonInvitePremium: " Schalte Premium",
    ReactivateCalendarPremium: "Ihr Abonnement ist deaktiviert. Reaktivieren Sie jetzt Raumbuchung Pro Premium.",
    NotRepeat: "Keine Wiederholung",
    Workweek: "Von Montag bis Freitag",
    Weekly: "Wöchentlich",
    Daily: "Täglich",
    Monthly: "Monatlich",
    Yearly: "Jährlich",
    Custom: "Benutzerdefiniert...",
    UnLockRecurrence: "Kalendar Pro Premium aktivieren, um Wiederholungen zu ermöglichen",
    RecurrenceInfo: "Die Dauer Ihrer Veranstaltung darf die Ihrer Wiederholung nicht überschreiten.",
    ReactivateCalendarPremiumRecurrence: "Reaktivieren Sie jetzt Raumbuchung Pro Premium um eine Wiederholung zu ermöglichen",
    TitleCalendarPro: "Raumbuchung Pro",
    Print: "Drücken",
    UnlockMoreOptions: "Schalten Sie Raumbuchung Pro Premium frei, um mehr Optionen zu aktivieren",
    ReactivateCalendarPremiumMoreOptions: "Reactivate Raumbuchung Pro Premium to enable more options",
    TitleCustomRecurrence: "Benutzerdefinierte Wiederholung",
    DateStartRecurrence: "Anfangsdatum",
    IntervalRecurrence: "Wiederholen alle",
    DaysOfRecurrence: "Wiederholen",
    EndOfRecurrence: "Ende",
    EndsNeverRecurrence: "Nie",
    DateEndsRecurrence: "Am",
    CountOfRecurrence: "Nach",
    OccurenceOfRecurrence: "Terminen",
    SerieOfRecurrence: "Serie",
    Year: "Jahr",
    RRuleEvery: "jeden",
    RRuleMonth: "Monat",
    RRuleMonths: "Monate",
    RRuleYear: "Jahr",
    RRuleYears: "Jahre",
    RRuleOnThe: "am",
    RRuleAnd: "und",
    RRuleNd: ".",
    RRuleTh: ".",
    RRuleSt: ".",
    RRuleWeek: "woche",
    RRuleWeeks: "wochen",
    RRuleDay: "tag",
    RRuleDays: "tage",
    RRuleOn: "am",
    RRuleFor: "nach",
    RRuleTimes: "Ereignissen",
    RRuleSunday: "Sonntag",
    RRuleMonday: "Montag",
    RRuleTuesday: "Dienstag",
    RRuleWednesday: "Mittwoch",
    RRuleThursday: "Donnerstag",
    RRuleFriday: "Freitag",
    RRuleSaturday: "Samstag",
    RRuleJanuary: "Januar",
    RRuleFebruary: "Februar",
    RRuleMarch: "März",
    RRuleApril: "April",
    RRuleMay: "Kann",
    RRuleJune: "Juni",
    RRuleJuly: "Juli",
    RRuleAugust: "August",
    RRuleSeptember: "September",
    RRuleOctober: "Oktober",
    RRuleNovember: "November",
    RRuleDecember: "Dezember",
    RRuleUntil: "bis",
    First: "Ersten",
    Second: "Zweiten",
    Third: "Dritten",
    Fourth: "Vierten",
    Last: "Letzten",
    Sunday: "Sonntag",
    Monday: "Montag",
    Tuesday: "Dienstag",
    Wednesday: "Mittwoch",
    Thursday: "Donnerstag",
    Friday: "Freitag",
    Saturday: "Samstag",
    OnThe: "Am",
    LabelResource: "Raum",
    LabelTitle: "Titel",
    LabelStartDate: "Anfangsdatum",
    LabelEndDate: "Endtermin",
    LabelRecurrence: "Wiederholung",
    LabelDescription: "Beschreibung",
    LabelWarning: "Warnung",
    LabelError: "Fehler",
    RemoveBookingTitle: "Sind Sie sicher, dass Sie diesen Tab löschen möchten?",
    RemoveBookingItem: "Alle Informationen, die diesem Tab zugeordnet sind, werden dauerhaft entfernt. Sie werden den Inhalt nicht wiederherstellen können.",
    RemoveCalendarFAQ: "FAQ:",
    RemoveCalendarCancellationText: "Stornierungsrichtlinien",
    RemoveCalendarContact: "Kontaktieren Sie uns:",
    MessageAlertTokenOutlookExpired: "Die Outlook-Synchronisierung ist abgelaufen. {LinkToRenew}",
    HeaderSyncModal: "Synchronisieren {CalendarName} in Ihrem persönlichen Outlook-Kalender.",
    CheckSyncModal: "Aktivieren Sie die Synchronisierung",
    WarningBodySyncModal: "Nur die Ereignisse des aktuellen Monats und diese zukünftigen Ereignisse werden synchronisiert.",
    BodySyncModal:
      "Kalendar Pro kopiert alle Ereignisse in Ihren persönlichen Outlook-Kalender. Die Synchronisation erfolgt automatisch. Um die Synchronisierung zu aktivieren, benötigt Kalendar Pro Ihre Zustimmung, um auf Ihren persönlichen Kalender zugreifen zu können.",
    ButtonAuthorizeSyncModal: "Ich berechtige zum Zugriff auf meinen persönlichen Kalender",
    Welcome: "Willkommen bei Kalendar Pro, wir freuen uns, dass Sie hier sind!",
    WelcomeDetail:
      "Stressfrei mit Kalendar Pro für Microsoft-Teams. Innerhalb eines Channels können Sie ganz einfach Teams organisieren, die Verfügbarkeit verwalten, Arbeit planen und Veranstaltungen gemeinsam nutzen.",
    Consent: "Let's go 🚀",
    OptionUnableInOutlook: "Eine Option für mehr als einen Tag kann nicht in Aussicht gestellt werden",
    RenewMessage: "Erneuern",
    RenewToken: "Klicken Sie hier, um zu erneuern",
    OnlyOwnersInfo: "Diese Option ist nur für Premium-Benutzer von Kalendar pro verfügbar",
    DiscardMessageTitle: "Nicht gespeicherte Änderungen",
    DiscardChanges: "Verwerfen",
    ContinueEditing: "Bearbeitung fortsetzen",
    DiscardMessage: "Möchten Sie Änderungen verwerfen oder mit der Bearbeitung fortfahren ?",
    MessageOnlyMePrevent: "{createdBy} Sie daran gehindert hat, Termine in diesem Kalender hinzuzufügen/zu bearbeiten.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit events in this calendar",
    DeleteOccurrence: "Serienelement löschen",
    DeleteSeries: "Serie löschen",
    MessageDeleteRecurrence: "Möchten Sie dieses ",
    ButtonDeleteRecurrence: " ",
    EditEvent: "Bearbeiten",
    EditOccurrence: "Serienelement bearbeiten",
    EditSeries: "Serie bearbeiten",
    Occurrence: "Serienelement löschen",
    Series: "Serie löschen",
    ViewOccurrenceOfSeries: "Sie zeigen ein Element einer Serie an.",
    LoadData: "Lade Daten...",
    LoadCalendar: "Laden Sie Ihren Kalender...",
    InfoEventId: "Ereignis-ID",
    InfoEventSerieId: "Serien-ID",
    InfoEventCreatedBy: "Erstellt von",
    InfoEventUpdatedBy: "Aktualisiert von",
    InfoEventTimezone: "Zeitzone",
    infoEventHeader: "Informationen zur Veranstaltung",
    CopyToClipboard: "In die Zwischenablage kopieren",
    Copied: "Kopiert !",
    InfoEvent: "Ereignisdiagnose",
    EstimatedTimeSync: "Wir werden {numberOfEvent} Buchungen synchronisieren, dies kann {EstimatedSyncTime} Stunden dauern.",
    Upgrade: "Upgrade Raumbuchung Pro",
    UpgradeDetail: "Für ein Upgrade klicken Sie oben auf die Upgrade-Schaltfläche.",
    AnyEventInResources: "Wenn Sie in Raumbuchung Pro vom Standardmodus in den Raummodus wechseln, werden alle Ereignisse, die nicht mit einem Raum verknüpft sind, im Raummodus nicht angezeigt.",
    NoResource: "Kein Raum",
    EmptyFillError: "Dieses Feld darf nicht leer sein",
    GoDesktopBuyPremium: "Sie müssen die Computerversion der Anwendung verwenden, um den Premium-Modus zu aktivieren",
    Ok: "Ok",
    NoRightToUpdateConfig: "Sie dürfen die Konfiguration nicht ändern",
    UpdatingApp: "Aktualisierung der Anwendung",
    LinkTitle: "Link",
    AddLink: "Einen Link hinzufügen",
    ErrorMessageUrl: "Bitte geben Sie eine gültige Website-URL ein, z. B.: https://www.domain.com oder domain.com",
    AddressTitle: "Anschrift",
    AddAddress: "Eine Adresse eingeben",
    TagsTitle: "Tags",
    LabelSelectionTimeZone: "Zeitzone",
    EditTextConfirm: "Speichern",
    EditTextEdit: "Bearbeiten",
    EditTextRemove: "Löschen",
    EditTextLink: "Link",
    Add: "Hinzufügen",
    Forward: "Fortgeschrittene",
    AlertTagsAndMore: "Um erweiterte Funktionen zu nutzen, gehen Sie zu Premium ",
    Copy: "Kopieren",
    About: "Über",
    UniqueId: "Eindeutiger Bezeichner",
    WarningUpdateRecurrenceMessage: "Sind Sie sicher, dass Sie die Serie speichern wollen? Alle benutzerdefinierten Vorkommen werden gelöscht",
    Version: "Version",
    BookingRoomDeleted: "Dieser Raumbelegungsmanager wurde gelöscht",
    Settings: "Einstellungen",
    Permissions: "Berechtigungen",
    RoomsManagement: "Raumverwaltung",
    "Suggest Feature": "Funktionalität vorschlagen",
    "Tag:Title": "Tag-Management",
    "Tag:Create": "Neuen Tag erstellen",
    "Tag:Search": "Geben Sie hier ein, um einen Tag zu suchen",
    "Tag:TableName": "Name",
    "Tag:DeleteTitle": "Tag löschen",
    "Tag:DeleteMessageFirstPart": "Der Tag «",
"Tag:DeleteMessageSecondPart": "» wird dauerhaft gelöscht und von allen Ereignissen entfernt.",
    "Tag:DeleteButton": "Löschen",
    "Tag:CreateTitle": "Tag erstellen",
    "Tag:CreateMessage": "Bitte geben Sie einen Namen für den Tag ein",
    "Tag:CreateButton": "Erstellen",
    "Tag:EditTitle": "Tag bearbeiten",
    "Tag:EditMessage": "Bitte geben Sie einen neuen Namen für den Tag ein",
    "Tag:EditButton": "Speichern",
    "Tag:ErrorFieldTooLong": "Sie haben das Zeichenlimit erreicht. Bitte schreiben Sie den Tag-Namen neu, um innerhalb der zulässigen Zeichenanzahl fortzufahren.",
    "Tag:ErrorTagAlreadyExist": "Dieser Tag existiert bereits",
    "Tag:ErrorFieldRequired": "Dieses Feld ist erforderlich",
    "Tag:NoResult": "Es wurden keine Suchergebnisse gefunden.",
    "Tag:NoEntries": "Kein Eintrag",
    "Tag:ErrorOccured": "Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    "Tag:UpdatingTags": "Wir sind derzeit dabei, das Tag-Verwaltungssystem zu aktualisieren. Bitte geben Sie Raumbuchung Pro etwas Zeit, um dieses Update abzuschließen.",
    "Tag:UpdatingTagsError": "Während des Updates trat ein Problem auf, bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, zögern Sie nicht, uns zu kontaktieren.",
    ConfigurationHelp: "Wir haben alle Einstellungen in das Hauptfenster von Raumbuchung Pro verschoben, um den Zugriff zu erleichtern.",
    WelcomeMessage: "Willkommen bei Raumbuchung Pro! Beginnen Sie mit der Benennung des Tabs und der Konfiguration der Räume, um loszulegen",
    addEventTitle: "{userName} hat {ressourceName} gebucht",
    addEventDesc: "Eine Reservierung wurde gerade erstellt",
    updateEventTitle: "{userName} hat in {ressourceName} aktualisiert",
    updateEventDesc: "Eine Reservierung wurde gerade aktualisiert",
    deleteEventTitle: "{userName} hat in {ressourceName} gelöscht",
    deleteEventDesc: "Eine Reservierung wurde gelöscht",
    participants: "Teilnehmer",
    Notifications: "Benachrichtigungen",
    AddUpdateNotif: "Hinzugefügte/aktualisierte Buchungen",
    DeleteNotif: "Gelöschte Buchungen",
    AddUpdateNotifDesc: "Senden Sie eine Teams-Benachrichtigung an alle Teilnehmer über eine neue oder aktualisierte Buchung",
    DeleteNotifDesc: "Senden Sie eine Teams-Benachrichtigung an alle Teilnehmer über eine gelöschte Buchung",
    ShowWeekend: "Wochenende anzeigen",
    NewReservation: "Neue Reservierung",
    EditReservation: "Reservierung bearbeiten",
    AlreadyBooked: "Der ausgewählte Zeitfenster ist bereits gebucht. Bitte wählen Sie ein anderes Zeitfenster oder {event}",
    AnotherParkingPlace: "einen anderen Parkplatz",
    AnotherRoom: "einen anderen Raum",
    UpgradeToPaidPlan: "Aktualisieren",
    ReccuringEventIsPremium: "Das Bearbeiten wiederkehrender Ereignisse ist eine Premium-Funktion",
    TagsPremium: "Filtern Sie nach Thema mit benutzerdefinierten Tags mit {App} Premium. Pläne für den Zugang ansehen.",
    "PremiumFeature:AddToOutlook" : "Kopieren Sie wichtige Buchungen von Raumbuchung Pro nach Outlook mit einem Klick.",
    "UpgradeToPremium" : "Upgraden Sie auf Premium",
    "NoTagFound" : "Kein Tag gefunden",
    WarningRecurrenceMaxDate: "Wiederkehrende Buchungen können nur bis zu einem Jahr im Voraus gebucht werden",
    "AdjustBooking": "Meine Buchung anpassen",
    "ConflictBookingTitle": "Benachrichtigung über widersprüchliche Buchung",
    "ConflictBookingTagLine": "Passen Sie Ihre Buchung an, um Konflikte zu vermeiden, oder speichern Sie Ihr Ereignis ohne die konfliktträchtigen Daten.",
    "SeeConflicts": "Konflikte sehen",
    "January" : "Januar",
    "February" : "Februar",
    "March" : "März",
    "April" : "April",
    "May" : "Mai",
    "June" : "Juni",
    "July" : "Juli",
    "August" : "August",
    "September" : "September",
    "October" : "Oktober",
    "November" : "November",
    "December" : "Dezember",
    "AlreadyBookedAt" : "bereits gebucht um",
    "Suggested": "Vorgeschlagen : ",
  },
  it: {
    FilesTitle: "File",
    Booking: "Prenota Sala Pro",
    noTag: "Inizia aggiungendo un tag per filtrare",
    Today: "Oggi",
    TimeLine: "Visualizzazione TimeLine",
    Month: "Mese",
    Week: "Settimana",
    Day: "Giorno",
    List: "Elenco",
    AddEvent: "Nuovo",
    CreatedBy: "Creato da",
    UpdatedBy: "Aggiornato da",
    AddTitle: "Aggiungi un titolo",
    AddDescription: "Aggiungi una descrizione",
    SelectValue: "Seleziona un valore",
    DeleteEvent: "Elimina questo evento",
    Cancel: "Annulla",
    Delete: "Elimina",
    HeaderDeleteModal: "Elimina evento",
    HeaderDeleteModalSerie: "Elimina serie",
    HeaderDeleteModalOccurence: "Elimina occorrenza",
    ContentDeleteModal: "Vuoi eliminare questo evento ?",
    Save: "Salva",
    AllDay: "Tutto il giorno",
    InfoUser: "Tutti gli utenti possono creare e modificare eventi",
    OnlyMe: "Solo io posso creare eventi",
    OnlyOwners: "Solo i proprietari possono creare e modificare",
    Name: "Nome",
    DefaultDisplay: "Modalità di visualizzazione predefinita",
    FirstDay: "Primo giorno della settimana",
    SecurityMode: "Modalità di sicurezza",
    AfterActivating: "Dopo aver attivato questo calendario, il tuo abbonamento rimarrà",
    NbRemain: "calendari da utilizzare",
    ManageSubscription: "Gestisci il tuo abbonamento",
    AddName: "Aggiungi un nome",
    ErrorDate: "Date non valide",
    ErrorTitle: "Un titolo è obbligatorio.",
    Close: "Chiudi",
    Color: "Colore",
    Reset: "Ripristina il colore",
    Resource: "Tipo di calendario",
    Standard: "Standard",
    Resources: "Camere",
    ButtonContentResource: "Gestire le camere",
    ButtonAddRessource: "Aggiungi una camera",
    InputResource: "Camera",
    ButtonDeleteRessource: "Rimuovi",
    placeholderResources: "ex: Aula ",
    versionPremiumText: "Prenota Sala Pro Premium: Attivo",
    mySubscription: "Il mio abbonamento",
    purchaseCalendarPro1: "Per attivare il tuo ",
    purchaseCalendarPro2: " camere. Sblocca ora Prenota Sala Pro Premium.",
    messageFormTask: "Sblocca Prenota Sala Pro Premium",
    closePremium: "Chiudi",
    WelcomePremium: "Benvenuto in Prenota Sala Pro Premium 🚀",
    HeaderMessageWelcome: "Prenota Sala Pro Premium",
    The: "La scheda ",
    TabToRemove: " verrà rimossa in modo permanente.",
    MessagePremiumCanceled: "Non disponi di un abbonamento a Prenota Sala Pro Premium per utilizzare più di tre camere.",
    ButtonInvitePremium: "Sblocca Premium",
    ReactivateCalendarPremium: "Il tuo abbonamento è disattivato. Riattiva subito Prenota Sala Pro Premium.",
    NotRepeat: "Non ripetere",
    Workweek: "Dal lunedì al venerdì",
    Weekly: "Settimanale ",
    Daily: "Giornaliero",
    Monthly: "Mensile",
    Yearly: "Annuale",
    Custom: "Personalizza...",
    UnLockRecurrence: "Sblocca Prenota Sala Pro Premium per abilitare la ricorrenza",
    RecurrenceInfo: "La durata del tuo evento non può superare quella della tua ricorrenza.",
    ReactivateCalendarPremiumRecurrence: "Riattiva subito Prenota Sala Pro Premium per abilitare la ricorrenza",
    TitleCalendarPro: "Prenota Sala Pro",
    Print: "Stampa",
    UnlockMoreOptions: "Sblocca Prenota Sala Pro Premium per abilitare più opzioni",
    ReactivateCalendarPremiumMoreOptions: "Riattiva subito Prenota Sala Pro Premium per abilitare più opzioni",
    TitleCustomRecurrence: "Ricorrenza personalizzata",
    DateStartRecurrence: "Data d'inizio",
    IntervalRecurrence: "Ripeti ogni",
    DaysOfRecurrence: "Si ripete",
    EndOfRecurrence: "Fine",
    EndsNeverRecurrence: "Mai",
    DateEndsRecurrence: "Il",
    CountOfRecurrence: "Dopo",
    OccurenceOfRecurrence: "Occorrenze",
    SerieOfRecurrence: "Serie",
    Year: "Anno",
    RRuleEvery: "Ogni",
    RRuleMonth: "mese",
    RRuleMonths: "mesi",
    RRuleYear: "anno",
    RRuleYears: "anni",
    RRuleOnThe: "il",
    RRuleAnd: "e",
    RRuleNd: " ",
    RRuleTh: " ",
    RRuleSt: " ",
    RRuleWeek: "settimana",
    RRuleWeeks: "settimane",
    RRuleDay: "giono",
    RRuleDays: "giorni",
    RRuleOn: "il",
    RRuleFor: "dopo",
    RRuleTimes: "occorrenze",
    RRuleSunday: "Domenica",
    RRuleMonday: "Lunedi",
    RRuleTuesday: "Martedì",
    RRuleWednesday: "Mercoledì",
    RRuleThursday: "Giovedì",
    RRuleFriday: "Venerdì",
    RRuleSaturday: "Sabato",
    RRuleJanuary: "Gennaio",
    RRuleFebruary: "Febbraio",
    RRuleMarch: "Marzo",
    RRuleApril: "Aprile",
    RRuleMay: "Maggio",
    RRuleJune: "Giugno",
    RRuleJuly: "Luglio",
    RRuleAugust: "Agosto",
    RRuleSeptember: "Settembre",
    RRuleOctober: "Ottobre",
    RRuleNovember: "Novembre",
    RRuleDecember: "Dicembre",
    RRuleUntil: "fino al",
    First: "Primo",
    Second: "Secondo",
    Third: "Terzo",
    Fourth: "Quarto",
    Last: "Ultimo",
    Sunday: "Domenica",
    Monday: "Lunedi",
    Tuesday: "Martedì",
    Wednesday: "Mercoledì",
    Thursday: "Giovedì",
    Friday: "Venerdì",
    Saturday: "Sabato",
    OnThe: "Il/l'",
    LabelResource: "Camera",
    LabelTitle: "Titolo",
    LabelStartDate: "Data d'inizio",
    LabelEndDate: "Data di finee",
    LabelRecurrence: "Ricorrenza",
    LabelDescription: "Descrizione",
    LabelWarning: "Avvertimento",
    LabelError: "Errore",
    RemoveBookingTitle: "Sei sicuro di voler eliminare questa scheda?",
    RemoveBookingItem: "Tutte le informazioni associate a questa scheda verranno rimosse permanentemente. Non sarai in grado di recuperare il contenuto.",
    RemoveCalendarFAQ: "FAQ:",
    RemoveCalendarCancellationText: "Politica di cancellazione",
    RemoveCalendarContact: "Contattaci:",
    MessageAlertTokenOutlookExpired: "La sincronizzazione di Outlook è scaduta. {LinkToRenew}",
    HeaderSyncModal: "Sincronizzare {CalendarName} nel tuo calendario personale di Outlook.",
    CheckSyncModal: "Abilita sincronizzazione",
    WarningBodySyncModal: "Verranno sincronizzati solo gli eventi del mese corrente e quelli futuri future.",
    BodySyncModal:
      "Prenota Sala Pro copia tutti gli eventi nel tuo calendario personale di Outlook. La sincronizzazione è automatica. Per abilitare la sincronizzazione, Prenota Sala Pro necessita del tuo consenso per accedere al tuo calendario personale.",
    ButtonAuthorizeSyncModal: "Autorizzo ad accedere al mio calendario personale",
    Welcome: "Benvenuto in Prenota Sala Pro, siamo contenti che tu sia qui!",
    WelcomeDetail:
      "Senza stress con Prenota Sala Pro per Microsoft Teams. All'interno di un canale, è possibile organizzare facilmente team, gestire la disponibilità, programmare il lavoro e condividere eventi.",
    Consent: "Let's go 🚀",
    OptionUnableInOutlook: "L'opzione per più di un giorno non è disponibile su Outlook",
    RenewMessage: "Rinnova",
    RenewToken: "Clicca qui per rinnovare",
    OnlyOwnersInfo: "Questa opzione è disponibile solo per gli utenti premium di Prenota Sala Pro",
    DiscardMessageTitle: "Modifiche non salvate",
    DiscardChanges: "Rimuovi",
    ContinueEditing: "Continua la modifica",
    DiscardMessage: "Vuoi continuare ad apportare modifiche o rimuoverle ?",
    MessageOnlyMePrevent: "{createdBy} ti ha impedito di aggiungere/modificare eventi in questo calendario.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit events in this calendar",
    DeleteOccurrence: "Elimina occorrenza",
    DeleteSeries: "Elimina serie",
    MessageDeleteRecurrence: "Vuoi eliminare questa ",
    ButtonDeleteRecurrence: "Elimiina ",
    EditEvent: "Modifica",
    EditOccurrence: "Modifica occorrenza",
    EditSeries: "Modifica serie",
    Occurrence: "occorrenza",
    Series: "serie",
    ViewOccurrenceOfSeries: "Stai visualizzando un'occorrenza di una serie.",
    LoadData: "Caricamento dati...",
    LoadCalendar: "Caricamento del calendario...",
    InfoEventId: "ID evento",
    InfoEventSerieId: "ID serie",
    InfoEventCreatedBy: "Creato da",
    InfoEventUpdatedBy: "Aggiornato da",
    InfoEventTimezone: "Fuso orario",
    infoEventHeader: "Informazioni sull'evento",
    CopyToClipboard: "Copia negli appunti",
    Copied: "Copiato !",
    InfoEvent: "Informazioni",
    EstimatedTimeSync: "Sincronizzeremo {numberOfEvent} prenotazioni, questo potrebbe richiedere {EstimatedSyncTime} ore.",
    Upgrade: "Aggiorna Prenota Sala Pro",
    UpgradeDetail: "Per l'aggiornamento, fare clic sul pulsante di aggiornamento in alto.",
    AnyEventInResources: "Quando passi dalla modalità standard alla modalità camera in Prenota Sala Pro, tutti gli eventi non collegati a una camera non saranno visualizzati nella modalità camera.",
    NoResource: "Nessuna camera",
    EmptyFillError: "Questo campo non può essere vuoto",
    GoDesktopBuyPremium: "È necessario utilizzare la versione per computer dell'applicazione per attivare la modalità premium",
    Ok: "Ok",
    NoRightToUpdateConfig: "Non ti è permesso cambiare la configurazione",
    UpdatingApp: "Aggiornamento dell'applicazione",
    LinkTitle: "Collegamento",
    AddLink: "Aggiungere un collegamento",
    ErrorMessageUrl: "Si prega di inserire un url valido del sito web, es: https://www.domain.com o domain.com",
    AddressTitle: "Indirizzo",
    AddAddress: "Inserisci un indirizzo",
    TagsTitle: "Tags",
    LabelSelectionTimeZone: "Fuso orario",
    EditTextConfirm: "Salva",
    EditTextEdit: "Modifica",
    EditTextRemove: "Elimina",
    EditTextLink: "Url",
    Add: "Aggiungi",
    Forward: "Avanzato",
    AlertTagsAndMore: "Per utilizzare le funzioni avanzate vai a Premium 🚀",
    Copy: "Copia",
    About: "A proposito",
    UniqueId: "Identificatore unico",
    WarningUpdateRecurrenceMessage: "Sei sicuro di salvare la serie? Tutte le occorrenze personalizzate saranno cancellate",
    Version: "Versione",
    BookingRoomDeleted: "Questo gestore di prenotazioni di camere è stato rimosso",
    Settings: "Impostazioni",
    Permissions: "Autorizzazioni",
    RoomsManagement: "Gestione delle camere",
    "Suggest Feature": "Suggerisci funzione",
    "Tag:Title": "Gestione dei tag",
    "Tag:Create": "Crea nuovo tag",
    "Tag:Search": "Digita qui per cercare un tag",
    "Tag:TableName": "Nome",
    "Tag:DeleteTitle": "Elimina tag",
    "Tag:DeleteMessageFirstPart": "Il tag «",
"Tag:DeleteMessageSecondPart": "» verrà eliminato definitivamente e rimosso da tutti gli eventi.",
    "Tag:DeleteButton": "Elimina",
    "Tag:CreateTitle": "Crea tag",
    "Tag:CreateMessage": "Inserisci un nome per il tag",
    "Tag:CreateButton": "Crea",
    "Tag:EditTitle": "Modifica tag",
    "Tag:EditMessage": "Inserisci un nuovo nome per il tag",
    "Tag:EditButton": "Salva",
    "Tag:ErrorTagAlreadyExist": "Questo tag esiste già",
    "Tag:ErrorFieldTooLong": "Hai raggiunto il limite di caratteri. Ti preghiamo di riscrivere il nome del tag per farlo rientrare nel conteggio dei caratteri consentiti per poter procedere.",
    "Tag:ErrorFieldRequired": "Questo campo è obbligatorio",
    "Tag:NoResult": "Nessun risultato di ricerca è stato trovato.",
    "Tag:NoEntries": "Nessuna voce",
    "Tag:ErrorOccured": "Si è verificato un errore durante l'elaborazione della richiesta. Riprova più tardi.",
    "Tag:UpdatingTags":
      "Siamo attualmente in fase di aggiornamento del sistema di gestione delle etichette. Si prega di concedere a Prenota Sala Pro il tempo necessario per completare questo aggiornamento.",
    "Tag:UpdatingTagsError": "Si è verificato un problema durante l'aggiornamento, si prega di riprovare più tardi. Se il problema persiste, sentitevi liberi di contattarci.",
    ConfigurationHelp: "Abbiamo spostato tutte le impostazioni nella finestra principale di Prenota Sala Pro per facilitare l'accesso.",
    WelcomeMessage: "Benvenuti in Prenota Sala Pro! Iniziate dando un nome alla scheda e configurando le stanze per iniziare",
    addEventTitle: "{userName} ha prenotato {ressourceName}",
    addEventDesc: "Una prenotazione è stata appena creata",
    updateEventTitle: "{userName} ha aggiornato in {ressourceName}",
    updateEventDesc: "Una prenotazione è stata appena aggiornata",
    deleteEventTitle: "{userName} ha eliminato in {ressourceName}",
    deleteEventDesc: "Una prenotazione è stata eliminata",
    participants: "Partecipanti",
    Notifications: "Notifiche",
    AddUpdateNotif: "Prenotazioni aggiunte/aggiornate",
    DeleteNotif: "Prenotazioni cancellate",
    AddUpdateNotifDesc: "Invia una notifica di Teams a tutti i partecipanti di una nuova o aggiornata prenotazione",
    DeleteNotifDesc: "Invia una notifica di Teams a tutti i partecipanti di una prenotazione cancellata",
    ShowWeekend: "Mostra fine settimana",
    NewReservation: "Nuova prenotazione",
    EditReservation: "Modifica prenotazione",
    AlreadyBooked: "La fascia oraria selezionata è già prenotata. Si prega di scegliere un'altra fascia oraria o {event}",
    AnotherParkingPlace: "un altro posto auto",
    AnotherRoom: "un'altra sala",
    UpgradeToPaidPlan: "Aggiornare",
    ReccuringEventIsPremium: "La modifica degli eventi ricorrenti è una funzione Premium",
    TagsPremium: "Filtra per argomento con tag personalizzati utilizzando {App} Premium. Vedi i piani per l'accesso.",
    "PremiumFeature:AddToOutlook" : "Copia le prenotazioni importanti da Prenota Sala Pro a Outlook con un clic.",
    "UpgradeToPremium" : "Passa a Premium",
    "NoTagFound" : "Nessun tag trovato",
    WarningRecurrenceMaxDate: "Le prenotazioni ricorrenti possono essere effettuate solo fino a 1 anno",
    "AdjustBooking": "Regola la mia prenotazione",
    "ConflictBookingTitle": "Avviso di prenotazione in conflitto",
    "ConflictBookingTagLine": "Regola la tua prenotazione per evitare conflitti o salva il tuo evento escludendo le date in conflitto.",
    "SeeConflicts": "Vedi conflitti",
    "January" : "Gennaio",
    "February" : "Febbraio",
    "March" : "Marzo",
    "April" : "Aprile",
    "May" : "Maggio",
    "June" : "Giugno",
    "July" : "Luglio",
    "August" : "Agosto",
    "September" : "Settembre",
    "October" : "Ottobre",
    "November" : "Novembre",
    "December" : "Dicembre",
    "AlreadyBookedAt" : "già prenotato a",
    "Suggested": "Suggerito : ",
  },
  es: {
    FilesTitle: "Archivo",
    Booking: "Reservacion de Salas Pro",
    noTag: "Comience por agregar una etiqueta para filtrar",
    Today: "Hoy",
    TimeLine: "Vista Timeline",
    Month: "Mes",
    Week: "Semana",
    Day: "Día",
    List: "Lista",
    AddEvent: "Nuevo",
    CreatedBy: "Creado por",
    UpdatedBy: "Actualizado por",
    AddTitle: "Agregar un título",
    AddDescription: "Agregar una descripción",
    SelectValue: "Selectionar un valor",
    DeleteEvent: "Suprimir",
    Cancel: "Anular",
    Delete: "Suprimir",
    HeaderDeleteModal: "Eliminar evento",
    HeaderDeleteModalSerie: "Eliminar serie",
    HeaderDeleteModalOccurence: "Eliminar ocurrencia",
    ContentDeleteModal: "¿ Quieres eliminar este evento ?",
    Save: "Guardar",
    AllDay: "Todo el día",
    InfoUser: "Todos los usuarios pueden crear y editar",
    OnlyMe: "Solo yo puedo crear",
    OnlyOwners: "Solo los propietarios pueden crear y editar",
    Name: "Nombre",
    DefaultDisplay: "Modo de visualización por defecto",
    FirstDay: "Primer día de la semana",
    SecurityMode: "Modo de seguridad",
    AddName: "Agregue un nombre",
    ErrorDate: "Fechas inválidas",
    ErrorTitle: "Un título es obligatorio",
    Close: "Cerrar",
    Color: "Color",
    Reset: "Reinicializar color",
    Resource: "Tipo de calendario",
    Standard: "Standard",
    Resources: "Salas",
    ButtonContentResource: "Administrar salas",
    ButtonAddRessource: "Añadir una sala",
    InputResource: "Sala",
    ButtonDeleteRessource: "Suprimir",
    placeholderResources: "ex: Sala ",
    versionPremiumText: "Reservacion de Salas Pro Premium: Activado",
    mySubscription: "Mi suscripción",
    purchaseCalendarPro1: "Para utilisar sus ",
    purchaseCalendarPro2: " salas. Active ahora Reservacion de Salas Pro Premium.",
    messageFormTask: "Active Reservacion de Salas Pro Premium",
    closePremium: "Cerrar",
    WelcomePremium: "Bienvenido a Reservacion de Salas Pro Premium 🚀",
    HeaderMessageWelcome: "Reservacion de Salas Pro Premium",
    The: "La ficha ",
    TabToRemove: " se eliminará de forma permanente.",
    MessagePremiumCanceled: "Usted no tiene una suscripción a Reservacion de Salas Pro Premium para usar más de tres salas.",
    ButtonInvitePremium: "Activar Premium",
    ReactivateCalendarPremium: "Su suscripción esta desactivada. Reactive Reservacion de Salas Pro Premium ahora.",
    NotRepeat: "No repetir",
    Workweek: "De Lunes a Viernes",
    Weekly: "Semanalmente",
    Daily: "Diariamente",
    Monthly: "Mensualmente",
    Yearly: "Anualmente",
    Custom: "Personalizar...",
    UnLockRecurrence: "Desbloquee Calendrio Pro Premium para habilitar la recurrencia",
    RecurrenceInfo: "La duración del evento no puede sobrepasar a la de la recurrencia.",
    ReactivateCalendarPremiumRecurrence: "Reactive Reservacion de Salas Pro Premium para habilitar la recurrencia",
    TitleCalendarPro: "Reservacion de Salas Pro",
    Print: "Imprimir",
    UnlockMoreOptions: "Desbloquee Calendrio Pro Premium para habilitar más opciones",
    ReactivateCalendarPremiumMoreOptions: "Reactive Reservacion de Salas Pro Premium para habilitar más opciones",
    TitleCustomRecurrence: "Periodicidad personalizada",
    DateStartRecurrence: "Fecha inicio",
    IntervalRecurrence: "Repetir cada",
    DaysOfRecurrence: "Se repite el",
    EndOfRecurrence: "Termina",
    EndsNeverRecurrence: "Nunca",
    DateEndsRecurrence: "El",
    CountOfRecurrence: "Después de",
    OccurenceOfRecurrence: "Repeticiones",
    SerieOfRecurrence: "Series",
    Year: "Año",
    RRuleEvery: "Cada",
    RRuleMonth: "mes",
    RRuleMonths: "meses",
    RRuleYear: "año",
    RRuleYears: "años",
    RRuleOnThe: "el",
    RRuleAnd: "",
    RRuleNd: "",
    RRuleTh: "",
    RRuleSt: "",
    RRuleWeek: "semana",
    RRuleWeeks: "semanas",
    RRuleDay: "día",
    RRuleDays: "días",
    RRuleOn: "el",
    RRuleFor: "después",
    RRuleTimes: "ocurrencias",
    RRuleSunday: "Domingo",
    RRuleMonday: "Lunes",
    RRuleTuesday: "Martes",
    RRuleWednesday: "Miércoles",
    RRuleThursday: "Jueves",
    RRuleFriday: "Viernes",
    RRuleSaturday: "Sábado",
    RRuleJanuary: "Enero",
    RRuleFebruary: "Febrero",
    RRuleMarch: "Marzo",
    RRuleApril: "Abril",
    RRuleMay: "Mayo",
    RRuleJune: "Junio",
    RRuleJuly: "Julio",
    RRuleAugust: "Agosto",
    RRuleSeptember: "Septiembre",
    RRuleOctober: "Octubre",
    RRuleNovember: "Noviembre",
    RRuleDecember: "Deciembre",
    RRuleUntil: "hasta",
    First: "Primer",
    Second: "Segundo",
    Third: "Tercer",
    Fourth: "Cuarto",
    Last: "Último",
    Sunday: "Domingo",
    Monday: "Lunes",
    Tuesday: "Master",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    OnThe: "Le",
    LabelResource: "Sala",
    LabelTitle: "Título",
    LabelStartDate: "Fecha inicio",
    LabelEndDate: "Fecha de Término",
    LabelRecurrence: "Periodicidad",
    LabelDescription: "Descripción",
    LabelWarning: "Advertencia",
    LabelError: "Error",
    RemoveBookingTitle: "¿Estás seguro de que quieres eliminar esta pestaña?",
    RemoveBookingItem: "Toda la información asociada a esta pestaña será eliminada permanentemente. No podrás recuperar el contenido.",
    RemoveCalendarFAQ: "FAQ:",
    RemoveCalendarCancellationText: "Política de cancelación",
    RemoveCalendarContact: "Contacto:",
    MessageAlertTokenOutlookExpired: "La sincronización de Outlook ha caducado. {LinkToRenew}",
    HeaderSyncModal: "Synchronize {CalendarName} in your personal Outlook calendar.",
    CheckSyncModal: "Habilitar la sincronización",
    WarningBodySyncModal: "Solo los eventos del persente mes y aquellos eventos futuros seran sincronizados.",
    BodySyncModal:
      "Reservacion de Salas Pro copia todos los eventos en su calendario personal de Outlook. La sincronización es automática. Para habilitar la sincronización, Reservacion de Salas Pro necesita su consentimiento para acceder a su calendario personal.",
    ButtonAuthorizeSyncModal: "Autorizo el acceso a mi calendario personal",
    Welcome: "Bienvenido a Reservacion de Salas Pro, ¡nos alegra que esté aquí!",
    WelcomeDetail:
      "Libre de estrés con Reservacion de Salas Pro para Microsoft Teams. Dentro de un canal, puedes organizar equipos, gestionar la disponibilidad, programar el trabajo y compartir eventos muy fácilmente..",
    Consent: "Let's go 🚀",
    OptionUnableInOutlook: "La opción de más de un día no es posible en Outlook",
    RenewMessage: "Renovar",
    RenewToken: "Haga clic aquí para renovar",
    OnlyOwnersInfo: "Esta opción solo está disponible para usuarios premium de Reservacion de Salas Pro",
    DiscardMessageTitle: "Cambios no guardados",
    DiscardChanges: "Descartar",
    ContinueEditing: "Continuar la edición",
    DiscardMessage: "¿ Deseas descartar las modificaciones o continuar con la edición ?",
    MessageOnlyMePrevent: "{createdBy} le ha impedido añadir/editar eventos en este calendario.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit events in this calendar",
    DeleteOccurrence: "Eliminar evento recurrente",
    DeleteSeries: "Eliminar seris",
    MessageDeleteRecurrence: "¿Deseas eliminar este ",
    ButtonDeleteRecurrence: "Eliminar ",
    EditEvent: "Editar",
    EditOccurrence: "Editar evento recurrente",
    EditSeries: "Editar serie",
    Occurrence: "evento recurrente",
    Series: "serie",
    ViewOccurrenceOfSeries: "Está viendo una repetición de una serie.",
    LoadData: "Cargando datos...",
    LoadCalendar: "Cargando su calendario...",
    InfoEventId: "Evento Id",
    InfoEventSerieId: "Serie ID",
    InfoEventCreatedBy: "Creado por",
    InfoEventUpdatedBy: "Actualizado por",
    InfoEventTimezone: "Zona horaria",
    infoEventHeader: "Información del evento",
    CopyToClipboard: "Copiar al portapapeles",
    Copied: "Copiado !",
    InfoEvent: "Información",
    EstimatedTimeSync: "Vamos a sincronizar {numberOfEvent} reservas, esto puede tardar {EstimatedSyncTime} horas.",
    Upgrade: "Actualizar Reservacion de Salas Pro",
    UpgradeDetail: "Para actualizar, haga clic en el botón de actualización en la parte superior.",
    AnyEventInResources: "Cuando se pasa del modo estándar al modo sala en Reservacion de Salas Pro, todos los eventos no vinculados a una sala no se mostrarán en el modo sala.",
    NoResource: "No hay sala",
    EmptyFillError: "Este campo no puede estar vacío",
    GoDesktopBuyPremium: "Debes utilizar la versión de ordenador de la aplicación para activar el modo premium",
    Ok: "Ok",
    NoRightToUpdateConfig: "No está permitido cambiar la configuración",
    UpdatingApp: "Actualización de la aplicación",
    LinkTitle: "Enlace",
    AddLink: "Añadir un enlace",
    ErrorMessageUrl: "Por favor, introduzca una url válida del sitio web, por ejemplo: https://www.domain.com o dominio.com",
    AddressTitle: "Dirección",
    AddAddress: "Introduce una dirección",
    TagsTitle: "Etiquetas",
    LabelSelectionTimeZone: "Huso horario",
    EditTextConfirm: "Guardar",
    EditTextEdit: "Editar",
    EditTextRemove: "Borrar",
    EditTextLink: "Url",
    Add: "Añadir",
    Forward: "Avanzado",
    AlertTagsAndMore: "Para utilizar las funciones avanzadas vaya a Premium ",
    About: "Acerca",
    Copy: "Copiar",
    UniqueId: "Identificador único",
    WarningUpdateRecurrenceMessage: "¿Está seguro de guardar la serie? Todas las ocurrencias personalizadas se borrarán",
    Version: "Versión",
    BookingRoomDeleted: "Este gestor de reservas de habitaciones ha sido eliminado",
    Settings: "Configuración",
    Permissions: "Permisos",
    RoomsManagement: "Gestión de salas",
    "Suggest Feature": "Sugerir función",
    "Tag:Title": "Gestión de etiquetas",
    "Tag:Create": "Crear nueva etiqueta",
    "Tag:Search": "Escriba aquí para buscar una etiqueta",
    "Tag:TableName": "Nombre",
    "Tag:DeleteTitle": "Eliminar etiqueta",
    "Tag:DeleteMessageFirstPart": "La etiqueta «",
"Tag:DeleteMessageSecondPart": "» se eliminará definitivamente y se eliminará de todos los eventos.",
    "Tag:DeleteButton": "Eliminar",
    "Tag:CreateTitle": "Crear etiqueta",
    "Tag:CreateMessage": "Introduzca un nombre para la etiqueta",
    "Tag:CreateButton": "Crear",
    "Tag:EditTitle": "Editar etiqueta",
    "Tag:EditMessage": "Introduzca un nuevo nombre para la etiqueta",
    "Tag:EditButton": "Guardar",
    "Tag:ErrorTagAlreadyExist": "Esta etiqueta ya existe",
    "Tag:ErrorFieldTooLong": "Has alcanzado el límite de caracteres. Por favor, reescribe el nombre de la etiqueta para que se ajuste al número de caracteres permitidos y poder continuar.",
    "Tag:ErrorFieldRequired": "Este campo es obligatorio",
    "Tag:NoResult": "No se encontraron resultados de búsqueda.",
    "Tag:NoEntries": "No hay entradas",
    "Tag:ErrorOccured": "Se ha producido un error al procesar la solicitud. Por favor, inténtelo de nuevo más tarde.",
    "Tag:UpdatingTags": "Estamos en proceso de actualizar el sistema de gestión de etiquetas. Por favor, da tiempo a Reservacion de Salas Pro para completar esta actualización.",
    "Tag:UpdatingTagsError": "Se produjo un error durante la actualización, inténtalo de nuevo más tarde. Si el problema persiste, no dudes en contactarnos.",
    ConfigurationHelp: "Hemos trasladado todas las configuraciones a la ventana principal de Reservacion de Salas Pro para facilitar el acceso.",
    WelcomeMessage: "¡Bienvenido/a a Reservacion de Salas Pro! Comienza por darle un nombre a la pestaña y configurar las salas para comenzar",
    addEventTitle: "{userName} ha reservado {ressourceName}",
    addEventDesc: "Una reserva acaba de ser creada",
    updateEventTitle: "{userName} ha actualizado en {ressourceName}",
    updateEventDesc: "Una reserva ha sido actualizada",
    deleteEventTitle: "{userName} ha eliminado en {ressourceName}",
    deleteEventDesc: "Una reserva ha sido eliminada",
    participants: "Participantes",
    Notifications: "Notificaciones",
    AddUpdateNotif: "Reservas añadidas/actualizadas",
    DeleteNotif: "Reservas eliminadas",
    AddUpdateNotifDesc: "Enviar una notificación de Teams a todos los participantes de una nueva o actualizada reserva",
    DeleteNotifDesc: "Enviar una notificación de Teams a todos los participantes de una reserva eliminada",
    ShowWeekend: "Mostrar fin de semana",
    NewReservation: "Nueva reserva",
    EditReservation: "Editar reserva",
    AlreadyBooked: "La franja horaria seleccionada ya está reservada. Por favor, elija otra franja horaria o {event}",
    AnotherParkingPlace: "otro lugar de estacionamiento",
    AnotherRoom: "otra sala",
    UpgradeToPaidPlan: "Actualizar",
    ReccuringEventIsPremium: "Editar eventos recurrentes es una función Premium",
    TagsPremium: "Filtra por tema con etiquetas personalizadas con {App} Premium. Ver planes para acceder.",
    "PremiumFeature:AddToOutlook" : "Copie reservas importantes de Reservacion de Salas Pro a Outlook con un clic.",
    "UpgradeToPremium" : "Actualiza a Premium",
    "NoTagFound" : "No se encontró ninguna etiqueta",
    WarningRecurrenceMaxDate: "Las reservas recurrentes solo se pueden realizar hasta 1 año",
    "AdjustBooking": "Ajustar mi reserva",
    "ConflictBookingTitle": "Alerta de reserva en conflicto",
    "ConflictBookingTagLine": "Ajuste su reserva para evitar conflictos o guarde su evento excluyendo las fechas en conflicto.",
    "SeeConflicts": "Ver conflictos",
    "January" : "Enero",
    "February" : "Febrero",
    "March" : "Marzo",
    "April" : "Abril",
    "May" : "Mayo",
    "June" : "Junio",
    "July" : "Julio",
    "August" : "Agosto",
    "September" : "Septiembre",
    "October" : "Octubre",
    "November" : "Noviembre",
    "December" : "Diciembre",
    "AlreadyBookedAt" : "ya reservado a",
    "Suggested": "Sugerido : ",
  },
  pt: {
    FilesTitle: "Arquivo",
    Booking: "Reservacion de Salas Pro",
    noTag: "Comece por adicionar uma etiqueta para filtrar",
    Today: "Hoje",
    TimeLine: "Visualização do TimeLine",
    Month: "Mês",
    Week: "Semana",
    Day: "Dia",
    List: "Lista",
    AddEvent: "Novo",
    CreatedBy: "Criado por",
    UpdatedBy: "Actualizado por",
    AddTitle: "Adicione um título",
    AddDescription: "Acrescente uma descrição",
    SelectValue: "Selecione um valor",
    DeleteEvent: "Deletar",
    Cancel: "Fechar",
    Delete: "Deletar",
    HeaderDeleteModal: "Deletar evento",
    HeaderDeleteModalSerie: "Deletar série",
    HeaderDeleteModalOccurence: "Deletar ocorrência",
    ContentDeleteModal: "Você quer deletar este evento ?",
    Save: "Guardar",
    AllDay: "Dia todo",
    InfoUser: "Todos os usuários podem criar e editar",
    OnlyMe: "Só eu posso criar",
    OnlyOwners: "Apenas os proprietários podem criar e editar",
    Name: "Nome",
    DefaultDisplay: "Modo de exibição padrão",
    FirstDay: "Primeiro dia da semana",
    SecurityMode: "Modo de segurança",
    AddName: "Adicionar título",
    ErrorDate: "Datas inválidas",
    ErrorTitle: "Um título é obrigatório.",
    Close: "Fechar",
    Color: "Cor",
    Reset: "Redefinir cor",
    Resource: "Tipo de calendário",
    Standard: "Padrão",
    Resources: "Salas",
    ButtonContentResource: "Gerenciar salas",
    ButtonAddRessource: "Adicionar uma sala",
    InputResource: "Sala",
    ButtonDeleteRessource: "Eliminar",
    placeholderResources: "ex: Sala",
    versionPremiumText: "Reservacion de Salas Pro Premium: Ativo",
    mySubscription: "Minha assinatura",
    purchaseCalendarPro1: "Para ativar o seu ",
    purchaseCalendarPro2: " salas. Desbloquear agora Reservacion de Salas Pro Premium.",
    messageFormTask: " ",
    closePremium: "Fechar",
    WelcomePremium: "Bem-vindo ao Reservacion de Salas Pro Premium 🚀",
    HeaderMessageWelcome: "Reservacion de Salas Pro Premium",
    The: "O ",
    TabToRemove: " guia será removida permanentemente.",
    MessagePremiumCanceled: "Você não tem uma assinatura do Reservacion de Salas Pro Premium para usar mais de três salas.",
    ButtonInvitePremium: "Desbloquear Premium",
    ReactivateCalendarPremium: "Sua assinatura está desativada. Reative o Reservacion de Salas Pro Premium agora.",
    NotRepeat: "Não repete",
    Workweek: "De segunda-feira a sexta-feira",
    Weekly: "Semanalmente",
    Daily: "Diariamente",
    Monthly: "Por mês",
    Yearly: "Anual",
    Custom: "Personalizado...",
    UnLockRecurrence: "Atualize este calendário {calendarName} o Reservacion de Salas Pro Premium para habilitar a recorrência",
    RecurrenceInfo: "A duração do seu evento não pode exceder a da sua recorrência.",
    ReactivateCalendarPremiumRecurrence: "Reative o Reservacion de Salas Pro Premium para permitir a recorrência",
    TitleCalendarPro: "Reservacion de Salas Pro",
    Print: "Impressão",
    UnlockMoreOptions: "Atualize este calendário {calendarName} o Reservacion de Salas Pro Premium para habilitar mais opções",
    ReactivateCalendarPremiumMoreOptions: "Reative o Reservacion de Salas Pro Premium para habilitar mais opções",
    TitleCustomRecurrence: "Recorrência personalizada",
    DateStartRecurrence: "Start date",
    IntervalRecurrence: "Repita a cada",
    DaysOfRecurrence: "Repita",
    EndOfRecurrence: "Termina",
    EndsNeverRecurrence: "Nunca",
    DateEndsRecurrence: "O",
    CountOfRecurrence: "Após",
    OccurenceOfRecurrence: "Ocorrências",
    SerieOfRecurrence: "Séries",
    Year: "Ano",
    RRuleEvery: "Cada",
    RRuleMonth: "mês",
    RRuleMonths: "meses",
    RRuleYear: "ano",
    RRuleYears: "anos",
    RRuleOnThe: "on the",
    RRuleAnd: "and",
    RRuleNd: "nd",
    RRuleTh: "th",
    RRuleSt: "st",
    RRuleWeek: "semana",
    RRuleWeeks: "semanas",
    RRuleDay: "dia",
    RRuleDays: "dias",
    RRuleOn: "on",
    RRuleFor: "por",
    RRuleTimes: "vezes",
    RRuleSunday: "Domigo",
    RRuleMonday: "Segunda-feira",
    RRuleTuesday: "Terça-feira",
    RRuleWednesday: "Quarta-feira",
    RRuleThursday: "Quinta-feira",
    RRuleFriday: "Sexta-feira",
    RRuleSaturday: "Sábado",
    RRuleJanuary: "Janeiro",
    RRuleFebruary: "Fevereiro",
    RRuleMarch: "Março",
    RRuleApril: "Abril",
    RRuleMay: "Maio",
    RRuleJune: "Junho",
    RRuleJuly: "Julho",
    RRuleAugust: "Agosto",
    RRuleSeptember: "Setembro",
    RRuleOctober: "Outubro",
    RRuleNovember: "Novembro",
    RRuleDecember: "Dezembro",
    RRuleUntil: "até",
    First: "Primeiro",
    Second: "Segundo",
    Third: "Terceiro",
    Fourth: "Quarto",
    Last: "Mais recentes",
    Sunday: "Domigo",
    Monday: "Segunda-feira",
    Tuesday: "Terça-feira",
    Wednesday: "Quarta-feira",
    Thursday: "Quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "Sábado",
    OnThe: "On the",
    LabelResource: "Sala",
    LabelTitle: "Título",
    LabelStartDate: "Data de início",
    LabelEndDate: "Data final",
    LabelRecurrence: "Recorrência",
    LabelDescription: "Descrição:",
    LabelWarning: "Aviso",
    LabelError: "Erro",
    RemoveBookingTitle: "Você tem certeza de que deseja excluir esta aba?",
    RemoveBookingItem: "Todas as informações associadas a esta aba serão removidas permanentemente. Você não será capaz de recuperar o conteúdo.",
    RemoveCalendarFAQ: "Reservacion de Salas Pro FAQ página",
    RemoveCalendarCancellationText: "Política de cancelamento de Witivio aqui",
    RemoveCalendarContact: "Contate-Nos",
    MessageAlertTokenOutlookExpired: "A sincronização do Outlook expirou. {LinkToRenew}",
    HeaderSyncModal: "Sincronizar {CalendarName} no seu calendário pessoal do Outlook.",
    CheckSyncModal: "Habilitar sincronização",
    WarningBodySyncModal: "Apenas os eventos do mês atual e os eventos futuros serão sincronizados.",
    BodySyncModal:
      "Reservacion de Salas Pro copia todos os eventos do seu calendário pessoal do Outlook. A sincronização é automática. Para habilitar a sincronização, o Reservacion de Salas Pro precisa do seu consentimento para acessar o seu calendário pessoal.",
    ButtonAuthorizeSyncModal: "Eu autorizo o acesso ao meu calendário pessoal",
    Welcome: "Bem-vindo ao Reservacion de Salas Pro. Estamos felizes por você estar aqui!",
    WelcomeDetail:
      "Sem stress com Reservacion de Salas Pro para Microsoft Teams. Dentro de um canal, pode muito facilmente organizar equipas, gerir a disponibilidade, agendar trabalho e partilhar eventos.",
    Consent: "Let's go 🚀",
    OptionUnableInOutlook: "A opção de mais de um dia não é possível no Outlook",
    RenewMessage: "Renovar",
    RenewToken: "Clique aqui para renovar",
    OnlyOwnersInfo: "Esta opção está disponível apenas para usuários de Reservacion de Salas Pro premium",
    DiscardMessageTitle: "Alterações não guardadas",
    DiscardChanges: "Eliminar",
    ContinueEditing: "Continuar a editar",
    DiscardMessage: "Pretende eliminar as edições ou continuar a editar?",
    MessageOnlyMePrevent: "{createdBy} impediu-o de acrescentar/editar eventos neste calendário.",
    MessageOnlyOwnersPrevent: "Only {Owners} can add/edit events in this calendar",
    DeleteOccurrence: "Eliminar ocorrência",
    DeleteSeries: "Eliminar série",
    MessageDeleteRecurrence: "Pretende eliminar esta ",
    ButtonDeleteRecurrence: "Eliminar ",
    EditEvent: "Editar",
    EditOccurrence: "Editar ocorrência",
    EditSeries: "Editar série",
    Occurrence: "ocorrência",
    Series: "série",
    ViewOccurrenceOfSeries: "Está a ver uma ocorrência de uma série.",
    LoadData: "Carregando dados...",
    LoadCalendar: "Carregando sua agenda...",
    InfoEventId: "ID de evento",
    InfoEventSerieId: "ID da série",
    InfoEventCreatedBy: "Criado por",
    InfoEventUpdatedBy: "Atualizado por",
    InfoEventTimezone: "Fuso horário",
    infoEventHeader: "Informação do evento",
    CopyToClipboard: "Cópia de",
    Copied: "Copiado !",
    InfoEvent: "Informação",
    EstimatedTimeSync: "Vamos sincronizar {numberOfEvent} reservas, isso pode levar {EstimatedSyncTime} horas.",
    Upgrade: "Atualizar Reservacion de Salas Pro",
    UpgradeDetail: "Para atualizar, clique no botão de atualização no topo.",
    AnyEventInResources: "Quando se muda do modo padrão para o modo sala no Reservacion de Salas Pro, todos os eventos não ligados a uma sala não serão exibidos no modo sala.",
    NoResource: "Não há sala",
    EmptyFillError: "Este campo não pode estar vazio",
    GoDesktopBuyPremium: "Deve utilizar a versão informática da aplicação para activar o modo premium",
    Ok: "Ok",
    NoRightToUpdateConfig: "Não está autorizado a alterar a configuração",
    UpdatingApp: "Actualização da aplicação",
    LinkTitle: "Ligação",
    AddLink: "Acrescentar um link",
    ErrorMessageUrl: "Por favor introduza uma url válida do site, ex: https://www.domain.com ou domain.com",
    AddressTitle: "Endereço",
    AddAddress: "Digite um endereço",
    TagsTitle: "Etiquetas",
    LabelSelectionTimeZone: "Fuso horário",
    EditTextConfirm: "Guardar",
    EditTextEdit: "Editar",
    EditTextRemove: "Eliminar",
    EditTextLink: "Url",
    Add: "Acrescentar",
    Forward: "Avançado",
    AlertTagsAndMore: "Para utilizar funcionalidades avançadas vá para Premium ",
    Copy: "Cópia",
    About: "Acerca",
    UniqueId: "Identificador único",
    WarningUpdateRecurrenceMessage: "Tem a certeza de salvar a série ? Todas as ocorrências personalizadas serão eliminadas",
    Version: "Versão",
    BookingRoomDeleted: "Este gestor de reservas de quartos foi removido",
    Settings: "Parâmetros",
    Permissions: "Permissões",
    RoomsManagement: "Gestão de salas",
    "Suggest Feature": "Sugerir funcionalidade",
    "Tag:Title": "Gestão de etiquetas",
    "Tag:Create": "Criar nova etiqueta",
    "Tag:Search": "Digite aqui para pesquisar uma etiqueta",
    "Tag:TableName": "Nome",
    "Tag:DeleteTitle": "Eliminar etiqueta",
    "Tag:DeleteMessageFirstPart": "A etiqueta «",
"Tag:DeleteMessageSecondPart": "» será eliminada definitivamente e eliminada de todos os eventos.",
    "Tag:DeleteButton": "Eliminar",
    "Tag:CreateTitle": "Criar etiqueta",
    "Tag:CreateMessage": "Digite um nome para a etiqueta",
    "Tag:CreateButton": "Criar",
    "Tag:EditTitle": "Editar etiqueta",
    "Tag:EditMessage": "Digite um novo nome para a etiqueta",
    "Tag:EditButton": "Guardar",
    "Tag:ErrorTagAlreadyExist": "Esta etiqueta já existe",
    "Tag:ErrorFieldTooLong": "Você atingiu o limite de caracteres. Por favor, reescreva o nome da tag para se adequar à contagem de caracteres permitidos para prosseguir.",
    "Tag:ErrorFieldRequired": "Este campo é obrigatório",
    "Tag:NoResult": "Nenhum resultado de pesquisa foi encontrado.",
    "Tag:NoEntries": "Não há entradas",
    "Tag:ErrorOccured": "Ocorreu um erro ao processar o pedido. Por favor, tente novamente mais tarde.",
    "Tag:UpdatingTags": "Estamos atualmente no processo de atualização do sistema de gestão de etiquetas. Por favor, dê algum tempo ao Reservacion de Salas Pro para completar esta atualização.",
    "Tag:UpdatingTagsError": "Ocorreu um problema durante a atualização, tente novamente mais tarde. Se o problema persistir, não hesite em contactar-nos.",
    ConfigurationHelp: "Mudámos todas as definições para a janela principal do Reservacion de Salas Pro para facilitar o acesso.",
    WelcomeMessage: "Bem-vindo ao Reservacion de Salas Pro! Comece por dar um nome à aba e configurar as salas para começar",
    addEventTitle: "{userName} reservou {ressourceName}",
    addEventDesc: "Uma reserva acabou de ser criada",
    updateEventTitle: "{userName} atualizou em {ressourceName}",
    updateEventDesc: "Uma reserva foi atualizada",
    deleteEventTitle: "{userName} excluiu em {ressourceName}",
    deleteEventDesc: "Uma reserva foi excluída",
    participants: "Participantes",
    Notifications: "Notificações",
    AddUpdateNotif: "Reservas adicionadas/atualizadas",
    DeleteNotif: "Reservas excluídas",
    AddUpdateNotifDesc: "Enviar uma notificação do Teams para todos os participantes de uma nova ou atualizada reserva",
    DeleteNotifDesc: "Enviar uma notificação do Teams para todos os participantes de uma reserva excluída",
    ShowWeekend: "Mostrar fim de semana",
    NewReservation: "Nova reserva",
    EditReservation: "Editar reserva",
    AlreadyBooked: "O intervalo de tempo selecionado já está reservado. Por favor, escolha outro intervalo de tempo ou {event}",
    AnotherParkingPlace: "outro lugar de estacionamento",
    AnotherRoom: "outra sala",
    UpgradeToPaidPlan: "Atualizar",
    ReccuringEventIsPremium: "Editar eventos recorrentes é um recurso Premium",
    TagsPremium: "Filtre por tópico com etiquetas personalizadas usando o {App} Premium. Veja os planos para acesso.",
    "PremiumFeature:AddToOutlook" : "Copie reservas importantes do Reservacion de Salas Pro para o Outlook com um clique.",
    "UpgradeToPremium" : "Atualize para Premium",
    "NoTagFound" : "Nenhuma tag encontrada",
    WarningRecurrenceMaxDate: "As reservas recorrentes só podem ser feitas por até 1 ano",
    "AdjustBooking": "Ajustar minha reserva",
    "ConflictBookingTitle": "Alerta de Reserva em Conflito",
    "ConflictBookingTagLine": "Ajuste sua reserva para evitar conflitos ou salve seu evento excluindo as datas conflitantes.",
    "SeeConflicts": "Ver conflitos",
    "January" : "Janeiro",
    "February" : "Fevereiro",
    "March" : "Março",
    "April" : "Abril",
    "May" : "Maio",
    "June" : "Junho",
    "July" : "Julho",
    "August" : "Agosto",
    "September" : "Setembro",
    "October" : "Outubro",
    "November" : "Novembro",
    "December" : "Dezembro",
    "AlreadyBookedAt" : "já reservado em",
    "Suggested": "Sugerido : ",
  },
};
