import { AxiosConfig } from "front";
export const getCalendar = (calendarId, ignoreException = false) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/calendar/${calendarId}` + (ignoreException ? "?ignoreException=true" : ""));
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get Calendar");
    }
});
export const createCalendar = (tenantId, calendar) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(calendar);
        const response = await AxiosConfig.instance.post(`/calendar/${tenantId}`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't create calendar");
    }
});
export const updateCalendar = (calendarId, calendar) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(calendar);
        const response = await AxiosConfig.instance.patch(`/calendar/${calendarId}`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update calendar");
    }
});
export const deleteCalendar = async (calendarId) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.delete(`/calendar/${calendarId}`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete calendar");
    }
});
export const checkCalendar = async (calendarId) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.get(`/calendar/${calendarId}/check`);
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't check calendar");
    }
});
export const getCalendarTags = async (calendarId) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.get(`/calendar/${calendarId}/tags`);
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get calendar tags");
    }
});
export const GetCalendarsWithEventsByTenantIdAndUserId = async (tenantId, userId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/calendar/${tenantId}/${userId}/all`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get calendars with events by tenantId and userId");
    }
});
export const copyEventFromASpecificCalendar = (AFInfo) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(AFInfo);
        const response = await AxiosConfig.instance.post(`/calendar/copy`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't copy calendar");
    }
});
export const CheckCopyStatus = (url) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(url);
        const response = await AxiosConfig.instance.post(`/calendar/copy/status`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get calendar copy status");
    }
});
