import { useTranslate } from "front";
import { translations } from "../translations";
export const durationText = (duration) => {
    const t = useTranslate(translations);
    const minutes = parseInt(duration);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    let text = "";
    if (hours > 0) {
        text += hours === 1 ? `1 ${t("hour")}` : `${hours} ${t("hours")}`;
    }
    if (remainingMinutes > 0) {
        text += text.length > 0 ? " " : "";
        text += `${remainingMinutes} ${t("minutes")}`;
    }
    return text.trim();
};
