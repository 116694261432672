import { AxiosConfig } from "front";
export const getMeetingScheduler = (calendarId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/calendar/${calendarId}/meetingscheduler/`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get meeting scheduler");
    }
});
export const createMeetingScheduler = (meetingScheduler) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(meetingScheduler);
        const response = await AxiosConfig.instance.post(`/calendar/${meetingScheduler.calendarId}/meetingscheduler/`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't create meeting scheduler");
    }
});
export const updateMeetingScheduler = (meetingScheduler) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(meetingScheduler);
        const response = await AxiosConfig.instance.patch(`/calendar/${meetingScheduler.calendarId}/meetingscheduler/`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update meeting scheduler");
    }
});
