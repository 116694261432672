import { constInputsTag } from "./InputsTag";
export const initialMeetingScheduler = {
    // entrer les infos de base
    calendarId: "",
    title: "",
    firstDay: 1,
    businessDays: { startHour: 8, endHour: 18, days: [0, 1, 2, 3, 4, 5, 6] },
    timezone: "",
    localLanguage: "",
    companyName: "",
    eventColor: "#6264A7",
    duration: 30,
    hosts: [],
    inputsTag: constInputsTag
};
