import { useState, useEffect } from "react";
import { GraphService } from "../services/GraphService/GraphService";
import * as microsoftTeams from "@microsoft/teams-js";
import { useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
//Used to set up the route to général files tab.
export const useSharepoint = () => {
    const { teamSiteDomain, teamSitePath, userName } = useMsTeamsSelector("teamSiteDomain", "teamSitePath", "channelId", "tenantId", "userName");
    const [siteId, setSiteId] = useState();
    const [urlAddToSharepoint, setUrlAddToSharepoint] = useState();
    const getChannelName = async () => {
        const config = await microsoftTeams.app.getContext();
        const { channel } = config;
        const relativeUrls = channel?.relativeUrl.split("/");
        return relativeUrls[relativeUrls.length - 1] ?? channel?.displayName;
    };
    const formatFolderName = (str) => str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    const getDriveIdAsync = async (site) => {
        try {
            const siteID = site ?? siteId;
            const urlDriveIds = "sites/" + siteID + "/drives";
            const driveId = await GraphService.getDriveIdSharepoint(urlDriveIds);
            let folderIDValues = [];
            for (let i = 0; i < driveId.value.length; i++) {
                const folderIDurl = `/drives/${driveId.value[i].id}/root/children`;
                const folderIds = await GraphService.getFolderIdSharepoint(folderIDurl);
                folderIDValues = [...folderIDValues, ...folderIds.value];
            }
            const folderName = await getChannelName();
            if (!folderName)
                return;
            const generalFolder = folderIDValues.find((v) => {
                return formatFolderName(v.name) === formatFolderName(folderName);
            });
            const url = `/sites/${siteID}/drives/${generalFolder.parentReference.driveId}/items/${generalFolder.id}:/`;
            setUrlAddToSharepoint(url);
            if (site)
                return url;
        }
        catch (err) {
            // const errorMessage = catchError(err);
            // dispatchCtx(setErrorMessage(t(errorMessage)));
            console.error("Error generating url sharepoint", err);
        }
    };
    const getSiteIdAsync = async (render) => {
        try {
            const res = await GraphService.getSitesId(teamSiteDomain, teamSitePath);
            if (!render)
                return res.id;
            setSiteId(res.id);
        }
        catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        if (!siteId)
            return;
        getDriveIdAsync();
    }, [siteId]);
    useEffect(() => {
        getSiteIdAsync(true);
    }, [teamSiteDomain, teamSitePath]);
    const saveFiles = async (files) => {
        let urlSp = urlAddToSharepoint;
        if (!siteId) {
            const sites = await getSiteIdAsync(false);
            urlSp = await getDriveIdAsync(sites);
        }
        const sharepointFiles = [];
        try {
            for (let file of files) {
                const fileNameSplit = file.name.split(".");
                fileNameSplit[fileNameSplit.length - 2] += "(" + userName + ")";
                const fileUrl = `${urlSp}${fileNameSplit.join(".").replaceAll(" ", "-")}:/content`;
                const sharepointFile = await GraphService.postImageToSharepoint(fileUrl, file);
                sharepointFiles.push(sharepointFile);
            }
        }
        catch (err) {
            console.error(err);
        }
        return sharepointFiles;
    };
    return { saveFiles, getDriveIdAsync };
};
