import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FullCalendar from "@fullcalendar/react";
import "@fullcalendar/react/dist/vdom";
import { CalendarEmpty24Regular, CalendarSync24Regular } from "@fluentui/react-icons";
import { Alert, Button, Dialog, EditIcon, Flex, MenuButton, Ref, TrashCanIcon, Text, Loader, MegaphoneIcon, Tooltip, OutlookColorIcon } from "@fluentui/react-northstar";
import { useLogic } from "./Calendar.logic";
import { IEventSelection } from "../../interfaces/Enums";
import EventPopup from "../../components/event/EventPopup";
import { createEventWithID, getFullcalendarDisplayMode } from "./Calendar.utils";
import { Modal, Newsbanner, PersistentAlert, openModal, renderCopyAlert, useConfigurationSelector, useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { useNavBarSelector } from "../../redux/reducers/navbarReducer";
import { CalendarNavbar } from "./navBar/NavbarComponent";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import ReactHtmlParser from "react-html-parser";
import { locales } from "../../utils/locales";
import { useDispatch, useSelector } from "react-redux";
export const CalendarComponent = (props) => {
    const { showView } = props;
    const { isPremium } = usePremiumSelector("isPremium");
    const { calendar, resources } = useCalendarCache();
    const logic = useLogic(props);
    const { locale, isOnMobile } = useMsTeamsSelector("locale", "isOnMobile");
    const { displayMode } = useNavBarSelector("displayMode");
    const t = useTranslate(translations);
    const { data } = useConfigurationSelector("data");
    const dispatch = useDispatch();
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    /**
     * Render popup when clicking on a fullcalendar event
     * @param fullcalendarEvent
     */
    const renderEventPopup = (fullcalendarEvent) => {
        const isSerieEvent = !!fullcalendarEvent.event.groupId && logic.events?.find((e) => e.id == fullcalendarEvent.event.id)?.rrule;
        const editOccurrenceFromSeries = !!fullcalendarEvent.event.groupId;
        const isRecurrent = logic.events?.find((e) => e.id == fullcalendarEvent.event.id)?.rrule;
        const editButton = (_jsx(Flex, { children: isPremium || !isRecurrent ? (_jsx(Button, { fluid: true, icon: _jsx(EditIcon, { outline: true }), disabled: !logic.userPerm.perm.update, content: t("EditEvent"), onClick: isSerieEvent ? () => { } : logic.onEditCalendarEvent(fullcalendarEvent, IEventSelection.Occurrence) })) : (_jsx(Tooltip, { content: t("ReccuringEventIsPremium"), trigger: _jsx(Button, { fluid: true, icon: _jsx(EditIcon, { outline: true }), disabledFocusable: true, content: t("EditEvent") }) })) }));
        const deleteButton = (_jsx(Button, { fluid: true, tinted: true, disabled: !logic.userPerm.perm.delete, content: t("Delete"), icon: _jsx(TrashCanIcon, { outline: true, size: "small" }), onClick: isSerieEvent ? () => { } : !editOccurrenceFromSeries ? logic.onDeleteEvent(fullcalendarEvent, IEventSelection.Occurrence) : logic.onDeleteEvent(fullcalendarEvent, IEventSelection.Serie) }));
        const renderButtonForShareEvent = () => (_jsx(Flex, { children: !isPremium ?
                _jsx(Tooltip, { mouseLeaveDelay: 50, content: _jsxs(Flex, { gap: "gap.smaller", hAlign: "center", children: [_jsx(Text, { content: t("PremiumFeature:AddToOutlook") }), _jsx(Text, { styles: { textDecoration: "underline", cursor: "pointer" }, onClick: () => { dispatch(openModal(Modal.UPGRADE)); }, weight: "bold", content: t("UpgradeToPremium") })] }), trigger: _jsx(Button, { fluid: true, secondary: true, icon: _jsx(OutlookColorIcon, { size: "small" }), content: t("ShareToOutlook"), disabledFocusable: true, onClick: (e) => {
                            e.stopPropagation();
                            dispatch(openModal(Modal.UPGRADE));
                        } }) })
                :
                    _jsx(Button, { fluid: true, secondary: true, icon: _jsx(OutlookColorIcon, { size: "small" }), content: t("ShareToOutlook"), onClick: () => logic.onShareEvent(fullcalendarEvent) }) }));
        return (_jsx(EventPopup, { viewMode: displayMode, eventInfo: fullcalendarEvent, calendarInfo: logic.displayedCalendars, children: _jsxs(Flex, { column: true, gap: "gap.small", children: [renderButtonForShareEvent(), fullcalendarEvent.event.extendedProps.readOnly ? null : !isSerieEvent ? (editButton) : (_jsx(MenuButton, { trigger: editButton, menu: isPremium || !isRecurrent
                            ? [
                                {
                                    key: IEventSelection.Occurrence,
                                    content: t("EditOccurrence"),
                                    onClick: logic.onEditCalendarEvent(fullcalendarEvent, IEventSelection.Occurrence),
                                    icon: _jsx(CalendarEmpty24Regular, {}),
                                },
                                {
                                    key: IEventSelection.Serie,
                                    content: t("EditSeries"),
                                    onClick: logic.onEditCalendarEvent(fullcalendarEvent, IEventSelection.Serie),
                                    icon: _jsx(CalendarSync24Regular, {}),
                                },
                            ]
                            : [] })), fullcalendarEvent.event.extendedProps.readOnly ? null : !isSerieEvent ? (deleteButton) : (_jsx(MenuButton, { trigger: deleteButton, menu: [
                            {
                                key: IEventSelection.Occurrence,
                                content: t("HeaderDeleteModalOccurence"),
                                onClick: logic.onDeleteEvent(fullcalendarEvent, IEventSelection.Occurrence),
                                icon: _jsx(CalendarEmpty24Regular, {}),
                            },
                            {
                                key: IEventSelection.Serie,
                                content: t("HeaderDeleteModalSerie"),
                                onClick: logic.onDeleteEvent(fullcalendarEvent, IEventSelection.Serie),
                                icon: _jsx(CalendarSync24Regular, {}),
                            },
                        ] }))] }) }));
    };
    const renderNews = () => (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(MegaphoneIcon, { outline: true }), _jsx(Text, { content: ReactHtmlParser(translations.get("NewsRedirect")) })] }) }) }));
    /**
     * Render calendar navbar
     */
    const renderNavbar = () => (_jsx(CalendarNavbar, { events: logic.events ?? [], calendarRef: logic.fullcalendarRef, refreshEvents: logic.refetchEvents, filterEvents: logic.filterEvents, refreshPublicHolidays: logic.refreshPublicHolidays, currentCalendarTitle: logic.currentCalendarTitle, onCreateNewEvent: logic.onCreateNewEvent, getExistingEvent: logic.getExistingEvent, onHeightChange: logic.updateNavbarHeight, clearEvents: logic.clearEvents, userCanEditCalendar: logic.userCanEditCalendar, displayedCalendars: logic.displayedCalendars, setDisplayedCalendars: logic.setDisplayedCalendars, isLoading: !calendar || !logic.config || !logic.userPerm }));
    /**
     * Render delete dialog
     */
    const renderDeleteDialog = () => {
        if (!logic.deleteState.showDeleteDialog || !logic.deleteState.fullcalendarEventToDelete)
            return null;
        let deleteHeader = !!logic.deleteState.fullcalendarEventToDelete.event.groupId
            ? logic.deleteState.deleteChoice === IEventSelection.Occurrence
                ? "HeaderDeleteModalOccurence"
                : "HeaderDeleteModalSerie"
            : "HeaderDeleteModal";
        return (_jsx(Dialog, { closeOnOutsideClick: false, header: t(deleteHeader), content: t("ContentDeleteModal"), open: logic.deleteState.showDeleteDialog, confirmButton: {
                content: t("Delete"),
                disabled: logic.deleteState.isDeletingEvent,
                loading: logic.deleteState.isDeletingEvent,
            }, cancelButton: {
                content: t("Cancel"),
                disabled: logic.deleteState.isDeletingEvent,
            }, onCancel: logic.closeDeleteDialog, onConfirm: logic.onDeleteEvent(logic.deleteState.fullcalendarEventToDelete, logic.deleteState.deleteChoice) }));
    };
    /**
     * Render premium alert when in resource mode and user has more than 3 resources
     */
    const renderGoPremiumAlert = () => {
        const show = !isPremium && calendar?.isResourceMode && (resources?.length ?? 0) > 3;
        if (!show)
            return null;
        return (_jsx(Ref, { innerRef: logic.premiumBannerRef, children: _jsx(Alert, { warning: true, styles: {
                    cursor: "pointer",
                    textDecoration: "underline",
                    margin: "0 10px 10px 10px",
                }, content: _jsx(Text, { align: "start", content: t("purchaseCalendarPro1") + resources?.length + t("purchaseCalendarPro2") + " 🚀" }), onClick: logic.showPremiumDialog }) }));
    };
    const renderPersistantAlert = () => {
        return _jsx(PersistentAlert, {});
    };
    const formatCalendarTitle = (view) => {
        const date = view.currentStart;
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${month} ${year}`;
    };
    const renderCalendar = () => {
        if (!calendar || !logic.config) {
            return (_jsx(Flex, { column: true, fill: true, styles: { height: "400px", justifyContent: "center", alignItems: "center", alignContent: "center" }, children: _jsx(Loader, {}) }));
        }
        return (_jsx(Flex, { column: true, fill: true, id: "fullcalendar", styles: {
                maxHeight: logic.config.height,
                overflowX: "scroll",
                overflowY: "auto",
            }, children: _jsx(FullCalendar, { ref: logic.fullcalendarRef, schedulerLicenseKey: data.apiCalendar ?? "", resourceAreaHeaderContent: t("Resources"), locales: locales, locale: locale, timeZone: logic.config.timeZone, plugins: logic.config.plugins, initialView: logic.displayMode ?? getFullcalendarDisplayMode(undefined, undefined), views: {
                    dayGridMonth: {
                        titleFormat: { year: 'numeric', month: 'long' }
                    },
                    timeGridWeek: {
                        titleFormat: { year: 'numeric', month: 'long' }
                    },
                    timeGridDay: {
                        titleFormat: { year: 'numeric', month: 'long' }
                    },
                    resourceTimelineWeek: {
                        titleFormat: { year: 'numeric', month: 'long' }
                    },
                }, initialDate: logic.config.initialDate, firstDay: logic.calendar?.firstDay ?? 0, dayHeaderFormat: logic.displayMode === "dayGridMonth" ? undefined : { weekday: logic.displayMode === "timeGridDay" ? 'long' : 'short', day: 'numeric' }, slotDuration: logic.config.slotDuration, slotMinTime: "00:00:00", slotMaxTime: "24:00:00", resourceAreaWidth: logic.config.resourceAreaWidth ?? 0, slotMinWidth: logic.config.slotMinWidth ?? 100, slotLabelFormat: logic.config?.slotLabelFormat ?? undefined, defaultAllDayEventDuration: { days: 1 }, slotEventOverlap: true, allDaySlot: true, headerToolbar: false, selectable: true, contentHeight: logic.config?.height ?? 0, nowIndicator: true, editable: logic.userCanEditCalendar, weekends: logic.config?.showWeekend ?? false, resourceOrder: logic.config?.resourceOrder ?? "", displayEventEnd: true, windowResize: logic.onResizeCalendar, eventTimeFormat: { hour: "numeric", minute: "2-digit" }, eventContent: renderEventPopup, select: logic.onSelectEvent, datesSet: logic.setCalendarTitle, eventDrop: async (data) => {
                    await showView(createEventWithID(data, logic.getExistingEvent(data.event.id), logic.config?.timeZone), true, logic.events, logic.queryKey);
                }, eventResize: async (data) => await showView(createEventWithID(data, logic.getExistingEvent(data.event.id), logic.config?.timeZone), true, logic.events, logic.queryKey), events: logic.config?.events || [], resources: logic.config?.resources ?? new Array(), businessHours: logic.config?.businessHours || {}, weekNumbers: logic.config?.showWeekNumbers ?? false }) }));
    };
    const renderBannerNews = () => _jsx(Newsbanner, { AppType: "calendar" });
    return (_jsxs(Flex, { fill: true, column: true, className: "fill-absolute", children: [calendar?.isCopying && renderCopyAlert(translations.get("CopyAlert")), renderNavbar(), renderBannerNews(), renderGoPremiumAlert(), renderPersistantAlert(), renderCalendar(), renderDeleteDialog()] }));
};
