import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import "./ViewManager.styles.scss";
import { AboutDialog, useConfigurationSelector, usePremiumSelector } from "front";
import { ActiveView, useLogic } from "./ViewManager.logic";
import { CalendarComponent } from "../../components/calendar/CalendarComponent";
import { EventComponent } from "../../components/event/EventComponent";
export const ViewManager = () => {
    const logic = useLogic();
    const { isPremium } = usePremiumSelector("isPremium");
    const { data: config } = useConfigurationSelector("data");
    /*useEffect(()=>{
        const track = async () => {
            await trackUser({
                appName: "Calendar Pro",
                isUserPremium: isPremium,
                apiBaseUrl: config.trackingBaseUrl,
                apiSecretKey: config.trackingSecretKey
            });
        };
        track();
    },[isPremium, config.trackingBaseUrl, config.trackingSecretKey])*/
    const renderCalendar = useMemo(() => {
        if (logic.view.activeView != ActiveView.Calendar)
            return _jsx(_Fragment, {});
        return (_jsx(CalendarComponent, { showView: async (event, isAlreadyUpdated, events, queryKey) => logic.showView(ActiveView.Event, event, isAlreadyUpdated, events, queryKey) }));
    }, [logic.view, logic.showView]);
    const renderEvent = useMemo(() => {
        if (logic.view.activeView != ActiveView.Event)
            return _jsx(_Fragment, {});
        return (_jsx(EventComponent, { onClose: () => logic.showView(ActiveView.Calendar), event: logic.event }));
    }, [logic.view, logic.event, logic.showView]);
    return (_jsxs(_Fragment, { children: [renderCalendar, renderEvent, _jsx(AboutDialog, {})] }));
};
