import { Modal, setDraftInfo, setModalWithData, useGraph, useSharepoint, useTranslate } from "front";
import { useDispatch } from "react-redux";
import { translations } from "../../translations";
import { useMemo } from "react";
const scopes = "Sites.ReadWrite.All";
export const useFilesLogic = (props) => {
    const graph = useGraph({ forceAsk: undefined });
    const { saveFiles, getDriveIdAsync } = useSharepoint();
    const t = useTranslate(translations);
    const dispatch = useDispatch();
    const updateFile = async (e) => {
        if (e.target.files.length == 0)
            return;
        //on rappel le getDriveIdAsync ici car on vient de mettre a jour le graphtoken avec els droit necessaire pour lire le sharepoint.
        await getDriveIdAsync();
        const files = await saveFiles(e.target.files);
        const filesEvent = files.map(f => {
            return {
                name: f.name,
                extensions: f.file.mimeType,
                url: f.webUrl,
            };
        });
        if (props.event.files) {
            const event = { ...props.event, files: [...props.event.files, ...filesEvent] };
            props.updateEvent(event);
            return;
        }
        const event = { ...props.event, files: filesEvent };
        props.updateEvent(event);
    };
    const addFiles = async () => {
        const isAllow = await graph.isGraphAllow(scopes);
        if (!isAllow) {
            dispatch(setModalWithData({ isOpen: Modal.NEED_CONSENT, data: { actionDialog: consentRequirementConfirm } }));
            return;
        }
        graph.askScopeGraph(scopes);
        document.getElementById("file-selector-input").click();
    };
    const deleteFile = async (e, name) => {
        e.stopPropagation();
        const filesEvent = props.event.files.filter(f => f.name != name);
        const event = { ...props.event, files: filesEvent };
        props.updateEvent(event);
    };
    const consentRequirementConfirm = () => {
        dispatch(setDraftInfo(props.event));
        graph.askScopeGraph(scopes);
    };
    const getContentFileMemo = useMemo(() => {
        let content = t("FilesTitle");
        if (props.event?.files?.length)
            content += " (" + props.event?.files.length + ")";
        return content;
    }, [props.event]);
    return {
        addFiles,
        consentRequirementConfirm,
        updateFile,
        deleteFile,
        getContentFileMemo
    };
};
