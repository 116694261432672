import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import MarkdownView from "react-showdown";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import { deleteCalendar } from "../../apis/apiCalendar";
export const useLogic = () => {
    const { calendar } = useCalendarCache();
    useEffect(() => {
        if (!calendar)
            return;
        microsoftTeams.pages.config.registerOnRemoveHandler(async (evt) => {
            if (!!calendar.id) {
                await deleteCalendar(calendar.id);
                evt.notifySuccess();
            }
            else {
                evt.notifyFailure();
            }
        });
        microsoftTeams.pages.config.setValidityState(true);
        microsoftTeams.app.notifySuccess();
    }, [calendar]);
    const getMarkdown = (text) => {
        const lines = text.split(/\r?\n|\r|\n/g).map(line => "<p>" + line + "</p>").join("");
        return (_jsx(MarkdownView, { markdown: lines }));
    };
    return {
        getMarkdown
    };
};
