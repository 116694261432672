import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, ChevronStartIcon, ExclamationTriangleIcon, Flex, FlexItem, Form, Header, Loader, Menu, PresenceAvailableIcon, SaveIcon, Text, UndoIcon } from "@fluentui/react-northstar";
import { useMeetingSchedulerLogic } from "./MeetingScheduler.logic";
import { Modal, useMsTeamsSelector, useTranslate, usePermissionsSelector, DiscardDialog } from "front";
import { translations } from "../../translations";
import { useState } from "react";
import { DetailsPanel } from "../../components/meetingScheduler/detailsPanel/DetailsPanel";
import { AvailabilityPanel } from "../../components/meetingScheduler/availabilityPanel/AvailabilityPanel";
import { OptionsPanel } from "../../components/meetingScheduler/optionsPanel/OptionsPanel";
import { LinksPanel } from "../../components/meetingScheduler/linksPanel/LinksPanel";
export const MeetingScheduler = () => {
    const logic = useMeetingSchedulerLogic();
    const t = useTranslate(translations);
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isOnMobile, locale } = useMsTeamsSelector("isOnMobile", "locale");
    const [isReturnToApp, setIsReturnToApp] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    if (logic.state.isLoading) {
        return (_jsx(Flex, { column: true, styles: {
                display: "flex",
                height: "100vh",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
            }, children: _jsx(Loader, { "data-testid": "meeting-scheduler-loader" }) }));
    }
    return (_jsx(_Fragment, { children: _jsx(Flex, { column: true, fill: true, className: "fill-absolute", "data-testid": "meeting-scheduler-page", children: _jsxs(Form, { styles: { justifyContent: "flex-start" }, onSubmit: logic.handleSubmit(logic.saveSettings), children: [_jsx(FlexItem, { children: _jsxs(Flex, { column: true, children: [_jsxs(Flex, { styles: { alignItems: "center" }, children: [_jsx(Flex, { styles: {
                                                padding: isOnMobile ? "15px" : "10px"
                                            }, children: _jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, type: "button", onClick: !logic.canSave() ? logic.returnToApp : () => { setIsReturnToApp(true), logic.toggleDiscardDialog(); } }) }), _jsx(Header, { styles: { width: isOnMobile ? "100%" : "40%" }, as: "h3", content: t("MeetingScheduler") }), _jsxs(Flex, { gap: "gap.medium", styles: { justifyContent: "end", alignItems: "center", width: "100%", marginRight: isOnMobile ? "15px" : "25px" }, children: [_jsx(FlexItem, { children: _jsx(Button, { primary: true, type: "submit", size: isOnMobile ? "small" : "medium", content: isOnMobile ? _jsx(SaveIcon, {}) : t("Save"), disabled: logic.state.isSaving || !logic.canSave() || !logic.isValid || !userPerm.perm.allowEditMeetingScheduler || logic.errorForm(), loading: logic.state.isSaving }) }), !isOnMobile && _jsx(FlexItem, { children: _jsx(Button, { secondary: true, type: "button", size: isOnMobile ? "small" : "medium", content: isOnMobile ? _jsx(UndoIcon, {}) : t("Cancel"), onClick: () => { setIsReturnToApp(false); logic.toggleDiscardDialog(); }, disabled: !logic.canSave || !userPerm.perm.allowEditMeetingScheduler }) })] })] }), !userPerm.perm.allowEditMeetingScheduler &&
                                    _jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true }), _jsx(Text, { content: t("NotAllowed") })] }) }) }), logic.isOpen === Modal.PERMISSIONS_CANCEL_UNSAVE_CHANGE &&
                                    _jsx(DiscardDialog, { handleDiscardConfirmed: logic.handleDiscardConfirmed, returnToApp: logic.returnToApp, isReturnToApp: isReturnToApp, handlePageChange: undefined, isPreviousPage: undefined, isNextPage: undefined, currentPage: undefined, searchOnChange: undefined, isSearch: undefined }), logic.state.isSaved && (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, success: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(PresenceAvailableIcon, { outline: true }), _jsx(Text, { content: t("MeetingSchedulerSaveAlert") })] }) }) })), _jsx(Menu, { styles: {
                                        padding: isOnMobile ? "15px" : "10px"
                                    }, defaultActiveIndex: 0, activeIndex: logic.menuItems.findIndex(item => item.index === activeTab), items: logic.menuItems, underlined: true, primary: true, onItemClick: (_, data) => { setActiveTab(data?.index); } })] }) }), _jsx(FlexItem, { children: _jsxs(Flex, { styles: { overflowY: "scroll" }, children: [activeTab === 0 && _jsx(DetailsPanel, { isValid: logic.isValid, meetingScheduler: logic.meetingScheduler, keysRegistered: logic.meetingSchedulerFilter, watchData: logic.watchData, register: logic.register, setValue: logic.setValue, control: logic.control }), activeTab === 1 && _jsx(AvailabilityPanel, { meetingScheduler: logic.meetingScheduler, keysRegistered: logic.meetingSchedulerFilter, watchData: logic.watchData, register: logic.register, setValue: logic.setValue, control: logic.control }), activeTab === 2 && _jsx(OptionsPanel, { meetingScheduler: logic.meetingScheduler, keysRegistered: logic.meetingSchedulerFilter, watchData: logic.watchData, register: logic.register, setValue: logic.setValue, control: logic.control }), activeTab === 3 && _jsx(LinksPanel, {})] }) })] }) }) }));
};
