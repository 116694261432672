import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./RemoveCalendar.styles.scss";
import { useLogic } from "./RemoveCalendar.logic";
import { useMsTeamsSelector, useTranslate } from "front";
import { Flex, Text } from "@fluentui/react-northstar";
import { translations } from "../../translations";
export const RemoveCalendar = () => {
    const t = useTranslate(translations);
    const logic = useLogic();
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    return (_jsxs(Flex, { fill: true, column: true, styles: { padding: isOnMobile ? "15px" : "0" }, children: [_jsx(Text, { weight: "bold", content: t("RemoveCalendarTitle") }), _jsx(Flex, { padding: "padding.medium", children: logic.getMarkdown(t("RemoveCalendarItem")) })] }));
};
