import * as microsoftTeams from "@microsoft/teams-js";
export const useParticipantLogic = (props) => {
    const toggleAddMemberDialog = async () => {
        try {
            const members = (await microsoftTeams.people.selectPeople({
                singleSelect: false,
                title: "",
                setSelected: props.members.map(m => m.id),
                openOrgWideSearchInChatOrChannel: true
            }));
            if (members?.length > 0) {
                const participants = members.map(m => ({
                    id: m.objectId,
                    displayName: m.displayName,
                    userPrincipalName: m.email,
                }));
                props.addMembers(participants);
            }
        }
        catch (e) {
            console.error("no particpants added");
        }
    };
    return {
        toggleAddMemberDialog
    };
};
