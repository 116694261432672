import "../style.scss";
export const highlightChildrenClass = (addHighlight, componentClass, classToHighlight) => {
    if (addHighlight) {
        const dropdowns = document.querySelectorAll(`${componentClass}`);
        dropdowns.forEach(dropdown => {
            const container = dropdown.querySelector(`${classToHighlight}`);
            if (container) {
                container.classList.add('highlight');
            }
        });
    }
    else {
        const dropdowns = document.querySelectorAll(`${componentClass}`);
        dropdowns?.forEach(dropdown => {
            const container = dropdown?.querySelector(`${classToHighlight}`);
            if (container) {
                container.classList.remove('highlight');
            }
        });
    }
};
