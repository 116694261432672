import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Resources.styles.scss";
import { useLogic } from "./Resources.logic";
import { Button, Flex, FlexItem, Text, FormInput, ChevronStartIcon, AddIcon, TrashCanIcon } from "@fluentui/react-northstar";
import { useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
export const ResourcesView = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    return (_jsxs(Flex, { column: true, fill: true, styles: {
            display: props.show ? "flex" : "none",
            padding: isOnMobile ? "15px" : "0"
        }, children: [_jsxs(Flex, { children: [_jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: props.onHide }), _jsx(FlexItem, { push: true, children: _jsx(Button, { primary: true, content: t("ButtonAddRessource"), icon: _jsx(AddIcon, { styles: { paddingTop: "2px" } }), onClick: logic.onAddResource }) })] }), _jsx(Flex, { column: true, fill: true, styles: { marginTop: "20px", overflowY: "scroll", maxHeight: "70vh" }, gap: "gap.medium", children: props.resources.length === 0 ?
                    _jsx(Flex, { fill: true, vAlign: "center", hAlign: "center", children: _jsx(Text, { content: t("NoResource") }) })
                    :
                        props.resources.map((item, index) => {
                            return (_jsxs(Flex, { vAlign: "start", gap: "gap.small", children: [_jsx(FlexItem, { grow: true, children: _jsx(FormInput, { fluid: true, clearable: true, max: 100, errorMessage: item.title.length === 0 ?
                                                t("EmptyFillError") : "", placeholder: t("placeholderResources") + (index + 1), onChange: (e, data) => logic.onUpdateResource(index, data?.value), value: item.title }) }), _jsx(TrashCanIcon, { styles: { marginTop: "6px" }, outline: true, size: "large", className: "trash-button", onClick: () => logic.onDeleteResource(index) })] }, "res_" + index));
                        }) })] }));
};
