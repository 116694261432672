import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@fluentui/react-northstar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
export const ToggleButton = (props) => {
    const [pressed, setPressed] = useState(false);
    const buttonRef = React.createRef();
    useEffect(() => {
        switch (props.freqString) {
            case 'byweekday':
                if (props.rrule?.byweekday?.includes(props.tabindex)) {
                    setPressed(true);
                }
                break;
            case 'bymonthday':
                if (props.resetSelectedDate === true) {
                    setPressed(false);
                }
                else {
                    if (props.rrule?.bymonthday?.includes(props.tabindex)) {
                        setPressed(true);
                    }
                    const dayOfMonth = moment(props.rrule?.dtstart).date();
                    if (dayOfMonth === props.tabindex) {
                        setPressed(true);
                        props.onWeeklyItervalUpdate(true, props.tabindex, true);
                    }
                }
                break;
            default:
                break;
        }
    }, [props.resetSelectedDate]);
    const onClick = (e) => {
        props.onWeeklyItervalUpdate(true, +e.currentTarget.getAttribute('tabindex'), !pressed);
        setPressed(!pressed);
    };
    return (_jsx(Button, { circular: props.freqString === 'bymonthday', disabled: props.disabled, styles: props.freqString === 'bymonthday'
            ? { marginBottom: '10px', borderTopRightRadius: "62.4375rem !important", borderTopLeftRadius: "62.4375rem !important", borderBottomRightRadius: "62.4375rem !important", borderBottomLeftRadius: "62.4375rem !important" }
            : { marginBottom: '10px', borderRadius: '100px', minWidth: 'auto' }, ref: buttonRef, tabIndex: props.tabindex, primary: !props.disabled && pressed, onClick: (e) => onClick(e), content: props.weeks }));
};
