import { useDispatch } from "react-redux";
import { useNotificationCache } from "../services/CacheService/notificationCache";
import { useEffect, useState } from "react";
import { initializeScope, useScopesSelector } from "../redux/reducers/ScopesReducer/ScopesReducer";
import { setConfiguration, useConfigurationSelector } from "../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { useAuthSelector } from "../redux/reducers/AuthReducer/AuthReducer";
import { useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { TokenApi } from "../apis/Token/TokenApi";
//dans le cas ou une app ne nécesite pas le graph a son initialisation, on utilise ce hook dans les composant qui vont avoir besoin du graph.
//si forceAsk contient un scope on va requet le graphtoken 
//si il est a false on va requete le graph en fonctio nde parametre tel que l'activation de notification.
export const useGraph = (props) => {
    const { notif } = useNotificationCache();
    const { data: config } = useConfigurationSelector("data");
    const { needToConsent } = useScopesSelector("needToConsent");
    const { isTeamsApp } = useAuthSelector("isTeamsApp");
    const { locale, isOnMobile, themeClass, tenantId } = useMsTeamsSelector("locale", "isOnMobile", "themeClass", "tenantId");
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const initGraph = (scope) => {
        let scopes = config?.graphScopes;
        if (!config?.graphScopes.includes(scope))
            scopes += " " + scope;
        const scopesServiceConfiguration = {
            clientId: config?.clientId,
            apiBaseUrl: window.location.origin,
            scopes: scopes ? scopes?.split(" ") : [],
            inTeams: isTeamsApp,
            locale: locale,
            isWidget: false,
            isOnMobile: isOnMobile,
            theme: themeClass,
            tenantId: tenantId,
        };
        const updatedConfig = { ...config };
        updatedConfig.graphScopes = scopes;
        dispatch(initializeScope({ initArgs: scopesServiceConfiguration, askGraphToken: true }));
        dispatch(setConfiguration({ data: updatedConfig }));
        setTimeout(() => setLoaded(true), 2000);
    };
    useEffect(() => {
        if (!needToConsent)
            return;
        setLoaded(false);
    }, [needToConsent]);
    useEffect(() => {
        if (!notif || props.forceAsk)
            return;
        if (!notif.delete && !notif.update)
            return;
        initGraph("TeamsActivity.Send");
    }, [notif]);
    useEffect(() => {
        if (!props.forceAsk)
            return setLoaded(true);
        initGraph(props.forceAsk);
    }, [props.forceAsk]);
    const isGraphAllow = async (rights) => {
        let scopes = config?.graphScopes;
        if (!config?.graphScopes.includes(rights))
            scopes += " " + rights;
        scopes = scopes.replace(new RegExp(" ", "g"), ",");
        const res = await TokenApi.getGraphToken(scopes);
        return res;
    };
    const askScopeGraph = (rights) => {
        initGraph(rights);
    };
    return { loaded, isGraphAllow, askScopeGraph };
};
