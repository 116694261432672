import React, { useEffect, useState} from "react";
import {Loader} from "@fluentui/react-northstar";
import { useSelector } from "react-redux";
import { App } from "calendarShared";
import { useConfig } from "config/config.hook";

export const AppCalendar = () => {
    const [ready, setReady] = useState(false);
    const {appViews} = useSelector((s:any)=> s.infosApp);
    useConfig();


    useEffect(()=>{
       if(!appViews) return;
       setReady(true)
    },[appViews])

    if(!ready) return <Loader styles={{height: "100vh"}}/>
    return (
        <App/>
    )
}