import { AxiosConfig, useAppConfigurationSelector } from "front";
import { useState } from "react";
import copy from 'copy-to-clipboard';
export const useLinksPanelLogic = () => {
    const { appId } = useAppConfigurationSelector("appId");
    const appUri = AxiosConfig.instance.getUri().replace(/\/api\/v1$/, '');
    const [state, setState] = useState({ showIframePopupCopied: false, showUrlPopupCopied: false });
    const onCopyAppPath = () => {
        const appPath = `${appUri}/appointments/meetingscheduler/${appId}`;
        copy(appPath);
        setState((prev) => ({ ...prev, showUrlPopupCopied: true }));
        setTimeout(() => {
            setState((prev) => ({ ...prev, showUrlPopupCopied: false }));
        }, 1000);
    };
    const onCopyIframe = () => {
        const iframe = `<iframe src="${appUri}/appointments/meetingscheduler/${appId}" style="border: 0" width="800" height="600" frameborder="0"></iframe>`;
        copy(iframe);
        setState((prev) => ({ ...prev, showIframePopupCopied: true }));
        setTimeout(() => {
            setState((prev) => ({ ...prev, showIframePopupCopied: false }));
        }, 1000);
    };
    return {
        state,
        onCopyIframe,
        onCopyAppPath,
        appUri,
        appId
    };
};
