import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CompareModule, OverwriteObjectDataWithWatchData, Modal, getKeyRegistered, manageWatchHookForm, openModal, setValuesHookForm, useAppConfigurationSelector, useModalSelector, useTranslate } from "front";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMeetingSchedulerCache } from "../../services/cacheService/meetingSchedulerCache";
import { useEffect, useState } from "react";
import { translations } from "../../translations";
import { ExclamationCircleIcon, Text, Tooltip } from "@fluentui/react-northstar";
export const useMeetingSchedulerLogic = () => {
    const { appPathName, appId } = useAppConfigurationSelector("appPathName", "appId", "isResourceMode", "appName", "app");
    const { isOpen } = useModalSelector("isOpen");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const t = useTranslate(translations);
    const { meetingScheduler, mutateMeetingScheduler } = useMeetingSchedulerCache();
    const { register, handleSubmit, watch, setValue, control, formState: { errors, isValid, isDirty }, getFieldState } = useForm({ mode: "onChange" });
    //const [menuItems, setMenuItems] = useState([]);
    const meetingSchedulerFilter = {
        title: meetingScheduler?.title,
        businessDays: meetingScheduler?.businessDays,
        timezone: meetingScheduler?.timezone,
        publicHolidays: undefined,
        companyName: meetingScheduler?.companyName,
        eventColor: meetingScheduler?.eventColor,
        duration: meetingScheduler?.duration,
        hosts: meetingScheduler?.hosts,
        inputsTag: meetingScheduler?.inputsTag
    };
    const fieldToRegister = getKeyRegistered(meetingSchedulerFilter);
    const watchData = manageWatchHookForm(watch, fieldToRegister);
    const [state, setState] = useState({ isLoading: true });
    useEffect(() => {
        if (!meetingScheduler)
            return;
        setState({
            isSaving: false,
            isLoading: false,
            isSaved: false
        });
        meetingSchedulerFilter;
        setValuesHookForm(setValue, fieldToRegister, meetingScheduler);
    }, [meetingScheduler]);
    const errorForm = () => {
        return (!CompareModule.areObjectsEqual(errors, {}));
    };
    const menuItems = [
        {
            key: 'booking-page-details',
            content: errorForm() ?
                (_jsx(Tooltip, { content: t("TabErrorTooltip"), styles: { pointerEvents: "auto" }, children: _jsxs(Text, { color: "red", styles: { verticalAlign: "true" }, children: [t("BookingPageDetailsTab"), " ", _jsx(ExclamationCircleIcon, { size: "small", outline: true }), " "] }) }))
                :
                    _jsx(Text, { content: t("BookingPageDetailsTab") }),
            index: 0,
            styles: {
                borderBottomColor: errorForm() ? "red" : "rgb(91, 95, 199)",
            }
        },
        {
            key: 'booking-availability',
            content: _jsx(Text, { content: t("BookingAvailabilityTab") }),
            index: 1
        },
        {
            key: 'booking-page-options',
            content: _jsx(Text, { content: t("BookingPageOptionsTab") }),
            index: 2
        },
        {
            key: 'sharing-links',
            content: _jsx(Text, { content: t("SharingLinkTab") }),
            index: 3
        },
    ];
    const toggleDiscardDialog = () => {
        if (!isOpen)
            dispatch(openModal(Modal.PERMISSIONS_CANCEL_UNSAVE_CHANGE));
    };
    const handleDiscardConfirmed = async () => {
        setValuesHookForm(setValue, fieldToRegister, meetingScheduler);
    };
    const returnToApp = () => {
        if (!appPathName)
            return;
        navigate(`/${appPathName}/` + appId);
    };
    const saveSettings = async () => {
        const stateObject = JSON.parse(JSON.stringify(meetingScheduler));
        ;
        OverwriteObjectDataWithWatchData(fieldToRegister, watchData, stateObject);
        setState((prev) => ({ ...prev, isSaving: true, object: stateObject }));
        mutateMeetingScheduler(stateObject, { onSuccess: async () => {
                setState({ isSaving: false, isLoading: false, isSaved: true });
                setTimeout(() => {
                    setState((prevState) => ({
                        ...prevState,
                        isSaved: false,
                    }));
                }, 5000);
            } });
    };
    const canSave = () => {
        if (!meetingScheduler || state.isLoading)
            return false;
        const actualMeetingSchedulerSettings = JSON.parse(JSON.stringify(meetingScheduler));
        OverwriteObjectDataWithWatchData(fieldToRegister, watchData, actualMeetingSchedulerSettings);
        return (!CompareModule.areObjectsEqual(meetingScheduler, actualMeetingSchedulerSettings));
    };
    return {
        toggleDiscardDialog,
        returnToApp,
        handleSubmit,
        register,
        setValue,
        control,
        isOpen,
        saveSettings,
        state,
        canSave,
        handleDiscardConfirmed,
        isValid,
        errorForm,
        meetingScheduler,
        meetingSchedulerFilter,
        watchData,
        menuItems
    };
};
