import { useAppConfigurationSelector, useTranslate } from "front";
import { PublicHoliday } from "../../../apis/apiGoogleCalendar";
import { translations } from "../../../translations";
import { useState } from 'react';
export const useAvailabilityPanelLogic = (props) => {
    const t = useTranslate(translations);
    const { from } = useAppConfigurationSelector("from");
    const [timezoneSearchQuery, setTimezoneSearchQuery] = useState("");
    const [holidaysSearchQuery, setHolidaysSearchQuery] = useState("");
    const publicHolidaysRegionsItems = Object.keys(PublicHoliday).map((key) => ({ header: t(key), value: key }));
    return {
        publicHolidaysRegionsItems,
        from,
        timezoneSearchQuery,
        setTimezoneSearchQuery,
        holidaysSearchQuery,
        setHolidaysSearchQuery
    };
};
