import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ClipboardCopiedToIcon, Flex, Popup, Text } from "@fluentui/react-northstar";
import { useLinksPanelLogic } from "./LinksPanel.logic";
import { useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../../translations";
export const LinksPanel = () => {
    const logic = useLinksPanelLogic();
    const t = useTranslate(translations);
    const { isOnMobile, themeClass } = useMsTeamsSelector("isOnMobile", "themeClass");
    const meetingSchedulerLinkInput = () => (_jsx(Flex, { styles: { alignItems: "center" }, children: _jsxs(Flex, { column: true, styles: { width: "100%" }, children: [_jsx(Text, { content: t("LinkPlatform") }), _jsxs(Flex, { styles: { flexDirection: "row", alignItems: "center" }, children: [_jsx(Flex, { styles: { width: "70%", borderRadius: "5px", border: "1px solid #BFBFBF", backgroundColor: "transparent", padding: "8px", marginRight: "10px" }, children: _jsx(Text, { styles: { width: "100%", overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: "break-word", filter: "none" }, content: `${logic.appUri}/appointments/meetingscheduler/${logic.appId}` }) }), _jsx(Popup, { pointing: true, open: logic.state.showUrlPopupCopied, content: t("Copied"), trigger: _jsx(Button, { icon: _jsx(ClipboardCopiedToIcon, { outline: true, onClick: logic.onCopyAppPath }), text: true, type: "button", iconOnly: true, title: t("Copy") }) }, "url_popup")] })] }) }));
    const integrationCodeInput = () => (_jsx(Flex, { children: _jsxs(Flex, { column: true, styles: { width: "100%" }, children: [_jsx(Text, { content: t("IntegrationCode") }), _jsxs(Flex, { styles: { flexDirection: "row", alignItems: "center" }, children: [_jsx(Flex, { fill: true, styles: { width: "70%", borderRadius: "5px", border: "1px solid #BFBFBF", backgroundColor: "transparent", padding: "8px", marginRight: "10px" }, children: _jsx(Text, { styles: { width: "100%", overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: "break-word", scrollbarHeight: "12px", filter: "none" }, content: `<iframe src="${logic.appUri}/appointments/meetingscheduler/${logic.appId}" style="border: 0" width="800" height="600" frameborder="0"></iframe>` }) }), _jsx(Popup, { pointing: true, open: logic.state.showIframePopupCopied, content: t("Copied"), trigger: _jsx(Button, { icon: _jsx(ClipboardCopiedToIcon, { outline: true, onClick: logic.onCopyIframe }), text: true, type: "button", iconOnly: true, title: t("Copy") }) }, "iframe_popup")] })] }) }));
    return (_jsxs(Flex, { column: true, fill: true, gap: "gap.medium", styles: {
            display: "flex",
            height: "100%",
            width: "100%",
            marginLeft: "20px",
        }, children: [meetingSchedulerLinkInput(), integrationCodeInput()] }));
};
