import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, FormDropdown, Header, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import { useAvailabilityPanelLogic } from "./AvailabilityPanel.logic";
import { findKeyPath, useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../../translations";
import { BusinessDaysComponent } from "../../businessDays/BusinessDays";
import momentTz from "moment-timezone";
export const AvailabilityPanel = (props) => {
    const logic = useAvailabilityPanelLogic(props);
    const t = useTranslate(translations);
    const timeZones = momentTz.tz.names();
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const selectedPublicHolidayItem = logic.publicHolidaysRegionsItems.find((phr) => phr.value === props.meetingScheduler.publicHolidays);
    const publicHolidaysDropdown = () => (_jsx(Flex, { styles: { maxWidth: "fit-content", paddingTop: "10px", paddingBottom: "10px", marginLeft: "20px" }, children: _jsx(FormDropdown, { fluid: true, search: true, inverted: true, styles: { width: "300px" }, label: _jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingScheduler:PublicHolidaysComponent"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingScheduler:PublicHolidaysInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), ...props.register(findKeyPath(props.keysRegistered, 'publicHolidays')), searchQuery: logic.holidaysSearchQuery, onSearchQueryChange: (_, data) => { logic.setHolidaysSearchQuery(data.searchQuery); }, placeholder: t(props.watchData[findKeyPath(props.keysRegistered, 'publicHolidays')]), items: logic.publicHolidaysRegionsItems, onChange: (_, data) => { props.setValue(findKeyPath(props.keysRegistered, 'publicHolidays'), data.value.value); logic.setHolidaysSearchQuery(""); }, "data-testid": "availability-panel-holiday", disabled: !userPerm.perm.allowEditMeetingScheduler }, "public-holidays-meeting-scheduler") }));
    const timezoneDropdown = () => (_jsx(Flex, { styles: { maxWidth: "fit-content", paddingBottom: "10px", marginLeft: "20px" }, children: _jsx(FormDropdown, { search: true, inverted: true, label: _jsx(Text, { content: t("MeetingScheduler:TimezoneComponent"), weight: "semibold" }), fluid: true, checkable: true, items: timeZones, ...props.register(findKeyPath(props.keysRegistered, 'timezone')), placeholder: props.watchData[findKeyPath(props.keysRegistered, 'timezone')], searchQuery: logic.timezoneSearchQuery, onSearchQueryChange: (_, data) => { logic.setTimezoneSearchQuery(data.searchQuery); }, onChange: (_, data) => { props.setValue(findKeyPath(props.keysRegistered, 'timezone'), data.value); logic.setTimezoneSearchQuery(""); }, "data-testid": "availability-panel-timezone", styles: {
                ".ui.dropdown .menu>.item": {
                    color: "red !important"
                }
            }, disabled: !userPerm.perm.allowEditMeetingScheduler }, "timezone-meeting-scheduler") }));
    return (_jsxs(Flex, { column: true, fill: true, gap: "gap.medium", styles: {
            display: "flex",
            height: "100%",
            width: isOnMobile ? "95vw" : "auto",
            marginLeft: "20px",
        }, children: [_jsx(Header, { as: "h4", content: t("MeetingScheduler:BookingAvailabilityTitle"), styles: { paddingBottom: "10px" } }), timezoneDropdown(), _jsxs(Flex, { column: true, gap: "gap.small", styles: { marginLeft: "20px", width: isOnMobile ? "100%" : "500px", }, children: [_jsxs(Flex, { gap: "gap.smaller", vAlign: "center", children: [_jsx(Text, { content: t("MeetingScheduler:BusinessDaysComponent"), weight: "semibold" }), _jsx(Tooltip, { content: t("MeetingScheduler:BusinessDaysInfo"), children: _jsx(InfoIcon, { outline: true, size: "small" }) })] }), _jsx(BusinessDaysComponent, { disable: !userPerm.perm.allowEditMeetingScheduler, readOnly: false, dataLoaded: !!props.meetingScheduler, register: props.register, setValue: props.setValue, keysRegistered: props.keysRegistered, watchData: props.watchData, control: props.control })] }), publicHolidaysDropdown()] }));
};
