import { AxiosConfig } from "front";
export const getResource = (calendarId) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.get(`/resources/${calendarId}`);
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get resource");
    }
});
export const createResource = async (calendarId, resource) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.post(`/resources/calendar/${calendarId}`, JSON.stringify(resource));
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't create resource");
    }
});
export const updateResource = async (resourceId, calendarId, resource) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.patch(`/resources/${resourceId}/calendar/${calendarId}`, JSON.stringify(resource));
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update resource");
    }
});
export const deleteResource = async (calendarId, resourceId) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.delete(`/resources/${resourceId}/calendar/${calendarId}`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete resource");
    }
});
