import { useState, useRef, useMemo } from "react";
import { useNotificationsSelector } from "../../../redux/reducers/NotificationsReducer/NotificationsReducer";
import { useAppConfigurationSelector } from "../../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useGraph } from "../../../hook/useGraph";
import { useNotificationCache } from "../../../services/CacheService/notificationCache";
import { CompareModule } from "../../../modules/Compare.module";
export const useNotifLogic = () => {
    const dispatch = useDispatch();
    const { update, delete: deleteNotif } = useNotificationsSelector("update", "delete");
    const { mutateNotif } = useNotificationCache();
    const { appId } = useAppConfigurationSelector("appId");
    const { loaded } = useGraph({ forceAsk: "TeamsActivity.Send" });
    //const loaded = true
    const [notifState, setNotifState] = useState({ projectId: appId,
        update,
        delete: deleteNotif });
    const originalState = useRef({ ...notifState });
    const onChange = (type) => () => {
        setNotifState((prev) => ({ ...prev, [type]: !prev[type] }));
    };
    const onClose = () => {
        dispatch(openModal(undefined));
    };
    const save = () => {
        // appeler une mutation pour patch la config
        //deplacer le infoReducer dan le packages front
        mutateNotif(notifState);
        onClose();
    };
    const compareState = useMemo(() => {
        return CompareModule.areObjectsEqual(notifState, originalState.current);
    }, [notifState, originalState]);
    return { notifState, onChange, onClose, save, loaded, compareState };
};
