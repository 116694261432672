import { findKeyPath, useAppConfigurationSelector, useTranslate } from "front";
import { translations } from "../../../translations";
import { IDuration } from "../../../interfaces/Enums";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { loadAllUsersPerms } from "./DetailsPanel.utils";
import { durationText } from "../../../utils/duration";
export const useDetailsPanelLogic = (props) => {
    const t = useTranslate(translations);
    const { appId } = useAppConfigurationSelector("appId");
    const [isDropdownHostsOpen, setIsDropdownHostsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    // Création des éléments pour le dropdown avec les durées formatées
    const durationItems = Object.keys(IDuration).map((key) => ({
        header: durationText(IDuration[key]),
        value: IDuration[key]
    }));
    const selectedDurationItem = durationItems?.find((d) => d.value === props.watchData[findKeyPath(props.keysRegistered, 'duration')].toString());
    const queryKeyMemo = useCallback(() => {
        return [
            "allUsersPerms",
            {
                appId
            },
        ];
    }, [appId]);
    const { data: allUsersPerms, refetch: refetchAllUsersPerms } = useQuery(queryKeyMemo(), loadAllUsersPerms, {
        staleTime: 900000,
        enabled: !!appId,
        retry: 1,
    });
    const handleColorOnChange = (color) => {
        props.setValue(findKeyPath(props.keysRegistered, 'eventColor'), color);
    };
    const onSelectDuration = (e, data) => {
        const value = data?.value?.value ?? undefined;
        props.setValue(findKeyPath(props.keysRegistered, 'duration'), value);
    };
    const onSelectHosts = (user, field, data) => {
        const currentHosts = Array.from(new Set(props.watchData[findKeyPath(props.keysRegistered, 'hosts')]));
        let newCurrentHosts = [];
        if (data.checked) {
            const newHost = [{
                    displayName: user.name,
                    userPrincipalName: user.upn,
                    id: user.id,
                    picture: ""
                }];
            newCurrentHosts = [...currentHosts, ...newHost];
        }
        else {
            newCurrentHosts = currentHosts?.filter((h) => h.id !== user.id);
        }
        field.onChange(newCurrentHosts ?? []);
    };
    const filteredUsers = useMemo(() => {
        if (!allUsersPerms)
            return [];
        return allUsersPerms.users.filter((u) => u.name.toLowerCase().includes(searchQuery.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }, [searchQuery, allUsersPerms]);
    return {
        handleColorOnChange,
        onSelectDuration,
        durationItems,
        allUsersPerms,
        onSelectHosts,
        setIsDropdownHostsOpen,
        isDropdownHostsOpen,
        searchQuery,
        setSearchQuery,
        filteredUsers,
        selectedDurationItem
    };
};
