import { useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import * as microsoftTeams from "@microsoft/teams-js";
import { DisplayMode, ResourceMode, SecurityMode } from "../../interfaces/Enums";
import { useEffect, useRef, useState } from "react";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import moment from "moment";
import "moment/min/locales";
import { createResource, getResource, updateResource } from "../../apis/apiResource";
import { translations } from "../../translations";
import { GetCalendarsWithEventsByTenantIdAndUserId, copyEventFromASpecificCalendar, createCalendar } from "../../apis/apiCalendar";
import { generateDropdownItems } from "../../components/event/eventUtils";
import { useSelector } from "react-redux";
import { useOptionsSelector } from "../../redux/reducers/optionsReducer";
export const buisinessDayDefault = { startHour: 8, endHour: 18, days: [0, 1, 2, 3, 4, 5, 6] };
const capitalizeString = (s) => {
    if (!s)
        return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};
export const useConfigLogic = () => {
    const t = useTranslate(translations);
    const resourceModes = generateDropdownItems(ResourceMode, ["Standard", "Resources"]);
    const { userMail, groupId, channelId, channelName, tenantId, userId, userName } = useMsTeamsSelector("userMail", "groupId", "channelId", "channelName", "tenantId", "userId", "userName");
    const { isPremium } = usePremiumSelector("isPremium");
    const { calendar, mutateCalendar } = useCalendarCache();
    const queryClient = useSelector((s) => s.infosApp.queryClient);
    const { forceRessource, nameApp } = useOptionsSelector("forceRessource", "nameApp");
    const initialState = {
        basicResources: new Array(),
        loaded: false,
        calendar: {
            securityMode: SecurityMode.AllUserCanEdit,
            defaultDisplayMode: DisplayMode.Month,
            resourceMode: ResourceMode.Standard,
            showWeekend: true,
            businessDays: buisinessDayDefault,
            showWeekNumbers: false,
            tags: [],
            consentUsers: [],
        },
        showResourcesView: false,
        resource: {
            created: new Date(),
            createdBy: userMail,
            calendarId: calendar && calendar.id,
            resourceData: undefined,
            resourceMode: undefined,
        },
        weekdays: moment
            .weekdays()
            .map((day) => t(capitalizeString(day))),
        allUsers: [],
        ownersSelected: [],
        readOnly: false
    };
    const [cal, setCalendar] = useState(initialState);
    const [calendarsToCopy, setCalendarsToCopy] = useState();
    const [resourceLoaded, setResourceLoaded] = useState(false);
    const calRef = useRef(initialState);
    const calendarIndexToCopyRef = useRef(undefined);
    useEffect(() => {
        if (!tenantId)
            return;
        const getCalendars = async () => {
            const allCalendars = await GetCalendarsWithEventsByTenantIdAndUserId(tenantId, userId);
            setCalendarsToCopy(allCalendars);
        };
        getCalendars();
    }, [tenantId]);
    useEffect(() => {
        calRef.current = cal;
        const getRessource = async () => {
            if (!!cal.calendar.id && cal.calendar.isResourceMode && !cal.resource.calendarId && !resourceLoaded) {
                setResourceLoaded(true);
                const resource = await getResource(cal.calendar.id);
                if (!!resource)
                    setCalendar((prev) => ({
                        ...prev,
                        resource: resource,
                        basicResources: resource.resourceData ?? new Array(),
                    }));
            }
        };
        getRessource();
    }, [cal, resourceLoaded]);
    useEffect(() => {
        let isActive = true;
        if (cal.calendar.name?.length == 0 || !cal.calendar.name) {
            isActive = false;
        }
        if (cal.calendar.resourceMode === ResourceMode.Resources)
            isActive = cal.basicResources.length != 0 && !cal.basicResources.some((r) => r.title === "");
        microsoftTeams.pages.config.setValidityState(isActive);
    }, [cal]);
    useEffect(() => {
        if (!calendar)
            return;
        if (calendar && calendar.id == "false") {
            initializeNewCalendar();
        }
        else {
            updatedCalendar();
        }
        microsoftTeams.pages.config.registerOnSaveHandler(async (saveEvent) => await saveConfiguration(saveEvent));
        microsoftTeams.app.notifySuccess();
    }, [calendar]);
    const manageName = (nameApp) => {
        return t(nameApp);
    };
    const initializeNewCalendar = () => {
        const newCalendar = { ...cal.calendar,
            firstDay: 0,
            name: manageName(nameApp),
            teamId: groupId,
            channelId: channelId ?? "fromGroup",
            channelName: channelName ?? "fromGroup",
            tenantId: tenantId,
            defaultDisplayMode: DisplayMode.Month,
            securityMode: SecurityMode.AllUserCanEdit,
            resourceMode: forceRessource ? ResourceMode.Resources : ResourceMode.Standard,
            isCopying: !!calendarIndexToCopyRef.current,
            basedOnCalendarId: calendarsToCopy !== undefined ? calendarsToCopy[calendarIndexToCopyRef.current]?.id : undefined,
            isResourceMode: forceRessource,
            createdBy: userMail,
            updatedBy: userMail,
            updated: moment().toDate(),
            created: moment().toDate(),
            owners: [{
                    upn: userMail,
                    id: "root",
                }]
        };
        const newResource = {
            createdBy: userMail,
            calendarId: newCalendar.id,
            resourceData: new Array()
        };
        setCalendar((prev) => ({
            ...prev,
            ownersSelected: newCalendar.owners,
            calendar: newCalendar,
            loaded: true,
            resource: newResource
        }));
    };
    const updatedCalendar = () => {
        const updatedCalendar = { ...calendar };
        updatedCalendar.updatedBy = userMail;
        let stateUpdate = {
            calendar: updatedCalendar,
            loaded: true,
        };
        if (isPremium) {
            stateUpdate.ownersSelected = updatedCalendar.owners;
            stateUpdate.calendar.owners = updatedCalendar.owners;
        }
        if (!updatedCalendar.owners) {
            let rootOwner = {
                upn: userMail.toLowerCase(),
                id: "root",
            };
            stateUpdate.ownersSelected = new Array(rootOwner);
            stateUpdate.calendar.owners = new Array(rootOwner);
        }
        if (stateUpdate.calendar.securityMode === SecurityMode.AllUserCanEdit) {
            stateUpdate.readOnly = false;
        }
        else {
            stateUpdate.readOnly =
                stateUpdate.calendar.createdBy?.toLowerCase() !==
                    userMail.toLowerCase();
        }
        if (stateUpdate.readOnly)
            microsoftTeams.pages.config.setValidityState(false);
        setCalendar((prev) => ({
            ...prev,
            ...stateUpdate
        }));
    };
    const saveConfiguration = async (saveEvent) => {
        if (cal.readOnly) {
            saveEvent.notifyFailure(t("NoRightToUpdateConfig"));
            return;
        }
        let calendarId = "";
        let resource = {};
        if (!calRef.current.calendar.id) {
            try {
                let newCalendar = await createCalendar(tenantId, calRef.current.calendar);
                calendarId = newCalendar.id;
                if (calRef.current.calendar.isResourceMode) {
                    resource = {
                        ...calRef.current.resource,
                        calendarId: calendarId,
                        resourceData: calRef.current.basicResources,
                    };
                    resource = await createResource(calendarId, resource);
                    newCalendar = {
                        ...newCalendar,
                        resourceId: resource.id,
                    };
                    mutateCalendar(newCalendar);
                }
                const AFInfo = {
                    SourceCalendarId: newCalendar.basedOnCalendarId,
                    Date: moment().toISOString(),
                    NewCalendarId: newCalendar.id,
                    Mail: newCalendar.createdBy,
                    EventErrorCount: 0,
                    isCopying: newCalendar.isCopying,
                    UserName: userName
                };
                copyEventFromASpecificCalendar(AFInfo);
            }
            catch (error) {
                console.log(error, JSON.stringify(error));
                saveEvent.notifyFailure();
                return;
            }
        }
        else { // Si c'est d�j� existant
            calendarId = calRef.current.calendar.id;
            if (calRef.current.calendar.isResourceMode) {
                resource = {
                    ...calRef.current.resource,
                    updated: new Date(),
                    updatedBy: userMail,
                    calendarId: calendarId,
                    resourceData: calRef.current.basicResources
                };
                if (!!resource.id) {
                    await updateResource(resource.id, calendarId, resource);
                }
                else
                    resource = await createResource(calendarId, resource);
            }
            let rootOwner = {
                upn: userMail.toLowerCase(),
                id: "root",
            };
            let updatedCalendar = {
                ...calRef.current.calendar,
                resourceId: resource.id,
                owners: [rootOwner],
            };
            mutateCalendar(updatedCalendar);
        }
        microsoftTeams.pages.config.setConfig({
            contentUrl: window.location.origin + "/calendars/" + calendarId,
            websiteUrl: window.location.origin + "/calendars/" + calendarId,
            removeUrl: window.location.origin +
                "/remove/" + calendarId,
            entityId: calendarId,
            suggestedDisplayName: calRef.current.calendar.name ?? "",
        });
        if (calRef.current.calendar.isResourceMode) {
            queryClient.setQueryData("resources", resource.resourceData);
        }
        saveEvent.notifySuccess();
    };
    /**
     * Format resource mode
     */
    const formatResourceMode = () => {
        return resourceModes.find((f) => f.value === cal.calendar.resourceMode);
    };
    /**
     * On change resource mode
     * @param event
     * @param data
     */
    const onChangeResourceMode = (event, data) => {
        const isResourceMode = data.value.value === 1;
        setCalendar((prev) => ({
            ...prev,
            calendar: {
                ...prev.calendar,
                resourceMode: isResourceMode
                    ? ResourceMode.Resources
                    : ResourceMode.Standard,
                isResourceMode: isResourceMode,
            },
        }));
    };
    /**
     * On change calendar name
     * @param e
     */
    const onChangeCalendarName = (e) => {
        setCalendar((prev) => ({
            ...prev,
            calendar: {
                ...prev.calendar,
                name: e.target.value
            },
        }));
    };
    const onChangeCalendarCopiedName = (data) => {
        calendarIndexToCopyRef.current = data.highlightedIndex;
        setCalendar((prev) => ({
            ...prev,
            calendar: {
                ...prev.calendar,
                basedOnCalendarId: data.value !== null ? calendarsToCopy[data.highlightedIndex].id : undefined,
                isCopying: data.value !== null ? true : false
            },
        }));
    };
    const showResourcesView = (show) => {
        setCalendar((prev) => ({
            ...prev,
            showResourcesView: show
        }));
    };
    const updatebasicResource = (resources) => {
        setCalendar((prev) => ({
            ...prev,
            basicResources: resources
        }));
    };
    return {
        data: cal,
        formatResourceMode,
        onChangeResourceMode,
        onChangeCalendarName,
        showResourcesView,
        updatebasicResource,
        capitalizeString,
        onChangeCalendarCopiedName,
        resourceModes,
        calendarsToCopy,
        calendarIndexToCopyRef
    };
};
