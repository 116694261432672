import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, Alert, Button, Checkbox, Dialog, ExclamationTriangleIcon, Flex, FlexItem, Form, FormDropdown, SettingsIcon, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { useLogic } from "./Settings.logic";
import { translations } from "../../../translations";
import { findKeyPath, useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { BusinessDaysComponent } from "../../businessDays/BusinessDays";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
export const SettingsDialog = (props) => {
    const t = useTranslate(translations);
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { isPremium } = usePremiumSelector("isPremium");
    const { calendar } = useCalendarCache();
    const { allowBuisnessDay, allowPublicHolidays, allowShowNumberOfWeek, allowShowWeekend } = useOptionsSelector("allowShowWeekend", "allowBuisnessDay", "allowPublicHolidays", "allowShowNumberOfWeek");
    const logic = useLogic(props);
    /**
     * Render no rights to edit warn message
     */
    const renderNoRightsToEditWarnMessage = () => {
        return _jsx(Alert, { warning: true, icon: _jsx(ExclamationTriangleIcon, { outline: true }), content: t("NoRightToUpdateConfig") });
    };
    /**
   * Render component display mode
   */
    const renderCalendarViewDropdown = () => {
        return (_jsx(FormDropdown, { label: t("DefaultDisplay"), fluid: true, disabled: logic.state.readOnly, placeholder: t("SelectValue"), value: logic.formatDisplayMode(), items: logic.displayModes, checkable: true, ...logic.register(findKeyPath(logic.calendarFilter, 'defaultDisplayMode')), onChange: logic.onChangeDisplayMode }));
    };
    /**
     * Render component first day of week
     */
    const renderCalendarFirstDayDropdown = () => {
        return (_jsx(FormDropdown, { label: t("FirstDay"), fluid: true, disabled: logic.state.readOnly, placeholder: t("SelectValue"), value: logic.formatFirstDay(), items: logic.state.weekdays, checkable: true, ...logic.register(findKeyPath(logic.calendarFilter, 'firstDay')), onChange: logic.onChangeFirstDayWeek }));
    };
    const renderSettings = () => {
        const showWeekendToggle = (_jsx(Flex, { children: _jsx(Checkbox, { toggle: true, defaultChecked: logic.calendar.showWeekend, ...logic.register(findKeyPath(logic.calendarFilter, 'showWeekend')), label: t("ShowWeekend"), onChange: (_, data) => logic.setValue(findKeyPath(logic.calendarFilter, 'showWeekend'), data.checked), disabled: logic.state.readOnly || !isPremium }) }));
        const weekNumbersToggle = () => (_jsx(Flex, { children: _jsx(Checkbox, { toggle: true, defaultChecked: logic.calendar.showWeekNumbers, label: t("ShowWeekNumbers"), ...logic.register(findKeyPath(logic.calendarFilter, 'showWeekNumbers')), onChange: (_, data) => logic.setValue(findKeyPath(logic.calendarFilter, 'showWeekNumbers'), data.checked), disabled: logic.state.readOnly || !isPremium }) }));
        const businessDaysAccordion = () => (_jsx(Flex, { styles: { marginTop: "-10px" }, children: _jsx(Accordion, { panels: [
                    {
                        title: t("BusinessDays"),
                        content: (_jsx(BusinessDaysComponent, { disable: false, readOnly: logic.state.readOnly, dataLoaded: !!logic.calendar, keysRegistered: logic.calendarFilter, register: logic.register, setValue: logic.setValue, watchData: logic.watchData, control: logic.control }, "settings")),
                    },
                ] }, "accordion_settings") }));
        const publicHolidaysDropdown = () => (logic.watchData[findKeyPath(logic.calendarFilter, 'publicHolidays')] !== "" ?
            _jsxs(Flex, { vAlign: "end", children: [_jsx(FormDropdown, { styles: { width: "100%" }, fluid: true, search: true, label: t("PublicHolidays"), items: logic.publicHolidaysRegionsItems, ...logic.register(findKeyPath(logic.calendarFilter, 'publicHolidays')), searchQuery: logic.holidaysSearchQuery, onSearchQueryChange: (_, data) => { logic.setHolidaysSearchQuery(data.searchQuery); }, placeholder: t(logic.watchData[findKeyPath(logic.calendarFilter, 'publicHolidays')] ?? "SelectValue"), onChange: (_, data) => { logic.setValue(findKeyPath(logic.calendarFilter, 'publicHolidays'), data.value.value); logic.setHolidaysSearchQuery(""); }, disabled: logic.state.readOnly || !isPremium }), _jsx(Button, { icon: _jsx(TrashCanIcon, { outline: true, onClick: () => logic.setValue(findKeyPath(logic.calendarFilter, 'publicHolidays'), "") }), text: true, iconOnly: true, title: t("Copy") })] })
            :
                _jsx(FormDropdown, { fluid: true, search: true, label: t("PublicHolidays"), items: logic.publicHolidaysRegionsItems, ...logic.register(findKeyPath(logic.calendarFilter, 'publicHolidays')), searchQuery: logic.holidaysSearchQuery, onSearchQueryChange: (_, data) => { logic.setHolidaysSearchQuery(data.searchQuery); }, placeholder: t(logic.watchData[findKeyPath(logic.calendarFilter, 'publicHolidays')] ?? "SelectValue"), onChange: (_, data) => { logic.setValue(findKeyPath(logic.calendarFilter, 'publicHolidays'), data.value.value); logic.setHolidaysSearchQuery(""); }, disabled: logic.state.readOnly || !isPremium }));
        return (_jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                marginTop: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
                minHeight: "300px",
                maxHeight: "550px",
            }, children: _jsxs(Form, { onSubmit: logic.handleSubmit(logic.saveSettings), children: [logic.state.readOnly && renderNoRightsToEditWarnMessage(), renderCalendarViewDropdown(), renderCalendarFirstDayDropdown(), !isPremium && !logic.state.readOnly && renderGoPremiumAdvancedAlert(), allowPublicHolidays && publicHolidaysDropdown(), allowShowWeekend && showWeekendToggle, allowShowNumberOfWeek && weekNumbersToggle(), allowBuisnessDay && businessDaysAccordion(), _jsxs(Flex, { gap: "gap.medium", styles: { justifyContent: "end", alignItems: "center", width: "100%", marginRight: isOnMobile ? "15px" : "25px" }, children: [_jsx(FlexItem, { children: _jsx(Button, { secondary: true, size: isOnMobile ? "small" : "medium", type: "button", content: t("Cancel"), disabled: logic.mutateCalendarIsLoading, onClick: props.onHide }) }), _jsx(FlexItem, { children: _jsx(Button, { primary: true, size: isOnMobile ? "small" : "medium", type: "submit", content: t("Save"), disabled: logic.mutateCalendarIsLoading || !logic.canSave, loading: logic.mutateCalendarIsLoading }) })] })] }) }));
    };
    const renderGoPremiumAdvancedAlert = () => (_jsx(Flex, { styles: {
            marginTop: "10px",
        }, children: _jsx(Alert, { warning: true, content: _jsxs(Flex, { gap: "gap.small", children: [_jsx(Text, { style: { textDecoration: 'underline', cursor: "pointer" }, content: t("AlertTagsAndMore"), onClick: logic.showPremiumDialog }), _jsx(Text, { content: "🚀" })] }) }) }));
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px", overflowY: "scroll !important" }, closeOnOutsideClick: false, open: true, header: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(SettingsIcon, { outline: true }), _jsx(Text, { content: t("Settings") })] }), content: !logic.state.loading && renderSettings() }));
};
