import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { renderHook } from "@testing-library/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider as FUIProvider } from "@fluentui/react-northstar";
export const useInitHook = (Component, hook, props, options) => {
    const store = options?.initStore();
    const queryClient = new QueryClient();
    const wrapper = ({ children }) => (_jsx(Provider, { store: store, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(BrowserRouter, { children: _jsxs(FUIProvider, { children: [_jsx(Component, { ...props }), children] }) }) }) }));
    const result = () => renderHook(() => hook(props), { wrapper });
    return {
        result,
        store,
        queryClient,
    };
};
