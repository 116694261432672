import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useCallback, useMemo } from 'react';
export const useDiscardLogic = (props) => {
    const dispatch = useDispatch();
    const closeDialog = useCallback(() => {
        dispatch(openModal(undefined));
    }, []);
    const closeDialogByConfirm = useCallback(() => {
        dispatch(openModal(undefined));
        props.handleDiscardConfirmed();
    }, [props.handleDiscardConfirmed]);
    const closeDialogAndReturnToApp = useCallback(() => {
        dispatch(openModal(undefined));
        props.returnToApp();
    }, [props.returnToApp]);
    const closeDialogAndChangePage = useCallback(() => {
        dispatch(openModal(undefined));
        props.handleDiscardConfirmed();
        if (props.isPreviousPage)
            props.handlePageChange(props.currentPage - 1);
        else if (props.isNextPage)
            props.handlePageChange(props.currentPage + 1);
    }, [props.handlePageChange, props.isPreviousPage, props.isNextPage]);
    const closeDialogAndSearch = useCallback(() => {
        dispatch(openModal(undefined));
        props.handleDiscardConfirmed();
        props.searchOnChange;
    }, [props.handleDiscardConfirmed, props.searchOnChange]);
    const actionDialog = useMemo(() => {
        if (props.isReturnToApp)
            return closeDialogAndReturnToApp;
        if (props.isPreviousPage || props.isNextPage)
            return closeDialogAndChangePage;
        if (props.isSearch)
            return closeDialogAndSearch;
        return closeDialogByConfirm;
    }, [props.isReturnToApp, props.isPreviousPage, props.isNextPage, props.isSearch, closeDialogAndReturnToApp, closeDialogAndChangePage, closeDialogAndSearch, closeDialogByConfirm]);
    return {
        actionDialog,
        closeDialog
    };
};
