import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {AppCalendar} from './App';
import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider} from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'calendarShared';
import * as microsoftTeams from "@microsoft/teams-js";

microsoftTeams.app.notifySuccess();
export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AppCalendar/>
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();