import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, CloseIcon, Flex, FlexItem, Popup, Status, SubtractIcon, SyncIcon, Text, Tooltip, Image } from "@fluentui/react-northstar";
import * as React from "react";
import { useState } from "react";
import MarkdownView from "react-showdown";
import { translations } from "../../translations";
import moment from "moment";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import { calculateDatesRangeDuration } from "./eventUtils";
import { useAppConfigurationSelector, useMsTeamsSelector, useTranslate } from "front";
import { ZOOM_LEVEL_LIMIT } from "front/src/const/const";
/**
 * Popup shown when clicking on a calendar event
 */
export const EventPopup = (props) => {
    const t = useTranslate(translations);
    const zoomLevel = window.devicePixelRatio;
    const [state, setState] = useState({ open: false });
    const { calendar } = useCalendarCache();
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { isResourceMode } = useAppConfigurationSelector("isResourceMode");
    /**
     * Get first part of email
     * @param email email
     */
    const getEmailFirstPart = (email) => email.substring(0, email.indexOf("@"));
    /**
     * On open change
     * @param event
     * @param data
     */
    const onOpenChange = (event, data) => setState({ open: data?.open ?? false });
    /**
     * On close popup
     */
    const onClose = () => setState({ open: false });
    /////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////// RENDERING ////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Render popup content
     */
    const renderPopUpContent = () => {
        let allDay = props.eventInfo.event.allDay;
        let createdBy = props.eventInfo.event.extendedProps.createdBy ?? "";
        let updatedBy = props.eventInfo.event.extendedProps.updatedBy ?? "";
        let displayName = updatedBy === "" ? createdBy : updatedBy;
        const description = !!props.eventInfo.event.extendedProps.description ? (_jsx(MarkdownView, { className: "markdown-viewer", style: { overflow: "auto", overflowWrap: "break-word", maxHeight: "100px" }, markdown: props.eventInfo.event.extendedProps.description.replace(/#</g, '"').replace(/#>/g, '"') })) : null;
        const duration = _jsx(Text, { styles: { color: "gray !important", marginBottom: "20px" }, content: allDay ? t("AllDay") : props.eventInfo.timeText });
        const participants = (_jsxs(Flex, { column: true, style: { padding: 10 }, children: [_jsx(Text, { style: { marginBottom: 5 }, children: t("participants") }), _jsx(Flex, { gap: "gap.small", vAlign: "center", styles: { flexWrap: "wrap" }, children: props.eventInfo.event.extendedProps.participants?.map((p, index) => (_jsx(Tooltip, { content: p.displayName, children: !p.picture ? _jsx(Avatar, { size: "smaller", name: p.displayName }) : _jsx(Image, { style: { width: "24px" }, avatar: true, src: p.picture }) }, index))) })] }));
        const userDetails = (_jsxs(Flex, { column: true, style: { padding: 10 }, children: [_jsx(Text, { style: { marginBottom: 5 }, children: props.eventInfo.event.extendedProps.readOnly
                        ? props.calendarInfo.find((calendar) => calendar.name === props.eventInfo.event.extendedProps.calendarName)?.name + t("BelongsTo")
                        : t(updatedBy === "" ? "CreatedBy" : "UpdatedBy") }), _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(Avatar, { size: "smaller", name: getEmailFirstPart(displayName.toUpperCase()) }), _jsx(Text, { content: getEmailFirstPart(displayName) })] })] }));
        const titleMemo = React.useMemo(() => {
            let content = props.eventInfo.event.title;
            content = props.eventInfo.event.extendedProps.files ? "📎 " + content : content;
            return _jsx(Text, { weight: "bold", styles: { overflow: "auto", overflowWrap: "break-word" }, content: content });
        }, [props.eventInfo]);
        return (_jsxs(Flex, { gap: "gap.small", column: true, styles: { width: zoomLevel > ZOOM_LEVEL_LIMIT ? "180px" : "200px", maxHeight: zoomLevel > ZOOM_LEVEL_LIMIT ? "180px" : "400px", overflowY: "scroll", paddingRight: "12px", paddingLeft: "12px" }, children: [_jsxs(Flex, { gap: "gap.small", children: [_jsx(FlexItem, { children: titleMemo }), _jsx(FlexItem, { push: true, children: _jsx(CloseIcon, { styles: { cursor: "pointer" }, outline: true, onClick: onClose }) })] }), _jsxs(Flex, { className: isOnMobile ? "mobile-pop-up" : "", column: true, gap: "gap.small", children: [description, duration, props.children, props.eventInfo.event.extendedProps.participants.length > 0 && participants, userDetails] })] }));
    };
    /**
     * Render event button
     */
    const renderEventButton = () => {
        const start = moment(props.eventInfo.event.start).toISOString();
        const end = moment(props.eventInfo.event.end).toISOString();
        const isAllDay = props.eventInfo.event.allDay;
        let eventDuration = calculateDatesRangeDuration(start, end);
        let isMoreThanOneDay = eventDuration > 86400000;
        const isResourceMode = calendar?.isResourceMode;
        const statusColor = isMoreThanOneDay || isResourceMode ? props.eventInfo.textColor : props.eventInfo.event.backgroundColor !== "null" ? props.eventInfo.event.backgroundColor : "#6264a7";
        const syncColor = props.eventInfo.event.allDay || isMoreThanOneDay || isResourceMode ? props.eventInfo.textColor : props.eventInfo.event.backgroundColor;
        return (_jsxs(Flex, { fill: true, hAlign: "start", vAlign: "center", styles: { overflow: "hidden" }, children: [!isAllDay && (_jsx(Flex, { children: _jsx(Status, { style: { margin: "0 2px 0 5px" }, color: statusColor }) })), !!props.eventInfo.event.groupId && (_jsx(Flex, { children: _jsxs(Flex, { styles: { position: "relative" }, children: [_jsx(SyncIcon, { style: { marginLeft: 2, color: syncColor } }), props.eventInfo.event.id !== props.eventInfo.event.groupId && (_jsx(SubtractIcon, { styles: {
                                    position: "absolute",
                                    marginLeft: "2px",
                                    color: syncColor,
                                    transform: "rotate(52deg)",
                                } }))] }) })), _jsxs(Flex, { styles: { flexDirection: isResourceMode ? "column" : "row" }, children: [!!props.eventInfo.timeText && _jsx("span", { style: { marginLeft: 5, whiteSpace: "nowrap" }, children: props.eventInfo.timeText }), _jsx("span", { style: {
                                marginLeft: 5,
                                color: props.eventInfo.event.allDay ? props.eventInfo.textColor : "",
                            }, children: props.eventInfo.event.title })] })] }));
    };
    /**
     * Render component
     */
    return _jsx(Popup, { unstable_disableTether: "all", pointing: zoomLevel > ZOOM_LEVEL_LIMIT ? false : true, position: "above", align: "start", open: state.open, content: renderPopUpContent(), trigger: renderEventButton(), onOpenChange: onOpenChange });
};
export default EventPopup;
