import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Button, Flex, ParticipantAddIcon, Text, Tooltip, UserFriendsIcon } from "@fluentui/react-northstar";
import { translations } from "../../translations";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { useParticipantLogic } from "./Participant.logic";
import { labelsMaxWidth } from "../google/GoogleRenderComponent";
import { useTranslate } from "../../hook/useTranslate";
export const Participant = (props) => {
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const addMemberLogic = useParticipantLogic(props);
    const t = useTranslate(translations);
    return (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(UserFriendsIcon, { outline: true, className: "Icon" }), _jsx(Text, { content: t("Participants") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Button, { primary: true, content: t("AddParticipantsButton"), onClick: addMemberLogic.toggleAddMemberDialog, style: {
                        maxWidth: "fit-content",
                        minWidth: !isOnMobile ? "fit-content" : "",
                    }, icon: _jsx(ParticipantAddIcon, { outline: true }), disabled: props.disabled }) }), _jsx(Flex.Item, { grow: true, children: _jsx("div", { style: { marginTop: "4px" }, children: props.members?.map((p, index) => _jsx(Tooltip, { content: p.displayName, children: _jsx(Avatar, { style: { marginRight: "5px" }, size: "smaller", name: p.displayName }) }, index)) }) })] }));
};
