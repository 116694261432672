import * as microsoftTeams from "@microsoft/teams-js";
export const openStageView = (url) => {
    const sharepointID = "2a527703-1f6f-4559-a332-d8a7d288cd88";
    const regex = /^(https?:\/\/[^\/]+)(\/.*)$/;
    const matches = url.match(regex);
    if (!matches)
        return;
    const root = matches[1];
    const path = matches[2];
    const context = {
        contentUrl: `${root}/_layouts/15/teamslogon.aspx?spfx=true&dest=${path}`,
        websiteUrl: url
    };
    const encodeContext = encodeURIComponent(JSON.stringify(context));
    microsoftTeams.app.openLink(`https://teams.microsoft.com/l/stage/${sharepointID}/0?context=${encodeContext}`);
};
