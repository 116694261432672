import { findKeyPath } from "front";
export const useOptionsPanelLogic = (props) => {
    const onToggleInputsTag = (index, isChecked, field) => {
        const inputsTag = Array.from(new Set(props.watchData[findKeyPath(props.keysRegistered, 'inputsTag')]));
        inputsTag[index].isChecked = isChecked;
        field.onChange(inputsTag);
    };
    return {
        onToggleInputsTag
    };
};
