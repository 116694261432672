import { AxiosConfig } from "./AxiosConfig/AxiosConfig";
export const GetCalendarSyncByIcsUrl = (url, timeZone = "Utc") => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.post(`/calendarsync?timeZone=${timeZone}`, url);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get Calendar Sync by ics url");
    }
});
