import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Checkbox, Dialog, ExclamationTriangleIcon, Flex, Header, Loader, Text } from "@fluentui/react-northstar";
import { useNotifLogic } from "./NotificationsDialog.logic";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useMemo } from "react";
import { usePermissionsSelector } from "../../../redux/reducers/Permissions/PermissionsReducer";
import { useTranslate } from "../../../hook/useTranslate";
export const NotificationsDIalog = (props) => {
    const NotifLogic = useNotifLogic();
    const { isOnMobile, userMail } = useMsTeamsSelector("isOnMobile", "userMail");
    const { userPerm } = usePermissionsSelector("userPerm");
    const t = useTranslate(translations);
    const isNotOwner = useMemo(() => userPerm.role == "Member", [userPerm]);
    const renderContent = () => {
        if (!NotifLogic.loaded)
            return _jsx(Loader, { styles: { height: "100%" } });
        return (_jsxs(_Fragment, { children: [isNotOwner && (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true }), _jsx(Text, { content: t("NotAllowedNotif") })] }) }) })), _jsx(Header, { as: "h4", content: props.firstCheckBox }), _jsx(Checkbox, { label: props.firstCheckBoxDesc, disabled: isNotOwner, labelPosition: "start", checked: NotifLogic.notifState.update, onClick: NotifLogic.onChange("update"), toggle: true }), _jsx(Header, { as: "h4", content: props.secondCheckBox }), _jsx(Checkbox, { label: props.secondCheckBoxDesc, disabled: isNotOwner, labelPosition: "start", checked: NotifLogic.notifState.delete, onClick: NotifLogic.onChange("delete"), toggle: true }), _jsxs(Flex, { gap: "gap.medium", styles: { justifyContent: "end", alignItems: "center", width: "100%", marginRight: isOnMobile ? "15px" : "25px", marginTop: "25px" }, children: [_jsx(Flex.Item, { children: _jsx(Button, { secondary: true, size: isOnMobile ? "small" : "medium", content: t("Cancel"), onClick: NotifLogic.onClose }) }), _jsx(Flex.Item, { children: _jsx(Button, { primary: true, size: isOnMobile ? "small" : "medium", content: t("EditTextConfirm"), disabled: NotifLogic.compareState, onClick: NotifLogic.save }) })] })] }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, defaultOpen: true, closeOnOutsideClick: false, header: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("NotifDialogHeader") }) }), content: renderContent() }));
};
