import { useMutation, useQuery } from "react-query";
import { initInputsTag } from "../../interfaces/MeetingScheduler";
import { useCalendarCache } from "./calendarCache";
import { useSelector } from "react-redux";
import { useNavBarSelector } from "../../redux/reducers/navbarReducer";
import { createMeetingScheduler, getMeetingScheduler, updateMeetingScheduler } from "../../apis/apiMeetingScheduler";
import { handleExpiredToken, useAppConfigurationSelector, useMsTeamsSelector } from "front";
import { initialMeetingScheduler } from "../../const/initialMeetingScheduler";
export const useMeetingSchedulerCache = () => {
    const { calendar } = useCalendarCache();
    const { locale, userMail } = useMsTeamsSelector("locale", "userMail");
    const { appName } = useAppConfigurationSelector("appName");
    const { calendarTimeZone } = useNavBarSelector('calendarTimeZone');
    const { queryClient } = useSelector((s) => s.infosApp);
    const { data: meetingScheduler, isLoading: meetingSchedulerLoading } = useQuery("meetingScheduler", () => getMeetingScheduler(calendar.id), {
        staleTime: Infinity,
        enabled: !!calendar.id,
        retry: false,
        onError: async (res) => {
            initialMeetingScheduler.calendarId = calendar.id;
            initialMeetingScheduler.title = `Meeting with ${appName}`;
            initialMeetingScheduler.timezone = calendarTimeZone;
            initialMeetingScheduler.createdBy = userMail;
            initialMeetingScheduler.localLanguage = locale;
            initialMeetingScheduler.duration = 30;
            initialMeetingScheduler.inputsTag = initInputsTag;
            const meetingScheduler = await createMeetingScheduler(initialMeetingScheduler);
            queryClient.setQueryData("meetingScheduler", meetingScheduler);
        }
    });
    const { mutate: mutateMeetingScheduler } = useMutation(updateMeetingScheduler, {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueryData("meetingScheduler", variable);
        }
    });
    return { meetingScheduler, meetingSchedulerLoading, mutateMeetingScheduler };
};
