import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CloseIcon, EyeIcon, Flex, FlexItem, InfoIcon, MenuButton, OutlookColorIcon, SaveIcon, TrashCanIcon } from "@fluentui/react-northstar";
import { IEventSelection } from "../../../interfaces/Enums";
import { useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../../translations";
import { CalendarEmpty24Regular, CalendarSync24Regular } from "@fluentui/react-icons";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
export const EventNavbar = (props) => {
    const t = useTranslate(translations);
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { userPerm } = usePermissionsSelector("userPerm");
    const { forceRessource } = useOptionsSelector("forceRessource");
    const renderSaveButton = () => (_jsx(Button, { fluid: isOnMobile, loading: props.isSavingEvent, icon: _jsx(SaveIcon, { outline: true }), primary: true, disabled: props.isSaveButtonDisabled || (!!!props.eventId && !userPerm.perm.add) || (!!props.eventId && !userPerm.perm.update), content: t("Save"), onClick: () => props.onSaveEvent() }));
    const renderSeeConflictsButton = () => (_jsx(Button, { fluid: isOnMobile, icon: _jsx(EyeIcon, { outline: true }), secondary: true, disabled: (!!!props.eventId && !userPerm.perm.add) || (!!props.eventId && !userPerm.perm.update), content: t("SeeConflicts"), onClick: props.onSeeConflicts }));
    const renderDeleteButton = () => {
        const deleteButton = (_jsx(Button, { fluid: isOnMobile, tinted: true, icon: _jsx(TrashCanIcon, { outline: true, size: "small" }), primary: true, loading: props.isDeletingEvent, disabled: props.readOnly || props.isDeletingEvent || props.isSavingEvent || !userPerm.perm.delete, content: t("DeleteEvent"), onClick: props.onClickDeleteButton }));
        if (!props.isEventOccurenceFromSerie) {
            return deleteButton;
        }
        return (_jsx(MenuButton, { trigger: deleteButton, menu: [
                {
                    key: "delete_occurence",
                    content: t("DeleteOccurrence"),
                    onClick: props.deleteEventOccurenceOrSerie(IEventSelection.Occurrence),
                    icon: _jsx(CalendarEmpty24Regular, {}),
                },
                {
                    key: "delete_serie",
                    content: t("DeleteSeries"),
                    onClick: props.deleteEventOccurenceOrSerie(IEventSelection.Serie),
                    icon: _jsx(CalendarSync24Regular, {}),
                },
            ] }));
    };
    const renderCloseButton = () => (_jsx(Button, { fluid: isOnMobile, secondary: true, icon: _jsx(CloseIcon, { size: "small" }), disabled: props.isSavingEvent || props.isDeletingEvent, content: t("Close"), onClick: props.onClose(false) }));
    const renderButtonForShareEvent = () => (_jsx(Button, { fluid: isOnMobile, secondary: true, icon: _jsx(OutlookColorIcon, { size: "small" }), disabled: props.isSavingEvent || props.isDeletingEvent, content: t("ShareToOutlook"), onClick: props.onShareEvent }));
    const renderAboutButton = () => (_jsx(Button, { fluid: isOnMobile, icon: _jsx(InfoIcon, { outline: true }), content: t("InfoEvent"), onClick: props.showAboutDialog, disabled: props.isSavingEvent || props.isDeletingEvent }));
    return (_jsx(FlexItem, { grow: true, children: _jsxs(Flex, { gap: "gap.small", hAlign: "end", column: isOnMobile, children: [props.conflictDatesString !== "" && !props.isSavingEvent && renderSeeConflictsButton(), renderSaveButton(), !!props.eventId && renderDeleteButton(), isOnMobile ? (_jsxs(Flex, { styles: { width: "100%" }, gap: "gap.small", children: [!!props.eventId && renderAboutButton(), renderCloseButton()] })) : (_jsxs(_Fragment, { children: [!!props.eventId && renderAboutButton(), !!props.eventId && !forceRessource && renderButtonForShareEvent(), renderCloseButton()] }))] }) }));
};
