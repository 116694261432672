export const hourString = (moment, language) => {
    let dateString;
    moment.locale(language);
    switch (language) {
        case "de":
        case "es":
        case "pt":
        case "it":
            dateString = moment.format("HH:mm");
            break;
        case "fr":
            dateString = moment.format("HH[h]mm");
            break;
        default:
            dateString = moment.format("hh:mma");
            break;
    }
    return dateString;
};
