import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog, Flex, Text } from "@fluentui/react-northstar";
import { useDiscardLogic } from "./DiscardDialog.logic";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useTranslate } from "../../../hook/useTranslate";
export const DiscardDialog = (props) => {
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const discardLogic = useDiscardLogic(props);
    const t = useTranslate(translations);
    const renderDiscard = () => {
        return (_jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                marginTop: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
            }, children: _jsx(Text, { content: t("DiscardMessage") }) }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, defaultOpen: true, closeOnOutsideClick: true, header: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("DiscardMessageTitle") }) }), confirmButton: t("ContinueEditing"), content: renderDiscard(), cancelButton: t("DiscardChanges"), onCancel: discardLogic.actionDialog, onConfirm: discardLogic.closeDialog }));
};
