import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Alert, Avatar, BookmarkIcon, CalendarIcon, EditIcon, Flex, FormInput, IndentIcon, Input, LocationIcon, MeetingTimeIcon, Provider, SyncIcon, TagIcon, teamsDarkTheme, teamsHighContrastTheme, teamsTheme, Text, } from "@fluentui/react-northstar";
import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import moment from "moment";
import MarkdownView from "react-showdown";
import { translations } from "../../../translations";
import { getResource } from "../../../apis/apiResource";
import { clearTimezoneFromDates, generateDropdownItems, getRruleDaysMonthsTranslations, getRruleTranslations, updateEventRRule } from "../../../components/event/eventUtils";
import { getEvent } from "../../../apis/apiEvent";
import { IOptionRecurrence } from "../../../interfaces/Enums";
import { formatEventRRuleToRRulePlugin } from "../../../components/calendar/Calendar.utils";
import { GoogleRenderComponent, useTranslate } from "front";
/**
 * Widget for event details
 * @constructor
 */
export const WidgetDetails = () => {
    const [event, setEvent] = useState(undefined);
    const [context, setContext] = useState(undefined);
    const [resources, setResources] = useState(new Array());
    const [recurrenceLabels, setRecurrenceLabels] = useState(undefined);
    const [rruleDaysMonthsTranslations, setRruleDaysMonthsTranslations] = useState(undefined);
    const [rruleTranslations, setRruleTranslations] = useState(undefined);
    const labelsMaxWidth = "180px";
    const t = useTranslate(translations);
    /**
     * Translate word
     * @param id word id
     */
    const translate = (id) => {
        return t(id);
    };
    /**
     * Initialization
     */
    useEffect(() => {
        (async () => {
            const context = await HomePro.context.getContext();
            moment.locale(context.locale);
            setContext(context);
            const queryParams = new URLSearchParams(window.location.search);
            const calendarId = queryParams.get("calendarId");
            const eventId = queryParams.get("eventId");
            if (!calendarId || !eventId)
                throw new Error("Can't retrieve event");
            let newEvent = await retrieveEvent(calendarId, eventId);
            newEvent = clearTimezoneFromDates(newEvent);
            newEvent = updateEventRRule(newEvent, newEvent.modeRecurrence);
            setEvent(newEvent);
            const resource = await getResource(calendarId);
            const resources = resource.resourceData ?? new Array();
            setResources(resources);
        })();
    }, []);
    /**
     * On context change
     */
    useEffect(() => {
        if (!!context && !recurrenceLabels && !rruleDaysMonthsTranslations && !rruleTranslations) {
            setRecurrenceLabels(generateDropdownItems(IOptionRecurrence, ["NotRepeat", "Workweek", "Daily", "Weekly", "Monthly", "Yearly", "Custom"]));
            setRruleDaysMonthsTranslations(getRruleDaysMonthsTranslations(translate));
            setRruleTranslations(getRruleTranslations(translate));
        }
    }, [context]);
    /**
     * Retrieve event
     * @param calendarId calendar id
     * @param eventId event id
     */
    const retrieveEvent = async (calendarId, eventId) => {
        const events = JSON.parse(localStorage?.getItem("events_" + calendarId) ?? "[]");
        let event = events.find(e => e.id === eventId);
        if (!event)
            event = await getEvent(calendarId, eventId);
        return event;
    };
    /**
     * Get teams theme from name
     * @param themeName theme name
     */
    const getTeamsTheme = (themeName) => {
        switch (themeName) {
            case "default":
                return teamsTheme;
            case "dark":
                return teamsDarkTheme;
            case "contrast":
                return teamsHighContrastTheme;
        }
        return teamsTheme;
    };
    /**
     * Get current event resource label
     */
    const getCurrentResourceLabel = () => {
        if (!event)
            return "";
        let resourceLabels = resources.map((item) => item.title);
        if (!!event.resourceId)
            return resourceLabels[parseInt(event.resourceId)];
        return resourceLabels[0];
    };
    /**
     * Transform recurrence rule to string
     */
    const getRRuleToString = () => {
        if (!event)
            return "";
        let rrule = formatEventRRuleToRRulePlugin(event);
        if (!rrule)
            return "";
        const gettext = (id) => {
            return rruleTranslations.find((f) => f.content === id)?.value || id.toString();
        };
        return rrule.toText(gettext, rruleDaysMonthsTranslations);
    };
    /**
     * Get event recurrence mode label
     */
    const getRecurrenceModeLabel = () => {
        const recurrenceMode = event?.modeRecurrence ?? IOptionRecurrence.NotRepeat;
        if (!!event?.rrule && recurrenceMode === IOptionRecurrence.Custom)
            return getRRuleToString();
        return recurrenceLabels?.find(f => f.value === recurrenceMode)?.header;
    };
    /**
     * Render avatar
     */
    const renderAvatar = () => (_jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(Text, { content: translate("CreatedBy") }), _jsx(Avatar, { name: event?.createdBy?.toUpperCase().substring(0, event?.createdBy.indexOf('@')) })] }));
    /**
     * Render resource input
     */
    const renderResourceInput = () => !!event?.resourceId ? (_jsxs(Flex, { vAlign: "center", column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(BookmarkIcon, { outline: true, className: "Icon" }), _jsx(Text, { content: translate("LabelResource") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { children: _jsx(Input, { fluid: true, readOnly: true, value: getCurrentResourceLabel() }) }) })] })) : null;
    /**
     * Render title input
     */
    const renderTitleInput = () => (_jsxs(Flex, { vAlign: "center", column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(EditIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("LabelTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { children: _jsx(FormInput, { fluid: true, showSuccessIndicator: false, styles: { width: "100%" }, required: true, max: 100, readOnly: true, value: event?.title ?? "", placeholder: translate("AddTitle") }) }) })] }));
    /**
     * Render start date input
     */
    const renderStartDateInput = () => (_jsxs(Flex, { vAlign: "center", column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(CalendarIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("LabelStartDate") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", gap: "gap.small", children: _jsx(Input, { fluid: true, readOnly: true, value: moment(event?.startDate).format(event?.allDay ? "LL" : "LLLL") }) }) })] }));
    /**
     * Render end date input
     */
    const renderEndDateInput = () => (_jsxs(Flex, { vAlign: "center", column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(CalendarIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("LabelEndDate") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", gap: "gap.small", children: _jsx(Input, { fluid: true, readOnly: true, value: moment(event?.endDate).format(event?.allDay ? "LL" : "LLLL") }) }) })] }));
    /**
     * Render timezone dropdown
     */
    const renderTimeZoneDropdown = () => (_jsxs(Flex, { column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(MeetingTimeIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("LabelSelectionTimeZone") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", children: _jsx(Input, { fluid: true, readOnly: true, value: event?.timeZone }) }) })] }));
    /**
     * Render recurrence mode dropdown
     */
    const renderRecurrenceDropdown = () => {
        return !!event?.rrule ? (_jsxs(Flex, { column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(SyncIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("LabelRecurrence") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { children: _jsx(Input, { fluid: true, readOnly: true, value: getRecurrenceModeLabel() }) }) })] })) : null;
    };
    /**
     * Render description input
     */
    const renderDescriptionInput = () => !!event?.description ? (_jsxs(Flex, { column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(IndentIcon, { outline: true, styles: { marginTop: "4px" } }), _jsx(Text, { truncated: true, content: translate("LabelDescription") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx("div", { className: "input-readonly", children: _jsx(MarkdownView, { className: "markdown-viewer", style: { overflow: "auto", overflowWrap: "break-word", maxHeight: "100px" }, markdown: event?.description }) }) })] })) : null;
    /**
     * Render tags input
     */
    const renderTagsInput = () => !!event?.tags && event.tags.length > 0 ? (_jsxs(Flex, { column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(TagIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("TagsTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { column: true, children: _jsx(Flex, { vAlign: "center", wrap: true, children: event?.tags?.map((tag) => (_jsx(Alert, { style: { marginRight: "5px" }, content: _jsx(Flex, { styles: { padding: "0px 5px" }, vAlign: "center", hAlign: "center", gap: "gap.small", children: _jsx(Text, { content: tag }) }) }, "tag_" + tag))) }) }) })] })) : null;
    /**
     * Render address input
     */
    const renderAddressInput = () => !!event?.address?.fullAddress ? (_jsxs(Flex, { column: context?.onMobile, gap: context?.onMobile ? "gap.small" : undefined, children: [_jsx(Flex.Item, { styles: { width: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(LocationIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: translate("AddressTitle") })] }) }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", children: _jsx(Input, { fluid: true, readOnly: true, value: event?.address?.fullAddress }) }) })] })) : null;
    /**
     * Render google maps address preview
     */
    const renderGoogleMap = () => !!event?.address?.fullAddress ? (_jsx(Flex, { fill: true, vAlign: "center", style: { height: "200px" }, children: _jsx(GoogleRenderComponent, { address: event?.address, disabled: true, readOnly: true, updateAddress: () => { } }) })) : null;
    return useMemo(() => (_jsx(Provider, { theme: getTeamsTheme(context?.themeName), children: !!event ?
            _jsxs(Flex, { fill: true, column: true, gap: "gap.small", id: context?.themeName, children: [renderAvatar(), renderResourceInput(), renderTitleInput(), renderStartDateInput(), renderEndDateInput(), renderTimeZoneDropdown(), renderRecurrenceDropdown(), renderDescriptionInput(), renderTagsInput(), renderAddressInput(), renderGoogleMap()] })
            :
                _jsx("div", {}) })), [event]);
};
