import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader, Provider } from "@fluentui/react-northstar";
import { useAppInitializer, wrapWithErrorBoundary, Consent, useMsTeamsSelector, useAuthSelector, UpgradePremiumDialog, useConfigurationSelector, ErrorBanner, NewsDialog, useModalSelector, Modal, ConsentDialog, IAppType, } from "front";
import { translations } from "./translations";
import TelemetryService, { appInsightInstance } from "./services/TelemetryService/TelemetryService";
export const App = () => {
    const { loaded } = useAppInitializer({ needInitializegraph: false });
    const { appViews } = useSelector((s) => s.infosApp);
    const msTeamsContext = useMsTeamsSelector("locale", "theme", "loaded");
    translations.locale = msTeamsContext.locale;
    const { isTeamsApp } = useAuthSelector("isTeamsApp");
    const { data: config } = useConfigurationSelector("data");
    const { isOpen } = useModalSelector("isOpen");
    const wrapWithFluentUI = getFluentUIWrapper(msTeamsContext.locale, msTeamsContext.theme);
    if (!loaded)
        return wrapWithFluentUI(_jsx(Consent, {}));
    return wrapWithErrorBoundary(wrapWithFluentUI(_jsx(TelemetryService, { children: loaded ? (_jsxs(_Fragment, { children: [_jsx(NewsDialog, { AppType: IAppType.CALENDAR }), isOpen === Modal.NEED_CONSENT && _jsx(ConsentDialog, {}), _jsx(UpgradePremiumDialog, { appId: config.adminCenterAppId, appInsight: appInsightInstance }), _jsx(ErrorBanner, {}), _jsxs(Routes, { children: [renderRoutes(appViews), !isTeamsApp && _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "calendars/" + process.env.REACT_APP_CALENDAR_MOCK_ID, replace: true }) })] })] })) : (_jsx(Loader, { styles: { height: "100vh" } })) })));
};
///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////
const renderRoutes = (appViews) => {
    return Object.values(appViews).map((view) => _jsx(Route, { path: view.path, element: _jsx(view.element, {}) }, view.path));
};
const getFluentUIWrapper = (locale, theme) => (children) => (_jsx(Provider, { lang: locale, theme: theme, children: children }));
