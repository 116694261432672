import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Flex, Button, Alert, ExclamationTriangleIcon } from "@fluentui/react-northstar";
import { useNewsLogic } from "./NewsBanner.logic";
import "./NewsBanner.styles.scss";
import ReactHtmlParser from "react-html-parser";
import "react-quill/dist/quill.snow.css";
export const Newsbanner = (props) => {
    const logic = useNewsLogic(props);
    if (!logic.isNotInsideRestrictedRoutes)
        return;
    if (!logic.queryNewsBanner.data || (logic.queryNewsBanner.data && !logic.queryNewsBanner.data.news))
        return;
    return (_jsx(Alert, { id: "AlertInApp", 
        //key={err.id}
        content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(Text, { content: ReactHtmlParser(logic.queryNewsBanner.data.news[0].content) }), logic.queryNewsBanner.data.ctaLabel && _jsx(Button, { className: "button-banner", content: logic.queryNewsBanner.data.ctaLabel, onClick: logic.openCTALink })] }), visible: true, warning: true, icon: _jsx(ExclamationTriangleIcon, {}) }));
};
