import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, CalendarIcon, Checkbox, CloseIcon, Datepicker, Dialog, Dropdown, Flex, FlexItem, InfoIcon, Input, SaveIcon, SyncIcon, Text } from "@fluentui/react-northstar";
import moment from "moment";
import { useLogic } from "./CustomReccurence.logic";
import { translations } from "../../../translations";
import { useMsTeamsSelector, useTranslate } from "front";
import { ToggleButton } from "../../toggle/ToggleButton";
import { addFiveYears, addYearAndHalf } from "../../event/eventUtils";
import { IOptionRecurrence } from "../../../interfaces/Enums";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
export const CustomRecurrenceDialog = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const { allowDoubleBooking } = useOptionsSelector("allowDoubleBooking");
    /**
    * Render recurrence options
    */
    const renderRecurrenceOptions = () => {
        return (_jsxs(Flex, { fill: true, column: true, style: { marginTop: "10px", overflowX: "hidden" }, children: [_jsxs(Flex, { column: true, gap: "gap.small", styles: { marginBottom: "15px" }, children: [_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(CalendarIcon, { outline: true, className: "icon" }), _jsx(Text, { content: t("DateStartRecurrence") })] }), _jsx(Flex, { gap: "gap.small", children: _jsx(Datepicker, { allowManualInput: false, inputOnly: true, styles: { width: "100%" }, input: { fluid: true }, formatMonthDayYear: logic.formatDateToLocaleString, selectedDate: moment(logic.state.dayStartRecurrence).toDate(), onDateChange: logic.onChangeRecurrenceStartDate }) })] }), _jsxs(Flex, { column: true, gap: "gap.small", styles: { marginBottom: "15px" }, children: [_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(SyncIcon, { outline: true }), _jsx(Text, { content: t("IntervalRecurrence") })] }), _jsxs(Flex, { gap: "gap.small", children: [_jsx(FlexItem, { size: "size.half", children: _jsx(Input, { fluid: true, type: "number", min: 1, max: 365, value: logic.state.weeklyInterval ?? 1, onChange: logic.onChangeWeeklyInterval }) }), _jsx(FlexItem, { size: "size.half", children: _jsx(Dropdown, { fluid: true, value: logic.formatRecurrenceFrequency(), items: logic.renderRecurrenceFrequencyOptions(), checkable: true, onChange: logic.onChangeRecurrenceFrequencyOption }) })] })] }), !logic.state.isRecurrenceCustomValid &&
                    _jsxs(Flex, { vAlign: "center", gap: "gap.small", styles: { marginBottom: "15px" }, children: [_jsx(InfoIcon, { outline: true, className: "Icon" }), _jsx(Alert, { info: true, content: t("RecurrenceInfo") })] }), logic.state.optionFrequencyReccurence !== 0 &&
                    _jsxs(Flex, { column: true, gap: "gap.small", styles: { marginBottom: "15px" }, children: [_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(SyncIcon, { outline: true, className: "icon" }), _jsx(Text, { className: "margin_item", content: t("DaysOfRecurrence") })] }), logic.state.optionFrequencyReccurence === 2 &&
                                _jsxs(Flex, { wrap: isOnMobile, gap: "gap.small", vAlign: "center", children: [_jsx(Checkbox, { label: t("OnThe"), toggle: true, checked: logic.state.monthOnTheOption, onChange: logic.onRepeatSpecificDayNumberOfMonth }), _jsx(FlexItem, { grow: true, children: _jsxs(Flex, { gap: "gap.small", children: [_jsx(FlexItem, { size: "size.half", children: _jsx(Dropdown, { fluid: true, disabled: !logic.state.monthOnTheOption, value: logic.formatRecurrenceStartDay(), items: logic.renderRecurrenceStartDayOptions(), checkable: true, onChange: logic.onChangeRecurrenceStartDayOption }) }), _jsx(FlexItem, { size: "size.half", children: _jsx(Dropdown, { fluid: true, disabled: !logic.state.monthOnTheOption, value: logic.formatRecurrenceDay(), items: logic.renderRecurrenceDayOptions(), checkable: true, onChange: logic.onChangeRecurrenceDayOption }) })] }) })] }), ([1, 2].includes(logic.state.optionFrequencyReccurence)) &&
                                _jsxs(Flex, { wrap: true, gap: "gap.small", children: [logic.state.optionFrequencyReccurence === 1 &&
                                            logic.state.weeks?.map((day, index) => {
                                                const translation = t(day);
                                                return (_jsx(ToggleButton, { rrule: logic.state.rrule ?? {}, weeks: translation, freqString: "byweekday", tabindex: index, disabled: false, onWeeklyItervalUpdate: logic.onRepeatWeeklyDay, resetSelectedDate: logic.resetSelectedDate }, day + logic.state.optionFrequencyReccurence));
                                            }), logic.state.optionFrequencyReccurence === 2 &&
                                            logic.state.daysofmonth.map((item, index) => {
                                                return (_jsx(ToggleButton, { rrule: logic.state.rrule ?? {}, weeks: item, freqString: "bymonthday", tabindex: index + 1, disabled: logic.state.monthOnTheOption, onWeeklyItervalUpdate: logic.onRepeatWeeklyDay, resetSelectedDate: logic.resetSelectedDate }, item + logic.state.optionFrequencyReccurence));
                                            })] })] }), _jsxs(Flex, { column: true, gap: "gap.small", styles: { marginBottom: "15px" }, children: [_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(CalendarIcon, { outline: true }), _jsx(Text, { content: t("EndOfRecurrence") })] }), allowDoubleBooking &&
                            _jsx(Flex, { gap: "gap.small", children: _jsx(Flex.Item, { children: _jsx(Checkbox, { label: t("EndsNeverRecurrence"), toggle: true, checked: logic.state.endNever, onChange: logic.onToggleRecurrenceNeverEnd }) }) }), _jsxs(Flex, { gap: "gap.small", children: [_jsx(Checkbox, { label: t("DateEndsRecurrence"), toggle: true, checked: logic.state.endWithDate, onChange: logic.onToggleRecurrenceEndWithDate }), _jsx(Datepicker, { allowManualInput: false, inputOnly: true, styles: { width: "100%" }, input: {
                                        fluid: true,
                                        disabled: !logic.state.endWithDate,
                                    }, formatMonthDayYear: logic.formatDateToLocaleString, selectedDate: !allowDoubleBooking ? moment(props.event.rrule.until ?? props.event.endDate).toDate() : moment(logic.state.dateEndRecurrence).toDate(), onDateChange: !allowDoubleBooking ? props.onUpdateEventFromDropdownOrInput("rrule") : logic.onRecurrenceEndWithDate, maxDate: !allowDoubleBooking ? (props.event.modeRecurrence !== IOptionRecurrence.Yearly ? addYearAndHalf(props.event.startDate).toDate() : addFiveYears(props.event.startDate).toDate()) : null, minDate: moment(props.event.startDate).toDate() })] })] }), _jsxs(Flex, { column: true, gap: "gap.small", children: [_jsxs(Flex, { gap: "gap.small", children: [_jsx(SyncIcon, { outline: true }), _jsx(Flex.Item, { size: "size.quarter", children: _jsx(Text, { content: logic.state.optionFrequencyReccurence === 1 || logic.state.optionFrequencyReccurence === 2 ?
                                            t("SerieOfRecurrence")
                                            :
                                                t("OccurenceOfRecurrence") }) })] }), _jsxs(Flex, { gap: "gap.small", children: [_jsx(Checkbox, { label: t("CountOfRecurrence"), toggle: true, checked: logic.state.isCount, onChange: logic.onToggleRecurrenceEndWithOccurences }), _jsx(Input, { type: "number", fluid: true, value: logic.state.count, disabled: !logic.state.isCount, onChange: logic.onChangeRecurrenceOccurences, max: !allowDoubleBooking ? 100 : null })] })] })] }));
    };
    return (_jsx(Dialog, { closeOnOutsideClick: false, header: t("TitleCustomRecurrence"), open: props.show, onCancel: props.onClose, onConfirm: logic.onSaveRrule, content: !!props.event ? renderRecurrenceOptions() : null, confirmButton: {
            content: t("Save"),
            icon: _jsx(SaveIcon, { outline: true }),
            primary: true,
        }, cancelButton: {
            icon: _jsx(CloseIcon, {}),
            content: t("Close"),
        }, styles: { overflowY: "scroll !important" } }));
};
